import React, { useEffect, useRef } from 'react';
import gsap from "gsap";
import { IntroSlider, IntroSlideInner } from '../style/main';
import SliderImg from './SliderImg';

const folder = 'sliderBottom/'
const largeFoler = `${folder}/large/`

const slider = [{
    src: `${folder}0.jpg`,
    largeSrc: `${largeFoler}0.jpg`
}, {
    src: `${folder}1.jpg`,
    largeSrc: `${largeFoler}1.jpg`
}, {
    src: `${folder}2.jpg`,
    largeSrc: `${largeFoler}2.jpg`
}, {
    src: `${folder}3.jpg`,
    largeSrc: `${largeFoler}3.jpg`
}, {
    src: `${folder}4.jpg`,
    largeSrc: `${largeFoler}4.jpg`
}, {
    src: `${folder}5.jpg`,
    largeSrc: `${largeFoler}5.jpg`
}, {
    src: `${folder}6.jpg`,
    largeSrc: `${largeFoler}6.jpg`
}]

const Slider = React.memo(({ sliderTimeline, largeImg, setPopImg }) => {
    const sliderBottom = useRef(null)
    const firstLoad = useRef(true);
    const orientation = useRef(window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape');
    useEffect(() => {
        const setBox = () => {
            // MOBILE
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                // ORIENTATION CHECK FOR ALLOWING BOTTOM FIXED SLIDER
                const newOrientation = window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
                if (newOrientation === orientation.current && !firstLoad.current) {
                    // IT IS THE SAME ORIENTATION AND DOES NOT NEED TO BE CHANGED, SETUP AS IF MOBILE BROWSER CANT HAVE CUSTOM HEIGHT, SO DONT KIL() TIMELINE, UNLESS USER SWITCHES ORIENTATION
                    return
                } else if (!firstLoad.current) {
                    orientation.current = newOrientation;
                }
            }
            const box = document.querySelectorAll('.box');
            const boxHeight = box[0].offsetHeight;
            gsap.set(".box", {
                x: (i) => i * boxHeight
            });
            // DELAY LOAD ONLY ON FIRSTLOAD
            if (sliderTimeline.current) {
                sliderTimeline.current.kill()
            }
            if (boxHeight === 0) {
                return
            }
            sliderTimeline.current = gsap.timeline({ repeat: -1 });
            sliderTimeline.current.to(".box", {
                duration: 17,
                ease: "none",
                x: `+=${boxHeight * 7}`, //move each box 2100px to right
                modifiers: {
                    x: gsap.utils.unitize(x => parseFloat(x) % (boxHeight * 7)) //force x value to be between 0 and 500 using modulus
                }
            });
            firstLoad.current = false;
        }
        setBox()
        window.addEventListener('resize', setBox)
        return () => {
            window.removeEventListener('resize', setBox)
        }
    }, [sliderTimeline]);
    useEffect(() => {
        // REMOVE FOR WHEN RESIZING BROWSER BETWEEN 1023 AND 1024 FOR THE SIGNUP BOX
        const setBox2 = () => {
            if (window.innerWidth >= 1024 && !largeImg) {
                document.querySelector('body').classList.remove('overflow');
            }
        }
        window.addEventListener('resize', setBox2)
        return () => {
            window.removeEventListener('resize', setBox2)
        }
    }, [largeImg]);
    const sliderHover = () => {
        if (sliderTimeline.current) {
            sliderTimeline.current.pause()
        }
    }
    const sliderHoverOut = () => {
        if (sliderTimeline.current && !largeImg) {
            sliderTimeline.current.play()
        }
    }
    return (
        <IntroSlider id="introSlider" ref={sliderBottom}>
            <IntroSlideInner onMouseEnter={sliderHover} onMouseLeave={sliderHoverOut}>
                {slider.map((x, i) => <SliderImg src={x.src} largeSrc={x.largeSrc} key={i} setPopImg={setPopImg} />)}
            </IntroSlideInner>
        </IntroSlider>
    );
})

export default Slider;