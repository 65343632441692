const initialState = {}

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER':
            return {
                ...state,
                id: action.id
            }
        default:
            return state;
    }
}

export default mainReducer;