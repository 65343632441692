import React, { useState, useRef, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase'
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { ReceiptUrlBox, ReceiptUrlWrap, ReceiptUrlTop, ReceiptUrlWebsite, ReceiptUrlOrderNum, ReceiptUrlOrderNumRight, ReceiptUrlOrderNumLeft, ReceiptUrlTableWrap, ReceiptUrlTitleBottom, ReceiptUrlTable, ReceiptUrlTableRow, ReceiptUrlTableRowBox, ReceiptUrlShippingBoxSide, ReceiptUrlShippingBoxSideTitle, ReceiptUrlShippingBoxSideList, ReceiptUrlShippingBoxSideListLi, ReceiptUrlSectionBox, ReceiptLogo, ReceiptUrlBottom, ReceiptUrlBottomTitle, ReceiptUrlBottomText, ReceiptUrlBottomTextBox, ButtonLoader, ReceiptNotFound, ReceiptEmailBlurb, ReceiptEmailBlurbTop, ReceiptEmailBlurbBottom } from '../../style/main';
import { businessPhoneNumber, businessEmail, ourAddress } from '../info/businessInfo'

const ReceiptUrl = () => {
    const firestore = useFirestore()
    let { id } = useParams();
    const [receipt, setReceipt] = useState('loading');
    const getReceipt = useRef(() => {
        firestore.collection("orders").where("receiptId", "==", id).get().then((snapshot) => {
            if (snapshot.empty) {
                setReceipt(false)
                return
            }
            return snapshot.forEach((doc) => {
                setReceipt(doc.data())
            });
        }).catch(e => {
            console.log(e)
        })
    })
    useEffect(() => {
        getReceipt.current()
    }, []);
    return (
        <>
            {receipt === 'loading' ?
                <ButtonLoader dark={true} receiptUrlPage={true} />
                : !receipt ?
                    <ReceiptNotFound>Whoops! We did not find your receipt, please contact us:<br /><a href={`mailto:${businessEmail}`}>{businessEmail}</a></ReceiptNotFound>
                    :
                    <ReceiptUrlBox>
                        <ReceiptUrlWrap>
                            <ReceiptUrlTop>
                                <Link to="/">
                                    <ReceiptLogo src={'/img/logo.png'} />
                                </Link>
                                <ReceiptUrlWebsite>
                                    <Link to="/">www.product.pictures</Link>
                                </ReceiptUrlWebsite>
                            </ReceiptUrlTop>
                            <ReceiptUrlOrderNum>
                                <ReceiptUrlOrderNumLeft>Details for order ID:</ReceiptUrlOrderNumLeft>
                                <ReceiptUrlOrderNumRight>{receipt.orderId}</ReceiptUrlOrderNumRight>
                            </ReceiptUrlOrderNum>
                            <ReceiptUrlTableWrap>
                                <ReceiptUrlSectionBox>
                                    <ReceiptUrlTitleBottom>Thank you for your order!</ReceiptUrlTitleBottom>
                                    <ReceiptUrlTable>
                                        <ReceiptUrlTableRowBox>
                                            <ReceiptUrlTableRow>Order ID</ReceiptUrlTableRow>
                                            <ReceiptUrlTableRow>{receipt.orderId}</ReceiptUrlTableRow>
                                        </ReceiptUrlTableRowBox>
                                        <ReceiptUrlTableRowBox>
                                            <ReceiptUrlTableRow>Order Date</ReceiptUrlTableRow>
                                            <ReceiptUrlTableRow>{receipt.dateOrdered}</ReceiptUrlTableRow>
                                        </ReceiptUrlTableRowBox>
                                        {receipt.receipt.map((x, i) => <ReceiptUrlTableRowBox key={x.name}>
                                            <ReceiptUrlTableRow stronger={i === receipt.receipt.length - 1 ? true : false}>{x.name}</ReceiptUrlTableRow>
                                            <ReceiptUrlTableRow>{x.price}</ReceiptUrlTableRow>
                                        </ReceiptUrlTableRowBox>)}
                                    </ReceiptUrlTable>
                                </ReceiptUrlSectionBox>
                                <ReceiptUrlSectionBox shipping={true}>
                                    <ReceiptUrlTitleBottom>Shipping Information:</ReceiptUrlTitleBottom>
                                    <ReceiptUrlTable shipping={true}>

                                        <ReceiptUrlShippingBoxSide>
                                            {receipt.shippingInfo.chosenShippingRate.cost > 0 ?
                                                <>
                                                    <ReceiptUrlShippingBoxSideTitle>Shipping from:</ReceiptUrlShippingBoxSideTitle>
                                                    <ReceiptUrlShippingBoxSideList>
                                                        {receipt.shippingInfo.shippingAddress.companyName && <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.companyName}</ReceiptUrlShippingBoxSideListLi>}
                                                        <ReceiptUrlShippingBoxSideListLi>{receipt.account.firstName} {receipt.account.lastName} </ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.address}</ReceiptUrlShippingBoxSideListLi>
                                                        {receipt.shippingInfo.shippingAddress.apt && <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.apt}</ReceiptUrlShippingBoxSideListLi>}
                                                        <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.city}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.state}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.shippingAddress.zipCode}</ReceiptUrlShippingBoxSideListLi>
                                                    </ReceiptUrlShippingBoxSideList>
                                                </>
                                                :
                                                <>
                                                    <ReceiptUrlShippingBoxSideTitle>Ship your product to:</ReceiptUrlShippingBoxSideTitle>
                                                    <ReceiptUrlShippingBoxSideList>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.name}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.address_line1}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.city_locality}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.state_province}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.postal_code}</ReceiptUrlShippingBoxSideListLi>
                                                        <ReceiptUrlShippingBoxSideListLi>{ourAddress.phone}</ReceiptUrlShippingBoxSideListLi>
                                                    </ReceiptUrlShippingBoxSideList>
                                                </>
                                            }
                                        </ReceiptUrlShippingBoxSide>

                                        {receipt.shippingInfo.chosenReturnedRate.cost > 0 &&
                                            <ReceiptUrlShippingBoxSide>
                                                <ReceiptUrlShippingBoxSideTitle>Returning your product to:</ReceiptUrlShippingBoxSideTitle>
                                                <ReceiptUrlShippingBoxSideList>
                                                    {receipt.shippingInfo.returnedAddress.companyName && <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.companyName}</ReceiptUrlShippingBoxSideListLi>}
                                                    <ReceiptUrlShippingBoxSideListLi>{receipt.account.firstName} {receipt.account.lastName} </ReceiptUrlShippingBoxSideListLi>
                                                    <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.address}</ReceiptUrlShippingBoxSideListLi>
                                                    {receipt.shippingInfo.returnedAddress.apt && <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.apt}</ReceiptUrlShippingBoxSideListLi>}
                                                    <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.city}</ReceiptUrlShippingBoxSideListLi>
                                                    <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.state}</ReceiptUrlShippingBoxSideListLi>
                                                    <ReceiptUrlShippingBoxSideListLi>{receipt.shippingInfo.returnedAddress.zipCode}</ReceiptUrlShippingBoxSideListLi>
                                                </ReceiptUrlShippingBoxSideList>
                                            </ReceiptUrlShippingBoxSide>
                                        }
                                    </ReceiptUrlTable>
                                </ReceiptUrlSectionBox>
                            </ReceiptUrlTableWrap>
                            <ReceiptEmailBlurb>
                                <ReceiptEmailBlurbTop>Your order information has been sent to:</ReceiptEmailBlurbTop>
                                <ReceiptEmailBlurbBottom>{receipt.account.email}</ReceiptEmailBlurbBottom>
                            </ReceiptEmailBlurb>
                            <ReceiptUrlBottom>
                                <ReceiptUrlBottomTitle>Questions about your order?</ReceiptUrlBottomTitle>
                                <ReceiptUrlBottomTextBox>
                                    <ReceiptUrlBottomText href={`mailto:${businessEmail}`}>{businessEmail}</ReceiptUrlBottomText>
                                    <ReceiptUrlBottomText href={`tel:${businessPhoneNumber}`}>{businessPhoneNumber}</ReceiptUrlBottomText>
                                </ReceiptUrlBottomTextBox>
                            </ReceiptUrlBottom>
                        </ReceiptUrlWrap>
                    </ReceiptUrlBox>
            }
        </>
    );
}

export default ReceiptUrl;