import React from 'react';
import { InnerH2Title, HowItWorksBox, HowItWorksSteps, HowItWorksStep, ParagraphH2, HowItWorksStepIcon, HowItWorksStepLeftTitle, HowItWorksStepLeftP, HowItWorksStepContent, HowItWorksStepNumber, HowItWorksStepCircle, HowItWorksStepNumberLine, HowItWorksStepNumberLineVertical, HowItWorksStepVerticalBox, HowItWorksStepsBox, HowItWorksWrap, HowItWorksStepsBottomTitle, ProductReturnedBlurb, ProductReturnedBlurbTitle, ProductReturnedBlurbP, HowItWorksStepVerticalBox2, CtaMobile } from '../style/main';

const HowItWorks = React.memo(({ setSignupOpen }) => {
    const openForm = () => {
        document.querySelector('body').classList.add('overflow');
        setSignupOpen(true)
    }
    return (
        <HowItWorksBox>
            <HowItWorksWrap>
                <InnerH2Title><span className="orange">How</span> It Works</InnerH2Title>
                <ParagraphH2 fourSimpleSteps={true}>Get your Amazon product photos in <strong className="noWrap"><u>4 simple steps.</u></strong></ParagraphH2>
                <HowItWorksSteps>
                    <HowItWorksStepsBox>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepNumber mobileVersion={true}>
                                    <HowItWorksStepCircle>1</HowItWorksStepCircle>
                                </HowItWorksStepNumber>
                                <HowItWorksStepIcon viewBox="0 0 24 24" className="box1">
                                    <path d="M14.039 8.968l-3.961-3.968h1.968s.991-3.178-2.985-5c6.191.222 6.972 5 6.972 5h1.967l-3.961 3.968zm6.461 5.575l-.342.195v1.717l.342-.195v-1.717zm-.844.481l-.35.199v1.717l.35-.199v-1.717zm.906-10.024l-1.306 1.55 1.62.919-6.859 3.698-8.86-5.138 4.436-2.685c-.234-.5-.626-.989-1.313-1.338l-5.124 2.978-3.156 5.487 2 1.106v5.445l12.25 6.978 9.75-5.551v-11.508l-3.438-1.941zm-16.624 2.688l7.897 4.54-1.192 2.091-7.872-4.605 1.167-2.026zm9.062 13.298l-9-5.126v-3.112l7.377 4.319 1.623-2.845v6.764zm9-3.7l-7 3.985v-8.361l7-3.773v8.149zm-4.907-.802l-.349.199v1.713l.349-.195v-1.717zm.584-.333l-.343.195v1.717l.343-.195v-1.717zm1.395-.794l-.343.195v1.717l.343-.195v-1.717zm-.574.327l-.344.196v1.717l.344-.196v-1.717z" />
                                </HowItWorksStepIcon>
                            </HowItWorksStepContent>
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftTitle>Prepare your product for shipment</HowItWorksStepLeftTitle>
                            </HowItWorksStepContent>
                            <HowItWorksStepNumber>
                                <HowItWorksStepNumberLine />
                                <HowItWorksStepCircle>1</HowItWorksStepCircle>
                                <HowItWorksStepVerticalBox2>
                                    <HowItWorksStepNumberLineVertical />
                                </HowItWorksStepVerticalBox2>
                            </HowItWorksStepNumber>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftP>Start by placing your order to receive your shipping information. Then package your product safely and place the packing slip that you will receive with your order completion on the inside of your shipment.</HowItWorksStepLeftP>
                            </HowItWorksStepContent>
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                    </HowItWorksStepsBox>
                    <HowItWorksStepsBox>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent>
                                <HowItWorksStepNumber mobileVersion={true}>
                                    <HowItWorksStepCircle>2</HowItWorksStepCircle>
                                </HowItWorksStepNumber>
                                <HowItWorksStepIcon viewBox="0 0 24 24" className="box2">
                                    <path d="M17.677 16.879l-.343.195v-1.717l.343-.195v1.717zm2.823-3.324l-.342.195v1.717l.342-.196v-1.716zm3.5-7.602v11.507l-9.75 5.54-10.25-4.989v-11.507l9.767-5.504 10.233 4.953zm-11.846-1.757l7.022 3.2 1.7-.917-7.113-3.193-1.609.91zm.846 7.703l-7-3.24v8.19l7 3.148v-8.098zm3.021-2.809l-6.818-3.24-2.045 1.168 6.859 3.161 2.004-1.089zm5.979-.943l-2 1.078v2.786l-3 1.688v-2.856l-2 1.078v8.362l7-3.985v-8.151zm-4.907 7.348l-.349.199v1.713l.349-.195v-1.717zm1.405-.8l-.344.196v1.717l.344-.196v-1.717zm.574-.327l-.343.195v1.717l.343-.195v-1.717zm.584-.332l-.35.199v1.717l.35-.199v-1.717zm-16.656-4.036h-2v1h2v-1zm0 2h-3v1h3v-1zm0 2h-2v1h2v-1z" />
                                </HowItWorksStepIcon>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepNumber>
                                <HowItWorksStepCircle>3</HowItWorksStepCircle>
                                <HowItWorksStepNumberLine />
                                <HowItWorksStepVerticalBox2>
                                    <HowItWorksStepNumberLineVertical />
                                </HowItWorksStepVerticalBox2>
                            </HowItWorksStepNumber>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftTitle>Ship us your product</HowItWorksStepLeftTitle>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftP>During order creation you can choose to let us help you in creating a shipping label or choose to create a shipping label yourself. Once you have your shipping label created and your product packaged, ship us your product.</HowItWorksStepLeftP>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                    </HowItWorksStepsBox>
                    <HowItWorksStepsBox>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepNumber mobileVersion={true}>
                                    <HowItWorksStepCircle>3</HowItWorksStepCircle>
                                </HowItWorksStepNumber>
                                <HowItWorksStepIcon viewBox="0 0 24 24" className="photo">
                                    <path d="M5 4h-3v-1h3v1zm10.93 0l.812 1.219c.743 1.115 1.987 1.781 3.328 1.781h1.93v13h-20v-13h3.93c1.341 0 2.585-.666 3.328-1.781l.812-1.219h5.86zm1.07-2h-8l-1.406 2.109c-.371.557-.995.891-1.664.891h-5.93v17h24v-17h-3.93c-.669 0-1.293-.334-1.664-.891l-1.406-2.109zm-11 8c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm7 0c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z" />
                                </HowItWorksStepIcon>
                            </HowItWorksStepContent>
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftTitle>We create your Amazon product photos</HowItWorksStepLeftTitle>
                            </HowItWorksStepContent>
                            <HowItWorksStepNumber>
                                <HowItWorksStepNumberLine />
                                <HowItWorksStepCircle>3</HowItWorksStepCircle>
                                <HowItWorksStepVerticalBox2>
                                    <HowItWorksStepNumberLineVertical />
                                </HowItWorksStepVerticalBox2>
                            </HowItWorksStepNumber>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftP>We are excited to receive your product! Once we receive your product we will create for you professional Amazon product photos made to fit Amazon product photo rules/standards. We are certain your new product photos will help you drive sales while selling on Amazon.</HowItWorksStepLeftP>
                            </HowItWorksStepContent>
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent />
                        </HowItWorksStep>
                    </HowItWorksStepsBox>
                    <HowItWorksStepsBox>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent>
                                <HowItWorksStepNumber mobileVersion={true}>
                                    <HowItWorksStepCircle>4</HowItWorksStepCircle>
                                </HowItWorksStepNumber>
                                <HowItWorksStepIcon viewBox="0 0 24 24" className="download">
                                    <path d="M23 24v-20h-8v2h6v16h-18v-16h6v-2h-8v20h22zm-12-13h-4l5 6 5-6h-4v-11h-2v11z" />
                                </HowItWorksStepIcon>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepNumber>
                                <HowItWorksStepCircle>4</HowItWorksStepCircle>
                                <HowItWorksStepNumberLine />
                                <HowItWorksStepVerticalBox2>
                                    <HowItWorksStepNumberLineVertical />
                                </HowItWorksStepVerticalBox2>
                            </HowItWorksStepNumber>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftTitle>Download your Amazon product photos</HowItWorksStepLeftTitle>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                        <HowItWorksStep>
                            <HowItWorksStepContent />
                            <HowItWorksStepVerticalBox>
                                <HowItWorksStepNumberLineVertical />
                            </HowItWorksStepVerticalBox>
                            <HowItWorksStepContent>
                                <HowItWorksStepLeftP>When ready, we will send you your completed Amazon product photos via a secure downloadable link online. (usually within 5-7 business days after we receive your product)</HowItWorksStepLeftP>
                            </HowItWorksStepContent>
                        </HowItWorksStep>
                    </HowItWorksStepsBox>
                </HowItWorksSteps>
                <HowItWorksStepsBottomTitle>It's just that <span>simple!</span></HowItWorksStepsBottomTitle>
                <ProductReturnedBlurb>
                    <ProductReturnedBlurbTitle>Need your product returned?</ProductReturnedBlurbTitle>
                    <ProductReturnedBlurbP>If you prefer we can return ship your product once your photos are ready. Simply choose "Yes" when asked "Do you need your product returned?" during your order creation process to receive a return shipping rate.</ProductReturnedBlurbP>
                </ProductReturnedBlurb>
                <CtaMobile onClick={openForm} shrunkWidth={true} >Start Now</CtaMobile>
            </HowItWorksWrap>
        </HowItWorksBox>
    );
})

export default HowItWorks;