import React from 'react';
import { ShippingOptionsRow, ShippingOptionsLeft, Radio, RadioCircle, ShippingOptionsLogo, ShippingOptionsText, ShippingOptionsPrice, ShippingOptionsTextType, ShippingOptionsTextCount } from '../style/main'

const ShippingChoice = React.memo(({ form, carrierName, cost, serviceType, serviceCode, deliveryDays, deliveryDayCount, rateId, setForm, carrierId }) => {
    const onClick = () => {
        setForm(x => ({
            ...x,
            chosenShippingRate: {
                carrierId: form.chosenShippingRate.rateId === rateId ? false : carrierId,
                cost: form.chosenShippingRate.rateId === rateId ? 0 : cost,
                rateId: form.chosenShippingRate.rateId === rateId ? false : rateId,
                serviceCode: form.chosenShippingRate.rateId === rateId ? false : serviceCode
            },
            formError: false
        }))
    }
    return (
        <ShippingOptionsRow selected={form.chosenShippingRate.rateId === rateId ? true : false} onClick={onClick}>
            <ShippingOptionsLeft>
                <Radio>
                    <RadioCircle selected={form.chosenShippingRate.rateId === rateId ? true : false} />
                </Radio>
                <ShippingOptionsLogo>
                    {carrierName === 'UPS' ?
                        <img src='/img/ups.png' alt="test" className="upsLogo" />
                        : carrierName === 'FedEx' ?
                            <img src='/img/fedex.png' alt="test" className="fedexLogo" />
                            : carrierName === 'USPS' ?
                                <img src='/img/usps.png' alt="test" className="uspsLogo" />
                                : ''
                    }
                </ShippingOptionsLogo>
                <ShippingOptionsText>
                    <ShippingOptionsTextType>{serviceType}</ShippingOptionsTextType>
                    <ShippingOptionsTextCount>({carrierName === 'USPS' ? `${deliveryDays} Business days` : serviceCode === 'fedex_ground' ? `${deliveryDays} Business days` : deliveryDayCount})</ShippingOptionsTextCount>
                </ShippingOptionsText>
            </ShippingOptionsLeft>
            <ShippingOptionsPrice>${cost.toFixed(2)}</ShippingOptionsPrice>
        </ShippingOptionsRow>
    );
})

export default ShippingChoice;