import React, { useState, useRef, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase'
import { Link, useRouteMatch } from 'react-router-dom';
import { ReceiptUrlBox, ReceiptUrlWrap, ReceiptUrlTop, ReceiptUrlWebsite, ReceiptUrlOrderNum, PackingSlipTitle, ReceiptUrlTableWrap, ReceiptUrlTitleBottom, ReceiptUrlTable, ReceiptUrlTableRow, ReceiptUrlTableRowBox, ReceiptUrlShippingBoxSide, ReceiptUrlShippingBoxSideList, ReceiptUrlShippingBoxSideListLi, ReceiptUrlSectionBox, ReceiptLogo, ReceiptUrlBottom, ReceiptUrlBottomTitle, ReceiptUrlBottomText, ReceiptUrlBottomTextBox, ButtonLoader, ReceiptNotFound, PackingSlipImg } from '../../style/main';
import { businessPhoneNumber, businessEmail, ourAddress } from '../info/businessInfo'

const PackingSlip = () => {
    const firestore = useFirestore()
    const packing = useRouteMatch("/packing/:id");
    const develop = useRouteMatch("/packing/:id/develop");
    const id = packing.params.id;
    const [slip, setSlip] = useState({
        loading: true,
        img: false,
        devInfo: false
    });
    const getSlip = useRef(() => {

        firestore.collection("orders").where("orderId", "==", id)
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot.empty) {
                    setSlip({
                        loading: false,
                        img: false
                    })
                }
                querySnapshot.forEach(function (doc) {
                    if (doc.data().shippingInfo.shipping) {
                        setSlip({
                            loading: false,
                            img: false
                        })
                        return
                    }
                    if (develop) {
                        setSlip({
                            loading: false,
                            img: false,
                            devInfo: doc.data()
                        })
                        return
                    }
                    if (doc.data().packingSlip) {
                        // SLIP IS READY
                        setSlip({
                            loading: false,
                            img: `https://storage.cloud.google.com/pslip8523883/ProductPicturesSlip-${id}.jpg`
                        })
                    }
                });
            });
    })
    useEffect(() => {
        getSlip.current()
    }, []);
    const imgLoaded = () => {
        window.print()
    }
    return (
        <>
            {slip.devInfo ?
                <ReceiptUrlBox>
                    <ReceiptUrlWrap id="pdfBox" packing={true}>
                        <ReceiptUrlTop>
                            <Link to="/">
                                <ReceiptLogo src={'/img/logo.png'} />
                            </Link>
                            <ReceiptUrlWebsite>
                                <Link to="/">www.product.pictures</Link>
                            </ReceiptUrlWebsite>
                        </ReceiptUrlTop>
                        <ReceiptUrlOrderNum>
                            <PackingSlipTitle>Print this packing slip and place it on the inside of your shipment.</PackingSlipTitle>
                        </ReceiptUrlOrderNum>
                        <ReceiptUrlTableWrap>
                            <ReceiptUrlSectionBox>
                                <ReceiptUrlTitleBottom>Ship Your Product To Our Address:</ReceiptUrlTitleBottom>
                                <ReceiptUrlTable shipping={true}>
                                    <ReceiptUrlShippingBoxSide>
                                        <ReceiptUrlShippingBoxSideList>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.name}</ReceiptUrlShippingBoxSideListLi>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.address_line1}</ReceiptUrlShippingBoxSideListLi>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.city_locality}</ReceiptUrlShippingBoxSideListLi>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.state_province}</ReceiptUrlShippingBoxSideListLi>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.postal_code}</ReceiptUrlShippingBoxSideListLi>
                                            <ReceiptUrlShippingBoxSideListLi>{ourAddress.phone}</ReceiptUrlShippingBoxSideListLi>
                                        </ReceiptUrlShippingBoxSideList>
                                    </ReceiptUrlShippingBoxSide>
                                </ReceiptUrlTable>
                            </ReceiptUrlSectionBox>
                            <ReceiptUrlSectionBox shipping={true}>
                                <ReceiptUrlTitleBottom>Order Details:</ReceiptUrlTitleBottom>
                                <ReceiptUrlTable>
                                    <ReceiptUrlTableRowBox>
                                        <ReceiptUrlTableRow>Order ID</ReceiptUrlTableRow>
                                        <ReceiptUrlTableRow>{slip.devInfo.orderId}</ReceiptUrlTableRow>
                                    </ReceiptUrlTableRowBox>
                                    <ReceiptUrlTableRowBox>
                                        <ReceiptUrlTableRow>Order Date</ReceiptUrlTableRow>
                                        <ReceiptUrlTableRow>{slip.devInfo.dateOrdered}</ReceiptUrlTableRow>
                                    </ReceiptUrlTableRowBox>
                                    {slip.devInfo.receipt.map((x, i) => <ReceiptUrlTableRowBox key={x.name}>
                                        <ReceiptUrlTableRow stronger={i === slip.devInfo.receipt.length - 1 ? true : false}>{x.name}</ReceiptUrlTableRow>
                                        <ReceiptUrlTableRow>{x.price}</ReceiptUrlTableRow>
                                    </ReceiptUrlTableRowBox>)}
                                </ReceiptUrlTable>
                            </ReceiptUrlSectionBox>
                        </ReceiptUrlTableWrap>
                        <ReceiptUrlBottom>
                            <ReceiptUrlBottomTitle>Questions about shipping?</ReceiptUrlBottomTitle>
                            <ReceiptUrlBottomTextBox>
                                <ReceiptUrlBottomText href={`mailto:${businessEmail}`}>{businessEmail}</ReceiptUrlBottomText>
                                <ReceiptUrlBottomText href={`tel:${businessPhoneNumber}`}>{businessPhoneNumber}</ReceiptUrlBottomText>
                            </ReceiptUrlBottomTextBox>
                        </ReceiptUrlBottom>
                    </ReceiptUrlWrap>
                </ReceiptUrlBox>
                :
                slip.loading ?
                    <ButtonLoader dark={true} receiptUrlPage={true} />
                    : (!slip.loading && !slip.img) ?
                        <ReceiptNotFound>Whoops! We did not find your packing slip, please contact us:<br /><a href={`mailto:${businessEmail}`}>{businessEmail}</a></ReceiptNotFound>
                        :
                        <PackingSlipImg src={slip.img} onLoad={imgLoaded} />
            }
        </>
    );
}

export default PackingSlip;