export const shippingLive = true;
export const billingOn = true;
// CONTACT
export const businessPhoneNumber = '1-888-887-6550'
export const businessEmail = 'support@product.pictures'
// STRIPE
export const stripeId = billingOn ? 'pk_live_SmoqqmtMdpSGnfI4Z95pqPI600NDU8FAvr' : 'pk_test_51GqnWvAcOCzMdJEBKdsjCev6OsJbS1q554MQdFXNrY5dS4UBbHdkFjTH5j9GmcJf4NJKBZ8eDtuboYvgw5g45QhY00tfeWsp7X'
export const brainTreeKey = billingOn ? 'production_38vz9m83_p5ngb489g6qgkdb2' : 'sandbox_x6yf8w85_cs2sydmdckv33sg3';
// CARRIERS
export const uspsId = shippingLive ? 'se-272046' : 'se-252641';
export const upsId = shippingLive ? 'se-272564' : 'se-252642';
export const fedExId = shippingLive ? 'se-272514' : 'se-252643';
// OUR ADDRESS, USED FOR SHIP ENGINE
export const ourAddress = {
    name: "Product Pictures",
    phone: businessPhoneNumber,
    address_line1: "3479 NE 163rd St. #108",
    city_locality: "North Miami Beach",
    state_province: "FL",
    postal_code: "33160",
    country_code: "US",
    address_residential_indicator: "no"
}