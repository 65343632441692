import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import NotFound from '../components/NotFound';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../components/members/Dashboard';
import ReceiptUrl from '../components/steps/ReceiptUrl';
import PrivacyPolicy from '../components/info/privacy';
import PackingSlip from '../components/steps/PackingSlip';
import Terms from '../components/info/terms';

const AppRouter = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route path="/rec/:id" component={ReceiptUrl} />
            <Route path="/packing" component={PackingSlip} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={Terms} />
            <Route component={NotFound} />
        </Switch>
    </Router>
);



export default AppRouter;