import React from 'react';
import { PriceBox, PriceBoxDropdown, OrderSummaryRow, PriceBoxLeft, PriceBoxRight, TotalRow, PriceBoxRow, PriceBoxLeftArrow, PriceBoxLeftArrowBox, OrderSummaryClose, OrderSummaryCloseIcon, ItemTotal } from '../style/main';

const OrderTotal = React.memo(({ photoAmount, form, setForm, steps, fade }) => {
    const onClick = () => {
        setForm(x => ({
            ...x,
            orderSummary: !x.orderSummary
        }))
    }
    const closeSummary = () => {
        setForm(x => ({
            ...x,
            orderSummary: false
        }))
    }
    return (
        <PriceBox noMarginBottom={steps[form.step].receipt ? true : false} fade={fade}>
            <PriceBoxDropdown show={steps[form.step].receipt ? true : form.orderSummary ? true : false}>
                <OrderSummaryRow onClick={closeSummary}>
                    <PriceBoxLeft>
                        <span>Order summary:</span>
                        <ItemTotal>({(form.chosenShippingRate.cost && form.chosenReturnedRate.cost) ? '3 Items' : form.chosenShippingRate.cost ? '2 Items' : form.chosenReturnedRate.cost ? '2 Items' : '1 Item'})</ItemTotal>
                    </PriceBoxLeft>
                    {!steps[form.step].receipt &&
                        <OrderSummaryClose>
                            <OrderSummaryCloseIcon viewBox="0 0 24 24">
                                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                            </OrderSummaryCloseIcon>
                        </OrderSummaryClose>
                    }
                </OrderSummaryRow>
                <PriceBoxRow>
                    <PriceBoxLeft>{photoAmount[form.photoAmount].amount} {photoAmount[form.photoAmount].amount === 1 ? 'Photo' : 'Photos'}</PriceBoxLeft>
                    <PriceBoxRight>${photoAmount[form.photoAmount].price.toFixed(2)}</PriceBoxRight>
                </PriceBoxRow>
                {form.chosenShippingRate.cost !== 0 &&
                    <PriceBoxRow>
                        <PriceBoxLeft>Shipping</PriceBoxLeft>
                        <PriceBoxRight>${form.chosenShippingRate.cost.toFixed(2)}</PriceBoxRight>
                    </PriceBoxRow>
                }
                {form.chosenReturnedRate.cost !== 0 &&
                    <PriceBoxRow>
                        <PriceBoxLeft>Return Shipping</PriceBoxLeft>
                        <PriceBoxRight>${form.chosenReturnedRate.cost.toFixed(2)}</PriceBoxRight>
                    </PriceBoxRow>
                }
            </PriceBoxDropdown>
            <TotalRow onClick={onClick} cursorDefault={steps[form.step].receipt ? true : false}>
                <PriceBoxLeft>
                    {!steps[form.step].receipt &&
                        <PriceBoxLeftArrowBox>
                            <PriceBoxLeftArrow viewBox="0 0 24 24" rotateIcon={form.orderSummary}>
                                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                            </PriceBoxLeftArrow>
                        </PriceBoxLeftArrowBox>
                    }
                    <span>{steps[form.step].stepBilling || steps[form.step].receipt ? 'Order Total' : 'Order Subtotal'}:</span>
                </PriceBoxLeft>
                <PriceBoxRight totalRow={true}>${(photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2)}</PriceBoxRight>
            </TotalRow>
        </PriceBox>
    );
})

export default OrderTotal;