import React, { useEffect, useRef } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const unsubscribe = useRef(false);
    const authId = useSelector(state => state.mainReducer.id)
    const checkSignIn = useRef(() => {
        unsubscribe.current = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const id = user.uid;
                dispatch({
                    type: 'USER', id
                })
            } else {
                console.log('no')
            }
        })
    })
    useEffect(() => {
        checkSignIn.current()
        return () => {
            if (unsubscribe.current) {
                unsubscribe.current()
            }
        };
    }, []);
    return (
        <Route {...rest} component={() => (!authId ? 'loading' : <Component />)} />
    );
}

export default PrivateRoute;