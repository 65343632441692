import React from 'react';
import { CompareSingleRow, CompareSingleCheck, PricePerTitleTotal, CompareTable, CompareSingleX, PricePer, PricePerTitle, PricePerText, CompareTop, CompareTopNumber, CompareTopText, PricePerTextTotal } from '../style/main';

const SavingsTable = React.memo(({ compare, photoAmount }) => {
    return (
        <CompareTable show={compare}>
            <tbody>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td className="numberRow">
                                <CompareTop>
                                    <CompareTopNumber>{x.amount}</CompareTopNumber>
                                    <CompareTopText>{x.amount === 1 ? 'Photo' : 'Photos'}</CompareTopText>
                                </CompareTop>
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td>
                                <CompareSingleRow>
                                    <CompareSingleCheck viewBox="0 0 24 24">
                                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                    </CompareSingleCheck>
                                    <span>1 Front angle photo</span>
                                </CompareSingleRow>
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td>
                                {x.amount > 1 ?
                                    <CompareSingleRow>
                                        <CompareSingleCheck viewBox="0 0 24 24">
                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                        </CompareSingleCheck>
                                        <span>1 Back angle photo</span>
                                    </CompareSingleRow>
                                    :
                                    <CompareSingleRow>
                                        <CompareSingleX viewBox="0 0 24 24">
                                            <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                        </CompareSingleX>
                                        <span>Not included</span>
                                    </CompareSingleRow>
                                }
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td>
                                {x.amount > 2 ?
                                    <CompareSingleRow>
                                        <CompareSingleCheck viewBox="0 0 24 24">
                                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                        </CompareSingleCheck>
                                        <span>3 Additional photo angles</span>
                                    </CompareSingleRow>
                                    :
                                    <CompareSingleRow>
                                        <CompareSingleX viewBox="0 0 24 24">
                                            <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                                        </CompareSingleX>
                                        <span>Not included</span>
                                    </CompareSingleRow>
                                }
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td>
                                <PricePer>
                                    <PricePerTitle>Price per photo:</PricePerTitle>
                                    <PricePerText>${(x.price / x.amount).toFixed(2)}</PricePerText>
                                </PricePer>
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td>
                                <PricePer>
                                    <PricePerTitle>Savings:</PricePerTitle>
                                    <PricePerText>{x.amount === 1 ? 0 : Math.round((photoAmount[0].price - (x.price / x.amount)) / photoAmount[0].price * 100)}%</PricePerText>
                                </PricePer>
                            </td>
                        </React.Fragment>
                    )}
                </tr>
                <tr>
                    {photoAmount.map((x, i) =>
                        <React.Fragment key={i}>
                            <td className="priceRow">
                                <PricePer>
                                    <PricePerTitleTotal>Sub total:</PricePerTitleTotal>
                                    <PricePerTextTotal>${x.price.toFixed(2)}</PricePerTextTotal>
                                </PricePer>
                            </td>
                        </React.Fragment>
                    )}
                </tr>
            </tbody>
        </CompareTable>
    );
})

export default SavingsTable;