const reviewText = [{
    name: 'Thomas B.',
    text: "Photos looked great! Super easy to order and was happy with the results. Would recommend.",
    title: 'Photos looked great!',
    rating: 5
}, {
    name: 'Cynthia S.',
    text: "I had a few questions regarding the product photos I needed. The customer service was willing to spend time answering all the questions I had and made me feel like they cared about the service they offered. Overall i'm very happy with the experience.",
    title: 'Customer service was helpful',
    rating: 5
}, {
    name: 'Shanice A.',
    text: "I run a small Amazon business that I only work on part time so i am on a tight budget. I found the pricing very affordable and found the final photos way out did what I paid for.",
    title: 'I liked the pricing',
    rating: 5
}, {
    name: 'Shane R.',
    text: "Ever since I put my product photos live we are seeing a nice % increase in sales. I think the professional look of our photos truly does help our sales on Amazon. We are launching a new product line and we will be planning on using the service again.",
    title: 'Seeing a sales increase',
    rating: 5
}, {
    name: 'Clarence J.',
    text: "We have about 30 different products in our Amazon catalogue. Product Pictures was able to offer us unbeatable bulk pricing and fully delivered just simply great photos for each of our products. If you're looking for professional Amazon product photos then this is the place.",
    title: 'Handled out quantity easily',
    rating: 5
}, {
    name: 'Becky H.',
    text: "I wish they offered Apple Pay but the support agent I spoke with was able to offer me multiple payment options. They advised me they would eventually include Apple Pay in their billing options and I would be willing to adjust my review to 5 stars when that happens. When it comes to the product photos I have no complaints as they were well done.",
    title: 'Wish they had Apple Pay',
    rating: 4
}, {
    name: 'Michael T.',
    text: "A+ A+ A+ !!!!.",
    title: 'A+!',
    rating: 5
}, {
    name: 'Tito M.',
    text: "I got my product photos, uploaded them to Amazon and the photos were approved, no guess work involved. Now lets see how the sales do!",
    title: 'Easy, simple, fast',
    rating: 5
}, {
    name: 'Debby V.',
    text: "I loved the Product Pictures online ordering system. Everything is done for you in the blink of an eye. I didn't have to spend hours figuring things out or negotiating with some sales person. Everything is so straight forward and made easy, even for someone like me with limited computer experience.",
    title: 'Process was straight forward',
    rating: 5
}, {
    name: 'Victor A.',
    text: "I had the most amateur looking product photos I shot with my iPhone listed on Amazon. I am almost embarrassed for such a small investment I did not get my product photos done sooner. As soon as I uploaded my product photos on Amazon I saw a big uptick in sales. Thanks!",
    title: 'Sales went boom!',
    rating: 5
}, {
    name: 'Nia S.',
    text: "I sell hair and beauty product on Amazon and had noooo idea how to use a camera to take product photos. I found this service while searching on Google and glad I tried! Photos look smoooth!",
    title: 'Easy',
    rating: 5
}, {
    name: 'Simon W.',
    text: "Great service",
    title: 'Great service',
    rating: 5
}]

export default reviewText;