import React from 'react';
import { LargeSliderImgSrc } from '../style/main';

const LargeSliderImg = React.memo(({ src, largeSrc, firstImg, setPopImg }) => {
    const onClick = () => {
        setPopImg({ largeSrc })
    }
    return (
        <>
            <LargeSliderImgSrc firstImg={firstImg} src={`/img/${src}`} alt="Product Pictures" className="largeSliderImg" onClick={onClick} />
        </>
    );
})

export default LargeSliderImg;