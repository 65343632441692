import React from 'react';

const SliderImg = React.memo(({ src, largeSrc, setPopImg }) => {
    const onClick = () => {
        setPopImg({ largeSrc })
    }
    return (
        <img src={`/img/${src}`} alt="Product Pictures" className="box" onClick={onClick} />
    );
})

export default SliderImg;