import styled, { keyframes } from 'styled-components/macro';
const boxPadding = '40px';
const boxPaddingLarge = '70px 40px';
const boxPadding2 = '40px 25px';
const boxPaddingLarge2 = '40px 25px';
const checkoutFade = '0.3'
export const FormOuter = styled.div`
    width: 100%;
    max-width: 470px;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1023px) {
        margin: auto;
    }
`
export const Form = styled.form`
    width: 100%;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`
export const FormCover = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 400;
    opacity: 0;
`
export const FormTitle = styled.div`
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: ${props => props.successStep ? '42px' : '29px'};
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: ${props => props.successStep ? 'center' : 'flex-start'};
    margin: ${props => props.successStep ? '0' : '0 0 15px'};
`
export const FormTitleBack = styled.div`
    margin: 0 15px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.calculating ? 'default' : 'pointer'};
`
export const FormTitleBackIcon = styled.svg`
    width: 12px;
    @media (max-width: 1151px) {
        width: 10px;
    }
`
export const FormTitleText = styled.div`
    line-height: 110%;
    @media (max-width: 1439px) {
        font-size: 25px;
    }
    @media (max-width: 1023px) {
        padding-right: 33px;
    }
`
export const Button = styled.button`
    width: 100%;
    height: ${props => props.gold ? '45px' : '50px'};
    background: #0061ff;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border: 0;
    padding: 0;
    outline: none;
    cursor: ${props => props.defaultPointer ? 'default' : 'pointer'};
    background: ${props => props.disabledButton ? '#8e8e8e' : props.gold ? '#ffc439' : '#0061ff'};
    border-radius: ${props => props.gold ? '5px' : '0'};
    opacity: ${props => props.fade ? checkoutFade : 1};
    transition: opacity 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const buttonLoaderFrames = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
export const ButtonLoader = styled.div`
    border-radius: 50%;
    width: ${props => props.receiptUrlPage ? '100px' : '35px'};
    height: ${props => props.receiptUrlPage ? '100px' : '35px'};
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: ${props => props.receiptUrlPage ? '10px' : '5px'} solid;
    border-right: ${props => props.receiptUrlPage ? '10px' : '5px'} solid;
    border-bottom: ${props => props.receiptUrlPage ? '10px' : '5px'} solid;
    border-left: ${props => props.receiptUrlPage ? '10px' : '5px'} solid;
    border-left-color: ${props => props.dark ? '#000000' : '#ffffff'};
    border-right-color: ${props => props.dark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
    border-top-color: ${props => props.dark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
    border-bottom-color: ${props => props.dark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
    transform: translateZ(0);
    animation: ${buttonLoaderFrames} 0.7s infinite linear;
    margin: ${props => props.noMargin ? '0' : props.receiptUrlPage ? '50px 0 0' : props.dark ? '0' : '0 0 0 12px'};
    &:after {
        border-radius: 50%;
        width: ${props => props.receiptUrlPage ? '100px' : '35px'};
        height: ${props => props.receiptUrlPage ? '100px' : '35px'};
        content: '';
    }
`
export const Input = styled.input`
    width: 100%;
    height: 40px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #bdc4c9;
    padding: 0 10px;
    border-radius: 4px;
`
export const LabelBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: ${props => props.switchMargin ? '0 0 15px;' : '15px 0 0;'};
    :first-child {
        margin: 0;
    }
    @media (max-width: 413px) {
        margin: ${props => props.switchMargin ? '0 0 35px;' : '15px 0 0;'};
    }
    @media (max-width: 374px) {
        font-size: 14px;
    }
`
export const Label = styled.label`
    color: #3d464d;
    font-size: 15px;
    @media (max-width: 374px) {
        font-size: 14px;
    }
`
export const PhotoCountBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const Tab = styled.div`
    width: 32.2%;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #bdc4c9;
    padding: 0 10px;
    border-radius: ${props => props.last ? '0 0 4px 4px' : '4px'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: ${props => props.selected ? '#ffffff' : '#f3f5f9'};
    font-weight: ${props => props.selected ? '700' : 'normal'};
    cursor: pointer;
    position: relative;
    :hover {
        background: #ffffff;
    }
`
export const TabValue = styled.div`
    width: calc(100% + 2px);
    height: 29px;
    position: absolute;
    left: -1px;
    top: -28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 4px 4px 0 0;
    background: linear-gradient(to left,#ff960f 0%,#fed212 100%);
    text-shadow: 0 0 2px rgb(123, 79, 17), 0 0 4px rgb(121, 121, 121);
    text-transform: uppercase;
    border: 1px solid #ff960f;
`
export const Radio = styled.div`
    padding: 3px;
    border-radius: 50%;
    border: 1px solid #bdc4c9; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    background: #ffffff;
`
export const RadioCircle = styled.div`
    width: 10px;
    min-width: 10px;
    height: 10px;
    background: #0061ff;
    border-radius: 50%;
    opacity: ${props => props.selected ? '1' : '0'};
`
export const InputRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0 0;
`
export const ExtraInfoRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
export const FormBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const YesNoBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
`
export const YesNoTab = styled.div`
    width: 50%;
    max-width: 115px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #bdc4c9;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background: ${props => props.selected ? '#ffffff' : '#f3f5f9'};
    font-weight: ${props => props.selected ? '700' : 'normal'};
    :hover {
        background: #ffffff;
    }
    :first-child {
        border-radius: 4px 0 0 4px;
    }
    :nth-child(2) {
        border-radius: 0 4px 4px 0;
        border-left: 0;
    }
    @media (max-width: 400px) {
        max-width: 105px;
    }
    @media (max-width: 374px) {
        max-width: 95px;
    }
`
export const BackgroundColorBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const BackgroundColorText = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const BackgroundColorTextType = styled.div`
    font-size: 15px;
    font-weight: 400; 
`
export const BackgroundColorTextBlurb = styled.div`
    font-size: 13px;
    margin: 0 0 0 7px;
    text-align: left;
    @media (max-width: 413px) {
        margin: 0 0 0 4px;
        font-size: 12px;
    }
`
export const BackgroundColor = styled.div`
    width: 27px;
    height: 27px;
    border: 1px solid #bdc4c9;
    border-radius: 4px;
    background: #ffffff;
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const BackgroundColorArrow = styled.svg`
    width: 50%;
    fill: #0061ff;
`
export const PriceBox = styled.div`
    width: 100%;
    border: 1px solid #b7c4d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin: ${props => props.noMarginBottom ? '25px 0' : '25px 0 15px'};
    border-radius: 4px;
    background: #e2edff;
    opacity: ${props => props.fade ? checkoutFade : 1};
    transition: opacity 0.2s;
`
const totalRowsHeight = '50px';
const totalRowsPadding = '0 15px';
const totalsBorderColor = '#b7c4d8 !important'
export const OrderSummaryRow = styled.div`
    width: 100%;
    height: ${totalRowsHeight};
    padding: ${totalRowsPadding};
    padding-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    border-bottom: 1px solid;
    border-color: ${totalsBorderColor};
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
`
export const PriceBoxRow = styled.div`
    width: 100%;
    height: ${totalRowsHeight};
    padding: ${totalRowsPadding};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f0f5ff;
    border-bottom: 1px solid #d4d7dc;
    :last-child {
        border: 0;
    }
`
export const OrderSummaryCloseIcon = styled.svg`
    width: 11px;
    height: 11px;
    fill: #47505f;
`
export const OrderSummaryClose = styled.div`
    height: 100%;
    padding: 0 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    :hover ${OrderSummaryCloseIcon}{
        fill: #202124;
    }
`
export const TotalRow = styled.div`
    width: 100%;
    height: ${totalRowsHeight};
    padding: ${totalRowsPadding};
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    cursor: ${props => props.cursorDefault ? 'default' : 'pointer'};
    font-weight: 700;
`
export const PriceBoxDropdown = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid;
    border-color: ${totalsBorderColor};
    display: ${props => props.show ? 'flex' : 'none'};
`
export const PriceBoxLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const PriceBoxLeftArrow = styled.svg`
    width: 13px;
    height: 13px;
    transition: 0.2s;
    transform: ${props => props.rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)'};
`
export const PriceBoxLeftArrowBox = styled.div`
    height: 100%;
    border-right: 1px solid #b7c4d8;
    cursor: pointer;
    margin: 0 15px 0 0;
    padding: 0 15px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const PriceBoxRight = styled.div`
    font-weight: ${props => props.totalRow ? '700' : 'normal'};
    color: ${props => props.totalRow ? '#00348a' : '#202124'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ItemTotal = styled.div`
    font-weight: normal;
    margin: 0 0 0 7px;
    font-size: 12px;
`
export const LabelExtraInfo = styled.div`
    font-size: 12px;
    color: #0061ff;
    margin: 0 0 0 10px;
    background: #eeeeee;
    border-radius: 4px;
    padding: 5px;
`
export const QuestionIcon = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #eeeeee;
    margin: 0 0 0 10px;
    color: #ffffff;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const AllBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
export const AllBoxRight = styled.div`
    width: 40%;
    height: 100%;
    background: #ffffff;
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999999;
    padding: 40px 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    overflow: auto;
    @media (max-width: 1599px) {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: 1279px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (max-width: 1151px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media (max-width: 1023px) {
        width: 100%;
        display: ${props => props.signupOpen ? 'flex' : 'none'};
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 350px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (max-width: 1023px) {
        width: 100%;
        display: ${props => props.signupOpen ? 'flex' : 'none'};
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 1023px) and (max-height: 627px) {
        padding-top: 17px;
    }
`
export const AllBoxLeft = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    &.noSelect {
        user-select: none;
    }
    @media (max-width: 1023px) {
        padding: 70px 0 0;
        width: 100%;
    }
    @media (max-width: 1023px) and (max-height: 650px) {
        padding: 60px 0 0;
    }
`
export const LogoBox = styled.div`
    flex-grow: 1;
    max-width: 320px;
    display: flex;
    flex-direction: ${props => props.footer ? 'row' : 'column'};
    align-items: center;
    justify-content: center;
    a {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 767px) {
        max-width: 300px;
    }
    @media (max-width: 1023px) and (max-height: 650px) {
        max-width: 280px;
    }
`
export const Logo = styled.img`
    width: 100%;
`
export const HeaderBox = styled.div`
    width: 100%;
    padding: 0 25px;
    height: 70px;
    min-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #334963;
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    @media (max-width: 1151px) {
        justify-content: center;
    }
    @media (max-width: 1023px) {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999999;
        justify-content: space-between;
    }
    @media (max-width: 1023px) and (max-height: 650px) {
        height: 60px;
        min-height: 60px;
    }
    @media (max-width: 450px) {
        padding: 0 15px;
    }
`
export const MenuBox = styled.ul`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
`
export const MenuBoxLi = styled.li`
    height: 100%;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-left: 1px solid;
    border-color: #405875 !important;
    position: relative;
    cursor: pointer;
    :hover {
        background: #222e3c;
    }
    :first-child {
        border: 0;
    }
`
export const MenuLoginBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    display: none;
    @media (max-width: 1023px) {
        display: flex;
    }
`
export const MenuLoginButton = styled.div`
    height: 40px;
    background: ${props => props.hollow ? 'none' : 'linear-gradient(to top,#ff960f 0%,#eac417 100%)'};
    border: ${props => props.hollow ? '3px solid #ffffff' : '0'};
    margin: ${props => props.hollow ? '0' : '0 0 0 15px'};
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    display: ${props => props.hollow ? 'none' : 'flex'};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding: 0 20px;
    cursor: pointer;
    text-shadow: 0 0 4px rgba(51, 43, 9, 0.7);
    white-space: nowrap;
    .mobile {
        display: none;
    }
    @media (max-width: 374px) {
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
    }
`
export const MenuBoxLiLine = styled.div`
    width: 100%;
    height: 5px;
    background: #ff960f;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: ${props => props.show ? '1' : '0'};
    transition: 0.2s;
`
export const LeftBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`
export const IntroBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: ${boxPadding};
    @media (max-width: 767px) {
        padding: ${boxPadding2};
        padding-top: 25px;
        padding-bottom: 25px;
    }
    @media (max-width: 1023px) and (max-height: 650px) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
`
export const IntroTop = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const introSliderSize1 = '300'
const introSliderSize2 = '220'
const introSliderSize3 = '200'
const introSliderSize4 = '150'
const introSliderSize5 = '140'
const introSliderSize6 = '135'
const sliderViewports = `
    @media (max-height: 767px), (max-width: 767px) {
        height: ${introSliderSize2}px;
        left: -${introSliderSize2}px;
        img {
            width: ${introSliderSize2}px;
            height: ${introSliderSize2}px;
        }
    }
    @media (max-height: 700px) {
        height: ${introSliderSize3}px;
        left: -${introSliderSize3}px;
        img {
            width: ${introSliderSize3}px;
            height: ${introSliderSize3}px;
        }
    }
    @media (max-width: 410px) and (max-height: 700px) {
        height: ${introSliderSize4}px;
        left: -${introSliderSize4}px;
        img {
            width: ${introSliderSize4}px;
            height: ${introSliderSize4}px;
        }
    }
    @media (max-width: 375px) and (max-height: 600px) {
        height: ${introSliderSize6}px;
        left: -${introSliderSize6}px;
        img {
            width: ${introSliderSize6}px;
            height: ${introSliderSize6}px;
        }
    }
    @media (max-width: 410px) and (max-height: 558px) {
        height: ${introSliderSize5}px;
        left: -${introSliderSize5}px;
        img {
            width: ${introSliderSize5}px;
            height: ${introSliderSize5}px;
        }
    }
    @media (max-width: 320px) and (max-height: 749px) {
        height: ${introSliderSize6}px;
        left: -${introSliderSize6}px;
        img {
            width: ${introSliderSize6}px;
            height: ${introSliderSize6}px;
        }
    }
`
export const IntroSlider = styled.div`
    width: 60%;
    height: ${introSliderSize1}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: fixed;
    left: 0 !important;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    z-index: 8999999;
    img {
        width: ${introSliderSize1}px;
        height: ${introSliderSize1}px;
        flex-shrink: 0;
        border-right: 1px solid #e6e8ec;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
    }
    ${sliderViewports}
    @media (max-width: 1023px) {
        width: 100%;
    }
    @media (max-height: 459px) {
        display: none;
    }
`
export const IntroSlideInner = styled.div`
    width: 100%;
    height: ${introSliderSize1}px;
    left: -${introSliderSize1}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    ${sliderViewports}
    background: #ffffff;
`
export const IntroSliderGradient = styled.div`
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    position: absolute;
    right: 0;
    top: 0;
`
export const ImgPopBox = styled.div`
    width: 60%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    overflow: auto;
    z-index: 9999999999;
    @media (max-width: 1023px) {
        width: 100%;
    }
`
export const ImgPopClose = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
`
export const ImgPopBg = styled.div`
    width: 100%;
    height: 120vh;
    background: #000000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    opacity: 0.5;
`
export const ImgPop = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
`
export const ImgPopXBox = styled.div`
    width: 70px;
    height: 70px;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 9999999;
    @media (max-width: 1023px) {
        position: fixed;
    }
    @media (max-width: 767px) {
        top: 5%;
        right: 5%;
    }
    @media (max-width: 375px) {
        width: 50px;
        height: 50px;
    }
`
export const ImgPopX = styled.svg`
    width: 100%;
    height: 100%;
    fill: #ff960f;
    cursor: pointer;
    position: relative;
    z-index: 50;
`
export const ImgPopXCircle = styled.div`
    width: 97%;
    height: 97%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ffffff;
    z-index: 0;
`
export const ImgPopImgBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 90%;
        height: 90%;
        max-width: 1500px;
        position: relative;
        z-index: 10000;
        object-fit: contain;
    }
    @media (max-width: 1023px) {
        img {
            width: 100%;
            height: 100%;
        }
    }
`
export const ImgPopImgBoxBlurb = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 45px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.7), 0 0 11px rgba(0, 0, 0, 0.7), 0 0 4px rgba(0, 0, 0, 0.7), 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 0 20px;
    line-height: 110%;
    @media (max-width: 1023px) {
        left: auto;
        right: 0;
        width: calc(95% - 50px);
    }
    @media (max-width: 767px) {
        font-size: 38px
    }
    @media (max-width: 550px) {
        font-size: 30px
    }
    @media (max-width: 400px) {
        font-size: 28px
    }
    @media (max-width: 374px) {
        padding: 0 40px;
    }
`
export const ImgPopControls = styled.div`
    width: 50px;
    height: 140px;
    border-radius: 5px;
    position: fixed;
    left: 5%;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 8999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 1px rgb(99, 71, 27);
`
export const ImgPopControl = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.hide ? 'default' : 'pointer'};
    border: 1px solid #ecc017;
    :first-child {
        background: linear-gradient(to top,#ff960f 0%,#eac417 100%);
        border-radius: 5px 5px 0 0;
    }
    :last-child {
        border-top: 0;
        background: linear-gradient(to bottom,#ff960f 0%,#eac417 100%);
        border-radius: 0 0 5px 5px;
    }
`
export const ImgPopControlIcon = styled.svg`
    width: 62%;
    fill: #ffffff;
`
export const ExtraInfo = styled.div`
    width: 100%;
    background: #fff5e2;
    border: 1px solid #d8c6b7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
export const ExtraInfoButtonBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 17px;
`
export const Button2 = styled.div`
    height: 35px;
    background: ${props => props.grey ? '#737373' : props.calculating ? '#003284' : '#0061ff'};
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    border: 0;
    cursor: pointer;
    padding: 0;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    margin: 0 0 0 7px;
    white-space: nowrap;
    position: relative;
`
export const ExtraInputBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
`
export const ExtraInput = styled.input`
    width: 100%;
    height: 35px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #bdc4c9;
    padding: 0 10px;
    position: relative;
    z-index: 100;
`
export const ZipError = styled.div`
    width: 100%;
    padding: 11px 12px;
    position: absolute;
    top: 35px;
    background: #f70a40;
    color: #ffffff;
    font-size: 13px;
    border: 1px solid #af0e33;
    display: ${props => props.show ? 'flex' : 'none'};
    z-index: 0;
`
export const ShippingOptionsPriceHeader = styled.div`
    width: 100%;
    font-size: 13px;
    font-weight: 700;
    border-bottom: ${props => props.footer ? '0' : '1px solid #d8c6b7'};
    padding: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const ShippingOptionsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
export const ShippingOptionsRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
    padding: 17px;
    border-bottom: 1px solid #d8c6b7;
    background: ${props => props.selected ? '#e6dbc6' : 'none'};
    cursor: pointer;
    .upsLogo {
        max-width: 25px;
    }
    .fedexLogo {
        max-width: 40px;
    }
    .uspsLogo {
        max-width: 35px;
    }
`
export const ShippingOptionsText = styled.div`
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
export const ShippingOptionsTextType = styled.div`

`
export const ShippingOptionsTextCount = styled.div`
    margin: 5px 0 0;
    font-weight: normal;
`
export const ShippingOptionsLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const ShippingOptionsPrice = styled.div`
    
`
export const ShippingOptionsLogo = styled.div`
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const ShippingOptionsBox = styled.div`
    width: 100%;
    overflow: hidden;
`
export const IntroTitle = styled.h1`
    width: 100%;
    font-size: 65px;
    line-height: 110%;
    .orange {
        color: #ff960f;
        position: relative;
    }
    @media (max-width: 1440px) {
        font-size: 58px;
    }
    @media (max-width: 1279px) {
        font-size: 50px;
    }
    @media (max-width: 1151px) {
        font-size: 45px;
    }
    @media (max-width: 1023px) {
        font-size: 57px;
    }
    @media (max-width: 767px) {
        font-size: 7.4vw;
    }
    @media (max-width: 740px) {
        font-size: 7.6vw;
    }
    @media (max-width: 450px) {
        font-size: 11vw;
        line-height: 98%;
    }
`
export const InnerH2Title = styled.h2`
    width: 100%;
    font-size: 51px;
    line-height: 107%;
    .orange {
        color: #ff960f;
        position: relative;
    }
    @media (max-width: 1365px) {
        font-size: 48px;
    }
    @media (max-width: 1279px) {
        font-size: 43px;
    }
    @media (max-width: 500px) {
        padding: ${props => props.mobilePadding ? '0 20px' : '0'};
    }
`
export const IntroTitleLine = styled.div`
    width: 100%;
    height: 5px;
    background: #405875;
    position: absolute;
    left: 0;
    bottom: 0;
`
export const IntroTitle2 = styled.div`
    width: 100%;
    max-width: 750px;
    font-size: 32px;
    color: #696a6d;
    @media (max-width: 1151px) {
        font-size: 27px;
    }
    @media (max-width: 1023px) {
        font-size: 22px;
    }
    @media (max-height: 767px) and (max-width: 1023px) {
        font-size: 19px;
    }
    @media (max-height: 630px) and (max-width: 1023px) {
        font-size: 17px;
    }
    @media (max-height: 600px) and (max-width: 1023px) {
        font-size: 16px;
    }
`
export const CreatedBySmall = styled.div`
    font-size: 23px;
    opacity: 0.8;
    width: 100%;
    text-align: center;
    color: #202124;
    margin: 10px 0 12px;
    text-transform: capitalize;
    @media (max-height: 767px), (max-width: 374px) {
        font-size: 17px;
    }
    @media (max-height: 767px) {   
        margin: 10px 0 8px;
    }
`
export const IntroWrap = styled.div`
    width: 100%;
    max-width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const IntroP = styled.p`
    width: 100%;
    margin: 20px 0 0;
    font-size: 19px;
    line-height: 140%;
    @media (max-width: 1023px) and (max-height: 767px) {
        font-size: 16px;
        margin: 15px 0 0;
    }
    @media (max-width: 650px) and (max-height: 767px) {
        font-size: 16px;
        margin: 12px 0 0;
    }
    @media (max-width: 650px) and (max-height: 630px) {
        font-size: 15px;
        margin: 10px 0 0;
        line-height: 130%;
    }
`
export const LargeProductTitle = styled.div`
    width: 100%;
    background: #334963;
    color: #ffffff;
    font-size: 31px;
    font-weight: 500;
    max-width: 750px;
    padding: 30px;
    text-align: center;
    line-height: 120%;
    span {
        color: yellow;
    }
    @media (max-width: 1439px) {
        font-size: 28px;
    }
    @media (max-width: 767px) {
        font-size: 24px;
    }
`
export const LargeProductAll = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e9ecf1;
    padding: ${boxPadding};
    @media (max-width: 767px) {
        padding: ${boxPadding2};
    }
    @media (max-width: 1599px) {
        padding-left: 0;
        padding-right: 0;
    }
    @media (max-width: 650px) {
        padding: 0;
    }
`
export const LargeProductWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    @media (max-width: 650px) {
        background: #ffffff;
    }
`
export const LargeProductBox = styled.div`
    width: 100%;
    max-width: 750px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @media (max-width: 650px) {
        padding: 10px 0 0;
        border: 0;
        box-shadow: none;
    }
`
export const LargeProductMobileArrowBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    display: none;
    @media (max-width: 650px) {
        display: flex;
    }
`
export const LargeProductMobileArrowWrap = styled.div`
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const LargeSliderBox = styled.div`
    width: 100%;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 650px) {
        border: 0;
        box-shadow: none;
    }
`
export const LargeSliderImgSrc = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: ${props => props.firstImg ? 'relative' : 'absolute'};
    opacity: 0;
    left: 0;
    top: 0;
    cursor: zoom-in;
`
export const LargeSliderImg = styled.div`
`
export const LargeProductBoxBadge = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    right: 7%;
    top 7%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ff960f;
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    @media (max-width: 450px) {
        width: 110px;
        height: 110px;
        right: 15px;
    }
    @media (max-width: 374px) {
        width: 100px;
        height: 100px;
    }
`
export const LargeProductBoxBadgeText = styled.div`
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 450px) {
        font-size: 16px;
    }
    @media (max-width: 374px) {
        font-size: 14px;
    }
`
export const LargeProductBoxBadgePrice = styled.div`
    font-weight: 700;
    font-size: 47px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    letter-spacing: -1px;
    margin: -1px 0 0;
    @media (max-width: 450px) {
        font-size: 41px;
    }
`
export const LargeProductBoxBadgeTextSmall = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const LargeProductBoxSteps = styled.div`
    position: absolute;
    max-height: 230px;
    padding: 0 10px;
    left: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  
    z-index: 100;
    @media (max-width: 650px) {
        left: 20px;
    }
`
export const LargeProductBoxStepsCircle = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${props => props.selected ? '#929396' : '#eeeeee'};
    margin: 0 0 13px;
    cursor: pointer;
    :last-child {
        margin: 0;
    }
    @media (max-width: 375px) {
        width: 17px;
        height: 17px;
        margin: 0 0 12px;
    }
`
export const LargeProductBoxArrowBox = styled.div`
    width: 90px;
    min-width: 90px;
    padding: 30px;
    cursor: ${props => props.topBoxes ? 'default' : 'pointer'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    :first-child {
        padding-left: 0;
    }
    :last-child {
        padding-right: 0;
    }
    @media (max-width: 1599px) {
        padding: 15px !important;
    }
    @media (max-width: 1279px) {
        padding: 5px !important;
        width: 60px;
        min-width: 60px;
    }
    @media (max-width: 650px) {
        width: 55px;
        min-width: 55px;
        padding: 10px 10px 20px 10px !important;
        display: ${props => props.mobileShow ? 'flex' : 'none'};
    }
`
export const LargeProductBoxArrow = styled.svg`
    width: 95%;
    fill: #37383c;
    @media (max-width: 1279px) {
        width: 80%;
    }
    @media (max-width: 650px) {
        width: 100%;
    }
`
export const DownArrowHeader = styled.svg`
    width: 55px;
    height: 55px;
    margin: 30px 0 0;
    cursor: pointer;
    @media (max-width: 1023px) and (max-height: 767px) {
        margin: 20px 0 0;
    }
    @media (max-height: 600px) {
        width: 45px;
        height: 45px;
    }
`
export const HeaderBoxIcons = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const HeaderBoxIconBox = styled.div`
    height: 100%;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
`
export const HeaderBoxIcon = styled.svg`
    width: 25px;
    height: 25px;
    fill: #ffffff;
    margin: 0 10px 0 0;
`
export const BoostProfitsBox = styled.div`
    width: 100%;
    padding: ${boxPaddingLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2};
    }
`
export const HowItWorksBox = styled.div`
    width: 100%;
    padding: ${boxPaddingLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e9ecf1;
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2};
    }
    @media (max-width: 1151px) {
        padding-left: 0;
        padding-right: 0;
    }
`
export const HowItWorksSteps = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0;
`
export const HowItWorksStep = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    @media (max-width: 1151px) {
        flex-direction: column;
        align-items: center;
    }
`
export const HowItWorksStepContent = styled.div`
    width: 100%;
    @media (max-width: 1151px) {
        text-align: center;
    }
`
const stepWidth = '200px';
export const HowItWorksStepNumber = styled.div`
    width: ${stepWidth};
    min-width: ${stepWidth};
    display: ${props => props.mobileVersion ? 'none' : 'flex'};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 1151px) {
        width: auto;
        min-width: initial;
        justify-content: center !important;
        display: ${props => props.mobileVersion ? 'flex' : 'none'};
        margin: ${props => props.mobileVersion ? '0 25px 0 0' : '0'};
    }
`
export const HowItWorksStepNumberLine = styled.div`
    width: 75px;
    height: 5px;
    background: #ff960f;
    @media (max-width: 1151px) {
        display: none;
    }
`
export const HowItWorksStepCircle = styled.div`
    width: 50px;
    height: 50px;
    font-size: 18px;
    background: #ff960f;
    color: #ffffff;
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    @media (max-width: 1151px) {
        width: 60px;
        height: 60px;
        font-size: 30px;
    }
`
export const HowItWorksStepVerticalBox = styled.div`
    width: ${stepWidth};
    min-width: ${stepWidth};
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
`
export const HowItWorksStepVerticalBox2 = styled.div`
    width: ${stepWidth};
    min-width: ${stepWidth};
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
    z-index: 0;
`
export const HowItWorksStepNumberLineVertical = styled.div`
    width: 5px;
    background: #ff960f;
`
export const HowItWorksStepIcon = styled.svg`
    width: 105px;
    margin: 0 0 15px;
    fill: #334963;
    &.download {
        width: 95px;
    }
    &.photo {
        width: 95px;
    }
`
export const HowItWorksStepLeftTitle = styled.div`
    font-size: 21px;
    font-weight: 500;
    text-transform: capitalize;
    @media (max-width: 1151px) {
        width: 100%;
        text-align: center;
        margin: 30px 0;
    }
`
export const HowItWorksStepLeftP = styled.p`
    margin: 7px 0 0;
    font-size: 16px;
    line-height: 140%;
    max-width: 600px;
    @media (max-width: 1151px) {
        margin: 0;
    }
`
export const HowItWorksStepsBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: right;
    ${HowItWorksStepContent} {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 0 15px 0 0;
        @media (max-width: 1151px) {
            flex-direction: row;
            align-items: center !important;
            padding: 0;
        }
    }
    :nth-child(even) {
        text-align: left;
    }
    :nth-child(even) ${HowItWorksStepContent} {
        align-items: flex-start;
        padding: 0 0 0 15px;
    }
    :nth-child(even) ${HowItWorksStepNumber} {
        justify-content: flex-end;
    }
    :last-child ${HowItWorksStepLeftP} {
        padding-bottom: 70px;
    }
    @media (max-width: 1151px) {
        padding: 70px 40px !important;
        border-top: 1px solid #cbd0d8;
        :last-child {
            border-bottom: 1px solid #cbd0d8; 
        }
        :last-child ${HowItWorksStepLeftP} {
            padding-bottom: 0;
        }
    }
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2} !important;
    }
`
export const HowItWorksWrap = styled.div`
    width: 100%;
    max-width: 1100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ParagraphH2 = styled.div`
    width: 100%;
    margin: 20px 0 10px;
    padding: 0 20px;
    font-size: 24px;
    line-height: 140%;
    color: #696a6d;
    br {
        display: none;
    }
    strong {
        color: #202124;
    }
    .noWrap {
        white-space: nowrap;
    }
    @media (max-width: 1151px) {
        font-size: 22px;
    }
`
export const CheckList = styled.div`
    width: 100%;
`
export const HowItWorksStepsBottomTitle = styled.h3`
    font-size: 45px;
    margin: 30px 0 0;
    span {
        color: #ff960f;
    }
    @media (max-width: 1151px) {
        margin: 70px 0 0;
    }
    @media (max-width: 1023px) {
        margin: 40px 0 0;
        padding: 0 20px;
    }
    @media (max-width: 374px) {
        font-size: 35px;
        line-height: 103%;
    }
`
export const ProductReturnedBlurb = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 0 0;
    @media (max-width: 1151px) {
        margin: 25px 0 0;
        padding: 0 40px;
    }
`
export const ProductReturnedBlurbTitle = styled.div`
    width: 100%;
    font-size: 25px;
    font-weight: 500;
`
export const ProductReturnedBlurbP = styled.p`
    width: 100%;
    max-width: 600px;
    margin: 25px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 140%;
    font-size: 19px;
    @media (max-width: 374px) {
        font-size: 17px;
    }
`
export const WhatYouGetBox = styled.div`
    width: 100%;
    padding: ${boxPaddingLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2};
    }
`
export const WhatYouGetChecks = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 35px 0 0;
`
export const WhatYouGetCheck = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 15px;
    :last-child {
        margin: 0;
    }
`
export const WhatYouGetCheckIcon = styled.svg`
    width: 45px;
    min-width: 45px;
    height: 45px;
    fill: #ff960f;
`
export const WhatYouGetCheckText = styled.div`
    margin: 0 0 0 20px;
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 1279px) {
        font-size: 23px;
    }
    @media (max-width: 374px) {
        font-size: 18px;
    }
`
export const FaqBox = styled.div`
    width: 100%;
    padding: ${boxPaddingLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e9ecf1;
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2};
    }
    @media (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
`
const faqBorderColor = '#b9b9b9 !important'
export const FaqQuestions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0;
    border-color: ${faqBorderColor};
    border-bottom: 1px solid;
`
export const FaqQuestion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${faqBorderColor};
    cursor: pointer;
    &.show {
        background: #ffffff;
    }
    @media (max-width: 500px) {
        border-left: 0;
        border-right: 0;
    }
    @media (max-width: 374px) {
        padding: 20px 15px;
    }
`
export const FaqQuestionLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const FaqQuestionIcon = styled.svg`
    width: 30px;
    min-width: 30px;
    height: 30px;
    fill: #ff960f;
`
export const FaqQuestionText = styled.div`
    margin: 0 0 0 15px;
    font-size: 21px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 500px) {
        font-size: 18px;
    }
`
export const FaqQuestionArrow = styled.svg`
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin: 0 0 0 30px;
    &.flip {
        transition: 0.2s;
        transform: rotate(180deg);
    }
    @media (max-width: 500px) {
        width: 15px;
        min-width: 15px;
    }
`
export const FaqQuestionAnswer = styled.div`
    width: 100%;
    padding: 30px;
    font-size: 15px;
    text-align: left;
    border: 1px solid;
    border-bottom: 0;
    border-color: ${faqBorderColor};
    line-height: 160%;
    display: none;
    background: #ffffff;
    &.show {
        display: block;
    }
    a {
        color: #000000;
        text-decoration: underline !important;
    }
    @media (max-width: 500px) {
        border-left: 0;
        border-right: 0;
        padding: 25px;
    }
`
export const ReviewsBox = styled.div`
    width: 100%;
    padding: ${boxPaddingLarge};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
        padding: ${boxPaddingLarge2};
    }
    @media (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
    }
`
export const Reviews = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
export const Review = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-top: 1px solid #e2e2e2;
    @media (max-width: 500px) {
        padding: 30px 20px;
    }
`
export const ReviewHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const ReviewProfileIcon = styled.svg`
    width: 30px;
    height: 30px;
    fill: #a7b5b6;
`
export const ReviewProfileUsername = styled.div`
    font-weight: 500;
    font-size: 17px;
    margin: 0 0 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const ReviewStarsBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0 0;
`
export const ReviewStars = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const ReviewTitle = styled.div`
    font-weight: 700;
    font-size: 17px;
    margin: 0 0 0 10px;
    text-align: left;
`
export const ReviewDate = styled.div`
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-size: 13px;
    color: #717171;
    margin: 5px 0 0;
`
export const ReviewP = styled.p`
    font-size: 14px;
    width: 100%;
    text-align: left;
    margin: 15px 0 0;
    line-height: 140%;
`
export const MoreReviewsBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e2e2e2;
    padding: 40px 0 0;
`
export const MoreReviewsButton = styled.button`
    padding: 15px 15px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(to bottom, #ffffff 0%,#f5f5f5 100%);
    outline: none;
`
export const ReviewPaginationBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
`
export const ReviewPaginationCount = styled.div`
    margin: 0 20px;
    font-size: 14px;
    font-weight: 500;
`
export const ReviewPaginationArrow = styled.button`
    width: 35px;
    height: 35px;
    background: #7b7b7b;
    border-radius: 5px;
    cursor: ${props => props.hide ? 'default' : 'pointer'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.hide ? '0.2' : '1'};
    border: 0;
    outline: 0;
`
export const ReviewPaginationArrowIcon = styled.svg`
    width: 50%;
    fill: #ffffff;
`
export const StarIcon = styled.svg`
    width: 15px;
    height: 15px;
    margin: 0 2px 0 0;
    :last-child {
        margin: 0;
    }
`
export const WriteAReviewBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    margin: 40px 0 0;
    border-top: 1px solid #e2e2e2;
    @media (max-width: 500px) {
        padding: 20px;
    }
`
export const ReviewTopRightPaging = styled.div`
    font-size: 14px;
    font-weight: 500;
`
export const SubmitReview = styled.div`
    padding: 12px 15px;
    background: #ffffff;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(to bottom, #ffffff 0%,#f5f5f5 100%);
`
export const VerifiedCustomerBlurb = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: #c46d00;
    margin: 0 0 0 12px;
    padding: 0 0 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #b9b9b9;
`
export const SubmitReviewBox = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid;
    border-color: #b9b9b9;
    display: ${props => props.show ? 'flex' : 'none'};
    margin: 0 0 40px;
    @media (max-width: 500px) {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
`
export const SubmitReviewTitle = styled.div`
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    padding-right: 20px;
`
export const SubmitReviewX = styled.svg`
    width: 15px;
    height: 15px;
    cursor: pointer;
`
export const SubmitReviewContent = styled.div`
    width: 100%;
    border-top: 1px solid;
    border-color: #b9b9b9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
export const SubmitReviewRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    border-bottom: 1px solid;
    border-color: #b9b9b9;
    :last-child {
        border: 0;
    }
`
export const SubmitReviewLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
`
export const SubmitReviewStars = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 6px 0 0;
`
export const SubmitReviewStar = styled.svg`
    width: 25px;
    height: 25px;
    margin: 0 2px 0 0;
    cursor: pointer;
`
export const SubmitReviewInputRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 0;
`
export const SubmitReviewInput = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid;
    border-color: #b9b9b9;
    background: #ffffff;
    font-size: 15px;
    padding: 0 10px;
    &.firstName {
        max-width: 200px;
    }
    &.lastName {
        max-width: 150px;
        margin: 0 0 0 10px;
    }
`
export const SubmitReviewTextArea = styled.textarea`
    width: 100%;
    height: 150px;
    border: 1px solid;
    border-color: #b9b9b9;
    background: #ffffff;
    font-size: 15px;
    padding: 10px;
    resize: none;
`
export const SubmitReviewBottom = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 15px;
`
export const SubmitReviewBottomButton = styled.button`
    padding: 15px 15px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(to bottom, #ffffff 0%,#f5f5f5 100%);
    outline: none;
`
export const CancelReviewButton = styled.div`
    padding: 15px 15px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(to bottom, #ffffff 0%,#f5f5f5 100%);
    outline: none;
    margin: 0 0 0 10px;
`
export const SubmitReviewError = styled.div`
    width: 100%;
    padding: 11px 12px;
    background: #ffd9e1;
    color: #af0e33;
    font-size: 13px;
    border: 1px solid #af0e33;
    text-align: left;
    margin: 7px 0 0;
    &.ratingError {
        margin: 15px 0 0;
        max-width: 250px;
    }
`
export const SubmitReviewErrorBottom = styled.div`
    width: 100%;
    text-align: left;
    color: #f70a40;
    font-size: 16px;
    font-weight: 500;
    padding: 0 15px;
    margin: 20px 0 0;
`
export const SkipSmall = styled.div`
    width: 100%;
    text-align: center;
    margin: 15px 0 0;
    font-size: 12px;
    cursor: ${props => props.hide ? 'default' : 'pointer'};
    opacity: ${props => props.hide ? '0' : '1'};
`
export const PlacesInputRowSingle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.half {
        width: calc(50% - 5px);
    }
    &.third {
        width: calc(33.3% - 5px);
    }
`
const inputCss = `
    width: 100%;
    border: 1px solid #bdc4c9;
    height: 50px;
    border-radius: 4px;
    line-height: normal;
    font-size: 13px;
    padding: 0 15px;
    outline: none;
    box-shadow: none;
    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px #ffffff inset;
    }
`
export const PlacesInputRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0 0;
    .ap-icon-pin {
        display: none;
    }
    .ap-input-icon {
        cursor: pointer;
    }
    .ap-input-icon svg, .ap-input-icon:hover svg {
        fill: #828282 !important;
    }
    .ap-dropdown-menu {
        max-height: 145px;
        overflow: auto;
    }
    :first-child {
        margin: 0;
    }
    .StripeElement {
        ${inputCss}
        height: auto;
        padding: 16px 15px;
        margin: 10px 0 0;
    }
    .cardNumber {
        margin: 0;
    }
`
export const PlacesInput = styled.input`
    ${inputCss}
    margin: ${props => props.noMargin ? '0' : '10px 0 0'};
`
export const SelectBox = styled.select`
    width: 100%;
    border: 1px solid #bdc4c9;
    height: 50px;
    border-radius: 4px;
    line-height: normal;
    font-size: 13px;
    padding: 0 15px;
    outline: none;
    background: #ffffff;
    margin: ${props => props.noMargin ? '0' : '10px 0 0'};
    box-shadow: none;
    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px #ffffff inset;
    }
    &.half {
        width: calc(50% - 5px);
    }
    &.weight {
        width: auto;
        flex-grow: 1;
        max-width: 275px;
        appearance: none;
        background: ${props => props.bgImg ? `url(${props.bgImg})` : 'none'} no-repeat right 17px center;
        background-size: 17px auto;
    }
`
export const FormError = styled.div`
    width: 100%;
    min-height: 50px;
    padding: 11px 12px;
    background: #ffd9e1;
    color: #af0e33;
    font-size: 13px;
    border: 1px solid #af0e33;
    text-align: left;
    margin: 7px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    a {
        color: #14202d;
    }
    .html {
        font-size: 15px;
    }
    .htmlA {
        white-space: nowrap;
    }
`
export const PlacesBox = styled.div`
    width: 100%;
    position: relative;
    margin: 10px 0 0;
`
export const WeightBox = styled.div`
    width: 100%;
    margin: 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const WeightBoxText = styled.div`
    height: 50px;
    font-size: 13px;
    margin: 0 0 0 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const UseSameAddress = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin: ${props => props.extraMargin ? '0 0 15px;' : '0'};
`
export const UseSameAddressCheckBox = styled.div`
    width: 18px;
    height: 18px;
    border: 1px solid #bdc4c9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
`
export const UseSameAddressCheck = styled.svg`
    width: 80%;
    display: ${props => props.show ? 'block' : 'none'};
`
export const ShippingReturnedRatesTab = styled.div`
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #bdc4c9;
    padding: ${props => props.boldFont ? '15px 20px' : '20px'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props => props.selected ? '#ffffff' : '#f3f5f9'};
    font-weight: ${props => props.boldFont ? '700' : 'normal'};
    cursor: pointer;
    border-radius: 4px;
    :last-child {
        margin: 10px 0 0;
    }
    :hover {
        background: #ffffff;
    }
`
export const ShippingReturnedRatesTabText = styled.div`
    flex-grow: 1;
    text-align: left;
    margin: 0 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const ShippingReturnedRatesTabTextLeft = styled.div`
    font-size: 15px;
    margin: 0 15px 0 0;
`
export const ShippingReturnedRatesTabTextRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ShippingReturnedRatesPrice = styled.div`
    padding: 10px;
    color: #ffffff;
    background: #ff960f;
    margin: 4px 0 0;
    font-size: 13px;
`
export const ShippingReturnedRatesPriceText = styled.div`
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: #6b410a;
`
export const Expiry = styled.div`
    width: 66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ExpiryRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
export const CCRow = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
`
export const CCLogos = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
`
export const CCImg = styled.img`
    margin: 0 12px 0 0;
    opacity: ${props => props.show ? '1' : '0.2'};
    transition: 0.1s;
    &.vs {
        width: 40px;
    }
    &.mc {
        width: 27px;
    }
    &.amx {
        width: 45px;
    }
    &.disc {
        width: 64px;
    }
    @media (max-width: 1439px) {
        margin: 0 8px 0 0;
    }
    @media (max-width: 1151px) {
        &.vs {
            width: 35px;
        }
        &.mc {
            width: 22px;
        }
        &.amx {
            width: 42px;
        }
        &.disc {
            width: 59px;
        }
    }
    @media (max-width: 400px) {
        margin: 0 5px 0 0;
        &.vs {
            width: 30px;
        }
        &.mc {
            width: 17px;
        }
        &.amx {
            width: 37px;
        }
        &.disc {
            width: 54px;
            margin: 0 10px 0 0;
        }
    }
    @media (max-width: 360px) {
        margin: 0 4px 0 0;
        &.vs {
            width: 25px;
        }
        &.mc {
            width: 12px;
        }
        &.amx {
            width: 32px;
        }
        &.disc {
            width: 49px;
            margin: 0 10px 0 0;
        }
    }
`
export const SuccessIconBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0;
    display: ${props => props.show ? 'flex' : 'none'};
`
export const SuccessIcon = styled.svg`
    width: 145px;
    fill: #4bc31e;
    transform: ${props => props.grow ? 'scale(1)' : 'scale(0)'};
    transition: 0.2s;
`
export const SuccessLoading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0;
`
export const SuccessLoadingText = styled.div`
    margin: 0 0 0 12px;
    font-size: 27px;
`
export const ReceiptBox = styled.div`
    width: 100%;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ReceiptIdText = styled.div`
    width: 100%;
    font-size: 16px;
    text-align: left;
`
export const ReceiptPdf = styled.a`
    width: 100%;
    background: linear-gradient(to top,#ff960f 0%,#eac417 100%);
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    cursor: pointer;
    text-shadow: 0 0 4px rgba(51, 43, 9, 0.7);
    font-size: 21px;
    border: 1px solid #905e18;
    margin: 15px 0 0;
`
export const ReceiptSupportBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #d0d0d0 !important;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
`
export const ReceiptSupportTitle = styled.div`
    width: 100%;
    font-size: 21px;
    font-weight: 500;
    text-align: left;
`
export const ReceiptSupportTypes = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
`
export const SupportTypeBox = styled.div`
    width: 100%;
    text-align: left;
    padding: 5px 0;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ReceiptSupportTypeLink = styled.a`
width: 100%;
`
export const ReceiptSupportType = styled.div`
    font-weight: 700;
    color: #ff960f;
    width: 100%;
`
export const PrintReceipt = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 0 25px;
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`
export const PrintReceiptIcon = styled.svg`
    width: 24px;
    margin: 0 15px 0 0;
`
export const PrintReceiptText = styled.div`
`
export const ReceiptUrlBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`
export const ReceiptUrlWrap = styled.div`
    width: ${props => props.packing ? '8.3in' : '100%'};
    height: ${props => props.packing ? '10in' : 'auto'};
    max-width: 800px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background: #ffffff;
`
export const ReceiptUrlTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    background: #334963;
`
export const ReceiptUrlLogo = styled.img`
    width: 100%;
    max-width: 321px;
`
export const ReceiptUrlWebsite = styled.div`
    color: #febf11;
    font-weight: 700;
    font-size: 24px;
    a {
        color: #febf11;
    }
    @media (max-width: 700px) {
        display: none;
    }
`
export const ReceiptUrlOrderNum = styled.div`
    font-weight: 700;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    @media (max-width: 374px) {
        font-size: 15px;
    }
`
export const ReceiptUrlOrderNumLeft = styled.div`

`
export const ReceiptUrlOrderNumRight = styled.div`
    color: #ff960f;
    margin: ${props => props.noMargin ? '0' : '0 0 0 10px'};
`
export const PackingSlipTitle = styled.div`
    color: #ff960f;
    width: 100%;
    text-align: center;
    font-size: 18px;
`
export const ReceiptUrlTitleBottom = styled.div`
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 25px;
    font-size: 17px;
    border: 1px solid #000000;
    border-bottom: 0 !important;
    text-transform: capitalize;
`
export const ReceiptUrlTableWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ReceiptUrlTable = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.shipping ? 'row' : 'column'};
    align-items: stretch;
    justify-content: space-between;
    font-size: ${props => props.shipping ? '15px' : '17px'};
    border: 1px solid #000000;
    @media (max-width: 700px) {
        flex-direction: column;
    }
`
export const ReceiptUrlTableRowBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border-bottom: 1px solid #000000;
    :last-child {
        border: 0;
    }
    @media (max-width: 700px) {
        flex-direction: column;
    }
    @media (max-width: 374px) {
        font-size: 13px;
    }
`
export const ReceiptUrlTableRow = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-weight: ${props => props.stronger ? '700' : 'normal'};
    :first-child {
        border-right: 1px solid #000000;
    }
    @media (max-width: 700px) {
        width: 100%;
        :first-child {
            border-bottom: 1px solid #000000;
            border-right: 0;
        }
    }
`
export const ReceiptUrlSectionBox = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${props => props.shipping ? '30px 0 0' : '0'};
`
export const ReceiptUrlShippingBoxSide = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    @media (max-width: 700px) {
        width: 100%;
    }
`
export const ReceiptUrlShippingBoxSideTitle = styled.div`
    width: 100%;
    padding: 0 0 15px;
    font-weight: 700;
`
export const ReceiptUrlShippingBoxSideList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    li {
        width: 100%;
    }
`
export const ReceiptUrlShippingBoxSideListLi = styled.li`
    width: 100%;
`
export const ReceiptLogoLink = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ReceiptLogo = styled.img`
    width: 100%;
    max-width: 321px;
`
export const ReceiptUrlBottom = styled.div`
    width: 100%;
    padding: 35px 25px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
`
export const ReceiptUrlBottomTitle = styled.div`
    width: 100%;
    font-weight: 700;
    text-align: center;

`
export const ReceiptUrlBottomTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ReceiptUrlBottomText = styled.a`
    color: #000000;
    margin: 20px 0 0;
`
export const ReceiptNotFound = styled.div`
    width: 90%;
    text-align: center;
    margin: 50px 0 0;
    font-size: 25px;
    font-weight: 500;
`
export const FormCornerX = styled.div`
    width: 75px;
    height: 75px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.fade ? checkoutFade : 1};
    @media (max-width: 1023px) {
        display: flex;
    }
    @media (max-width: 700px) {
        width: 55px;
        height: 55px;
    }
`
export const FormCornerXIcon = styled.svg`
    width: 30%;
`
export const SupportBottomBox = styled.div`
    width: 100%;
    background: #334963;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const SupportBottomLogoBox = styled.div`
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #4e6f96;
`
export const SupportBottomTitle = styled.div`
    width: 100%;
    font-size: 35px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 0 20px;
    margin: 50px 0 0;
    line-height: 107%;
    .orange {
        color: #fec012;
        position: relative;
    }
`
export const SupportBottomBlurb = styled.div`
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 19px;
    max-width: 600px;
    line-height: 160%;
    padding: 0 20px;
    margin: 20px 0 0;
`
export const SupportInfoBottom = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #4e6f96;
    margin: 50px 0 0;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`
export const SupportInfoBottomText = styled.div`
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :first-child {
        border-right: 1px solid #4e6f96;
    }
    @media (max-width: 767px) {
        width: 100%;
        :first-child {
            border: 0;
            border-bottom: 1px solid #4e6f96;
        }
    }
`
export const SupportInfoBottomTextLink = styled.a`
    width: 100%;
    padding: 50px 0;
    color: #ffffff;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const SupportTextIcon = styled.svg`
    width: 40px;
    fill: #ffffff;
`
export const SupportInfoBottomTextInfo = styled.div`
    width: 100%;
    text-align: center;
    margin: 20px 0 0;
    font-size: 25px;
    font-weight: 700;
    color: #ffffff;
    @media (max-width: 1279px) {
        font-size: 19px;
    }
    @media (max-width: 1023px) {
        font-size: 25px;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
    @media (max-width: 374px) {
        font-size: 6.5vw;
    }
`
export const CopyRight = styled.div`
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    border-top: 1px solid #4e6f96;
    padding: 30px 20px;
    opacity: 0.7;
    a {
        color: #ffffff;
    }
`
export const ScrollUpArrow = styled.svg`
    width: 35px;
    min-width: 35px;
    fill: #ffffff;
    margin: 0 0 0 10px;
    @media (max-width: 374px) {
        width: 30px;
        min-width: 30px;
    }
`
export const SupportLogoBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    cursor: pointer;
    @media (max-width: 1151px) {
        width: 100%;
    }
`
export const LogoBottom = styled.img`
    width: 100%;
    max-width: 320px;
`
export const LogoBottomBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const CompareBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    background: #ffffff;
    border: 1px solid #bdc4c9;
    border-radius: 4px;
    margin: 10px 0 0;
`
export const CompareSingle = styled.div`
    width: calc(100% / 3);
    border-right: 1px solid #bdc4c9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    :last-child {
        border: 0;
    }
`
export const CompareSingleRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const CompareSingleCheck = styled.svg`
    width: 12px;
    margin: 0 0 6px;
    fill: #269621;
`
export const CompareSingleX = styled.svg`
    width: 10px;
    fill: #4c3636;
    margin: 0 0 8px;
`
export const CompareTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    background: #ffffff;
    margin: 0 0 20px;
    line-height: 100%;
    display: ${props => props.show ? 'flex' : 'none'};
    tbody {
        width: 100%;
    }
    td, th {
        border: 1px solid #bdc4c9;
        padding: 15px 12px;
        font-size: 13px;
        width: 33%;
        text-align: center;
    }
    th {
        padding: 4px;
    }
    td.numberRow {
        font-size: 17px;
        font-weight: 700;
        line-height: 135%;
    }
    td.numberRow:first-child {
        color: #183146;
    }
    td.numberRow:nth-child(2) {
        color: #254618;
    }
    td.numberRow:nth-child(3) {
        color: #462d18;
    }
    td.priceRow {
        font-size: 14px;
        font-weight: 700;
        padding: 20px 0;
    }
    td:first-child {
        background: #e9f5ff;
    }
    td:nth-child(2) {
        background: #f1fff1;
    }
    td:nth-child(3) {
        background: #fff9ee;
    }
    th {
        border-top: 0;
        border-bottom: 0;
    }
    th:first-child {
        background: #267fca;
        border-color: #267fca !important;
    }
    th:nth-child(2) {
        background: #4ff34f;
        border-color: #4ff34f !important;
    }
    th:nth-child(3) {
        background: linear-gradient(90deg,#007cff 0,#d800ff);
        border-color: #d800ff !important;
    }
`
export const PricePer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const PricePerTitle = styled.div`
    font-weight: 700;
`
export const PricePerText = styled.div`
    margin: 5px 0 0;
`
export const PricePerTextTotal = styled.div`
    color: #B12704;
    margin: 5px 0 0;
`
export const CompareTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    @media (max-width: 374px) {
        font-size: 19px;
    }
`
export const CompareTopNumber = styled.div`
    
`
export const CompareTopText = styled.div`
    margin: 2px 0 0;
    @media (max-width: 374px) {
        margin: 0;
    }
`
export const PricePerTitleTotal = styled.div`
    font-weight: 700;
`
export const CompareSmall = styled.div`
    font-size: 15px;
    color: #0061ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    @media (max-width: 374px) {
        font-size: 14px;
    }
`
export const CompareSmallBoxOuter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
export const CompareSmallBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px 0;
    margin: 10px 0 14px;
`
export const CompareSmallRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 4px;
`
export const CompareSmallLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const CompareSmallArrowBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 7px 0 0;
`
export const CompareSmallArrow = styled.svg`
    width: 14px;
    fill: #0061ff;
    transform: ${props => props.rotateArrow ? 'rotate(180deg)' : 'rotate(0deg)'};
`
export const PPButtonBox = styled.div`
    width: 100%;
    height: 45px;
    display: ${props => props.show ? 'block' : 'none'};
    opacity: ${props => props.fade ? checkoutFade : 1};
    transition: opacity 0.2s;
    background: #ffc439;
`
export const PPButton = styled.div`
    
`
export const CheckoutOrBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: relative;
    margin: 19px 0 15px;
    opacity: ${props => props.fade ? checkoutFade : 1};
    transition: opacity 0.2s;
`
export const CheckoutOr = styled.div`
    position: relative;
    z-index: 100;
    background: #ffffff;
    padding: 0 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #585a63;
    font-size: 14px;
`
export const CheckoutOrLine = styled.div`
    width: 100%;
    background: #bdc4c9;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`
export const CheckoutInputBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: ${props => props.fade ? checkoutFade : 1};
    transition: opacity 0.2s;
`
export const PrivacyBox = styled.div`
    width: 100%;
    padding: 40px;
`
export const PackingSlipImg = styled.img`
    width: 800px;
`
export const ReceiptEmailBlurb = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px 25px 0;
    font-size: 17px;
`
export const ReceiptEmailBlurbTop = styled.div`
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 17px;
`
export const ReceiptEmailBlurbBottom = styled.div`
    width: 100%;
    text-align: center;
    margin: 15px 0 0;
`
export const CheckoutLoaderBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const CheckoutLoaderBoxText = styled.div`
    width: 100%;
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    margin: 25px 0 0;
`
export const CtaMobileSupportBottom = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #4e6f96;
    padding: 50px 25px;
    display: none;
    @media (max-width: 1023px) {
        display: flex;
    }
`
export const CtaMobile = styled.div`
    width: ${props => props.shrunkWidth ? 'calc(100% - 50px);' : '100%'};
    max-width: 500px;
    background: linear-gradient(to top,#ff960f 0%,#eac417 100%);
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    cursor: pointer;
    text-shadow: 0 0 4px rgba(51, 43, 9, 0.7);
    font-size: 21px;
    border: 1px solid #905e18;
    margin: ${props => props.noMargin ? '0' : '45px 0 0'};
    @media (max-width: 1023px) {
        display: flex;
    }
`
export const ToolTipBox = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
`
export const QuestionStepIconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px 0 5px;
    margin: 0 0 0 10px;
    @media (max-width: 400px) {
        padding: 0 10px 0 5px;
        margin: 0 0 0 7px;
    }
`
export const QuestionStepIcon = styled.svg`
    width: 16px;
    fill: #334963;
`
export const ToolTipTextFloat = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    background: #ffffff;
    padding: 3px 0;
    @media (max-width: 1279px) {
        line-height: 110%;
    }
    @media (max-width: 374px) {
        font-size: 12px;
    }
`
export const ToolTipText = styled.div`
    flex-grow: 1;
    max-width: 170px;
    color: #334963;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: default;
    position: relative;
    user-select: none;
`
export const ButtonLoaderPrepping = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const PreppingLock = styled.svg`
    width: 37px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    fill: #808080;
`
export const LabelErrorReceipt = styled.div`
    background: #fff7f9;
    color: #af0e33;
    font-size: 15px;
    border: 1px solid #af0e33;
    padding: 15px;
    margin: 15px 0 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
`