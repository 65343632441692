import React from 'react';
import { Label, LabelBox, PhotoCountBox, InputRow, FormBox, BackgroundColorBox, BackgroundColor, CompareSmall, CompareSmallBox, CompareSmallArrow, BackgroundColorArrow, BackgroundColorText, BackgroundColorTextBlurb, BackgroundColorTextType, CompareSmallLeft, CompareSmallRight, CompareSmallArrowBox, CompareSmallBoxOuter } from '../../style/main';
import FormYesNo from '../FormYesNo';
import FormTabs from '../FormTabs';
import SavingsTable from '../SavingsTable';

const Step0 = React.memo(({ form, setForm, photoAmount, compare, setCompare }) => {
    const onClick = () => {
        setCompare(x => !x)
    }
    return (
        <FormBox>
            <LabelBox>
                <Label>Background Color:</Label>
            </LabelBox>
            <InputRow>
                <BackgroundColorBox>
                    <BackgroundColor>
                        <BackgroundColorArrow viewBox="0 0 24 24">
                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                        </BackgroundColorArrow>
                    </BackgroundColor>
                    <BackgroundColorText>
                        <BackgroundColorTextType>White</BackgroundColorTextType>
                        <BackgroundColorTextBlurb>(All photos created on white background)</BackgroundColorTextBlurb>
                    </BackgroundColorText>
                </BackgroundColorBox>
            </InputRow>
            <LabelBox>
                <Label><strong>1.</strong> Want us to create your shipping label?</Label>
            </LabelBox>
            <InputRow>
                <FormYesNo type={'shipping'} form={form} setForm={setForm} />
            </InputRow>
            <LabelBox>
                <Label><strong>2.</strong> Do you need your product returned?</Label>
            </LabelBox>
            <InputRow>
                <FormYesNo type={'returned'} form={form} setForm={setForm} />
            </InputRow>
            <LabelBox>
                <Label><strong>3.</strong> How many photos do you need?</Label>
            </LabelBox>
            <CompareSmallBoxOuter>
                <CompareSmallBox onClick={onClick} className="compareSmallBox">
                    <CompareSmallArrowBox>
                        <CompareSmallArrow rotateArrow={compare} viewBox="0 0 24 24">
                            <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </CompareSmallArrow>
                    </CompareSmallArrowBox>
                    <CompareSmall>
                        <CompareSmallLeft>Compare</CompareSmallLeft>
                        <CompareSmallRight>Prices</CompareSmallRight>
                    </CompareSmall>
                </CompareSmallBox>
            </CompareSmallBoxOuter>
            <SavingsTable compare={compare} photoAmount={photoAmount} />
            {compare &&
                <LabelBox switchMargin={true}>
                    <Label><strong>3.</strong> How many photos do you need?</Label>
                </LabelBox>
            }
            <PhotoCountBox>
                {photoAmount.map((x, i) => <FormTabs count={i} formPhotoAmount={form.photoAmount} last={i === photoAmount.length - 1 ? true : false} setForm={setForm} {...x} key={i} />)}
            </PhotoCountBox>
        </FormBox>
    );
})

export default Step0;