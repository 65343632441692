import React from 'react';
import { Review, ReviewHead, ReviewProfileIcon, ReviewProfileUsername, ReviewStarsBox, ReviewTitle, ReviewStars, ReviewDate, ReviewP, StarIcon, VerifiedCustomerBlurb } from '../style/main';

const ReviewSingle = React.memo(({ name, text, rating, title }) => {
    return (
        <Review>
            <ReviewHead>
                <ReviewProfileIcon viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z" />
                </ReviewProfileIcon>
                <ReviewProfileUsername>{name}</ReviewProfileUsername>
                <VerifiedCustomerBlurb>Verified Customer</VerifiedCustomerBlurb>
            </ReviewHead>
            <ReviewStarsBox>
                <ReviewStars>
                    <StarIcon viewBox="0 0 24 24">
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" style={{ stopColor: "#ffcb0f", stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: "#ff960f", stopOpacity: 1 }} />
                        </linearGradient>
                        <path fill={rating >= 1 ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                    </StarIcon>
                    <StarIcon viewBox="0 0 24 24">
                        <path fill={rating >= 2 ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                    </StarIcon>
                    <StarIcon viewBox="0 0 24 24">
                        <path fill={rating >= 3 ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                    </StarIcon>
                    <StarIcon viewBox="0 0 24 24">
                        <path fill={rating >= 4 ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" strokeWidth="1" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                    </StarIcon>
                    <StarIcon viewBox="0 0 24 24">
                        <path fill={rating === 5 ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                    </StarIcon>
                </ReviewStars>
                <ReviewTitle>{title}</ReviewTitle>
            </ReviewStarsBox>
            <ReviewP>{text}</ReviewP>
        </Review>
    );
})

export default ReviewSingle;