import React from 'react';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { SupportBottomBox, LogoBottom, LogoBottomBox, SupportBottomTitle, SupportBottomBlurb, SupportBottomLogoBox, SupportInfoBottom, SupportInfoBottomText, SupportInfoBottomTextLink, SupportTextIcon, SupportInfoBottomTextInfo, CopyRight, ScrollUpArrow, SupportLogoBox, CtaMobile, CtaMobileSupportBottom } from '../style/main';
import { businessPhoneNumber, businessEmail } from './info/businessInfo'
import { Link } from 'react-router-dom';
gsap.registerPlugin(ScrollToPlugin);

const SupportBottom = React.memo(({ setSignupOpen }) => {
    const scrollTop = () => {
        gsap.to(window, { duration: 2, scrollTo: { y: 0 } });
    }
    const openForm = () => {
        document.querySelector('body').classList.add('overflow');
        setSignupOpen(true)
    }
    return (
        <SupportBottomBox>
            <SupportBottomLogoBox>
                <SupportLogoBox onClick={scrollTop}>
                    <LogoBottomBox>
                        <LogoBottom src={'/img/logo.png'} alt="Product.Pictures" />
                    </LogoBottomBox>
                    <ScrollUpArrow viewBox="0 0 24 24">
                        <path d="M12 0l8 9h-6v15h-4v-15h-6z" />
                    </ScrollUpArrow>
                </SupportLogoBox>
            </SupportBottomLogoBox>
            <SupportBottomTitle>
                Have any <span className="orange">questions?</span>
            </SupportBottomTitle>
            <SupportBottomBlurb>
                We would love to assist you! At product.pictures we pride ourselves on customer service. Please don't hesitate to reach out to one of our friendly representatives at any time using the contact information below.
            </SupportBottomBlurb>
            <SupportInfoBottom>
                <SupportInfoBottomText>
                    <SupportInfoBottomTextLink href={`mailto:${businessEmail}`}>
                        <SupportTextIcon viewBox="0 0 24 24">
                            <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
                        </SupportTextIcon>
                        <SupportInfoBottomTextInfo>
                            {businessEmail}
                        </SupportInfoBottomTextInfo>
                    </SupportInfoBottomTextLink>
                </SupportInfoBottomText>
                <SupportInfoBottomText>
                    <SupportInfoBottomTextLink href={`tel:${businessPhoneNumber}`}>
                        <SupportTextIcon viewBox="0 0 24 24">
                            <path d="M7 0c-1.105 0-2 .896-2 2v18.678c-.001 2.213 3.503 3.322 7.005 3.322 3.498 0 6.995-1.106 6.995-3.322v-18.678c0-1.104-.895-2-2-2h-10zm5 22c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm5-4h-10v-14h10v14z" />
                        </SupportTextIcon>
                        <SupportInfoBottomTextInfo>
                            {businessPhoneNumber}
                        </SupportInfoBottomTextInfo>
                    </SupportInfoBottomTextLink>
                </SupportInfoBottomText>
            </SupportInfoBottom>
            <CtaMobileSupportBottom>
                <CtaMobile onClick={openForm} noMargin={true}>Start Now</CtaMobile>
            </CtaMobileSupportBottom>
            <CopyRight>&copy; Copyright Product.Pictures | <Link to='/privacy'>Privacy Policy</Link> | <Link to='/terms'>Terms &#x26; Conditions</Link></CopyRight>
        </SupportBottomBox>
    );
})

export default SupportBottom;