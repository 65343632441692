import React from 'react';
import { FormBox, PlacesInputRow, LabelBox, PlacesInputRowSingle, PlacesInput, Label, FormError } from '../../style/main';

const StepEmail = React.memo(({ form, setForm }) => {
    const onchangeInput = (e) => {
        e.persist();
        setForm(x => ({
            ...x,
            account: {
                ...x.account,
                [e.target.name]: e.target.name === 'email' ? e.target.value.replace(/\s/g, "") : e.target.name === 'phone' ? e.target.value.replace(/\D+/g, '') : e.target.value
            },
            accountError: {
                ...x.accountError,
                [e.target.name]: false
            }
        }))
    }
    return (
        <FormBox>
            <PlacesInputRow>
                <PlacesInputRowSingle>
                    <LabelBox>
                        <Label>Introduce yourself</Label>
                    </LabelBox>
                    <PlacesInputRowSingle>
                        <PlacesInput name="firstName" type="text" onChange={onchangeInput} value={form.account.firstName ? form.account.firstName : ''} placeholder="First name" />
                        {form.accountError.firstName && <FormError>{form.accountError.firstName}</FormError>}
                    </PlacesInputRowSingle>
                </PlacesInputRowSingle>
            </PlacesInputRow>
            <PlacesInputRow>
                <PlacesInputRowSingle>
                    <PlacesInputRowSingle>
                        <PlacesInput name="lastName" type="text" onChange={onchangeInput} value={form.account.lastName ? form.account.lastName : ''} placeholder="Last name" noMargin={true} />
                        {form.accountError.lastName && <FormError>{form.accountError.lastName}</FormError>}
                    </PlacesInputRowSingle>
                </PlacesInputRowSingle>
            </PlacesInputRow>
            <PlacesInputRow>
                <PlacesInputRowSingle>
                    <LabelBox>
                        <Label>Phone number (digits only)</Label>
                    </LabelBox>
                    <PlacesInputRowSingle>
                        <PlacesInput name="phone" type="tel" onChange={onchangeInput} value={form.account.phone ? form.account.phone : ''} placeholder="Phone number" />
                        {form.accountError.phone && <FormError>{form.accountError.phone}</FormError>}
                    </PlacesInputRowSingle>
                </PlacesInputRowSingle>
            </PlacesInputRow>
            <PlacesInputRow>
                <PlacesInputRowSingle>
                    <LabelBox>
                        <Label>Which email do we send your order details to?</Label>
                    </LabelBox>
                    <PlacesInputRowSingle>
                        <PlacesInput name="email" type="email" onChange={onchangeInput} value={form.account.email ? form.account.email : ''} placeholder="Email address" />
                        {form.accountError.email && <FormError>{form.accountError.email}</FormError>}
                    </PlacesInputRowSingle>
                </PlacesInputRowSingle>
            </PlacesInputRow>
        </FormBox>
    );
})

export default StepEmail;