import React, { useEffect, useRef, useCallback } from 'react';
import places from 'places.js';
import { FormBox, PlacesInput, PlacesInputRow, LabelBox, Label, PlacesInputRowSingle, FormError, PlacesBox, UseSameAddress, UseSameAddressCheckBox, UseSameAddressCheck, SelectBox } from '../../style/main';
import { stateValues } from '../states'

const StepShipping = React.memo(({ form, setForm, type }) => {
    const placesRef = useRef(null);
    const boxRef = useRef(null);
    const inputRef = useRef(null);
    const shippingAddressForm = useRef(null);
    const onchangeInput = (e) => {
        e.persist();
        setForm(x => ({
            ...x,
            [`${type}Address`]: {
                ...x[`${type}Address`],
                [e.target.name]: e.target.value
            },
            [`${type}AddressError`]: {
                ...x[`${type}AddressError`],
                [e.target.name]: false
            }
        }))
    }
    useEffect(() => {
        if (inputRef.current) {
            placesRef.current = places({
                appId: 'pl5HQ5S8GDB2',
                apiKey: '2d7aba01d2e70ed82f0638961b4325af',
                container: inputRef.current,
                templates: {
                    value: (suggestion) => {
                        return suggestion.name;
                    }
                }
            }).configure({
                countries: ['us'],
                type: 'address'
            });
            placesRef.current.on('change', e => {
                const address = e.suggestion.name;
                const city = e.suggestion.city;
                const state = e.suggestion.administrative;
                const zipCode = e.suggestion.postcode;
                setForm(x => ({
                    ...x,
                    [`${type}Address`]: {
                        ...x[`${type}Address`],
                        address: address ? address : false,
                        city: city ? city : false,
                        state: state ? state : false,
                        zipCode: zipCode ? zipCode : false
                    },
                    [`${type}AddressError`]: {
                        ...x[`${type}AddressError`],
                        address: address ? false : x.address,
                        city: city ? false : x.city,
                        state: state ? false : x.state,
                        zipCode: zipCode ? false : x.zipCode
                    }
                }))
            });
            placesRef.current.on('suggestions', e => {
                // CHECK FOR BOX SHADOW ON AUTOFILL
                let autofilled = false;
                if (shippingAddressForm.current) {
                    const boxShadows = shippingAddressForm.current.querySelectorAll('input');
                    boxShadows.forEach(x => {
                        if (getComputedStyle(x).boxShadow !== 'none') {
                            autofilled = true
                        }
                    })
                }
                if (autofilled) {
                    placesRef.current.close()
                }
            });
            placesRef.current.on('clear', () => {
                setForm(x => ({
                    ...x,
                    [`${type}Address`]: {
                        address: '',
                        city: '',
                        zipCode: '',
                        state: ''
                    }
                }))
            });
        }
    }, [setForm, type]);
    const docClick = (e) => {
        // CLOSE PLACES BOX
        if (boxRef.current && !boxRef.current.contains(e.target)) {
            if (placesRef.current) {
                placesRef.current.close()
            }
        }
    }
    useEffect(() => {
        window.addEventListener('click', docClick);
        return () => {
            window.removeEventListener('click', docClick)
        }
    }, []);
    const switchUseSameAddress = useCallback(() => {
        if (form.returnedAddress.useSameAddress) {
            setForm(x => ({
                ...x,
                returnedAddress: {
                    address: false,
                    city: false,
                    zipCode: false,
                    state: false,
                    apt: false,
                    useSameAddress: false
                },
                returnCheckClicked: true
            }))
        } else {
            setForm(x => ({
                ...x,
                returnedAddress: {
                    ...x.shippingAddress,
                    useSameAddress: true
                },
                returnCheckClicked: true
            }))
        }

    }, [form, setForm]);
    const stateSelect = useCallback((e) => {
        e.persist()
        setForm(x => ({
            ...x,
            [`${type}Address`]: {
                ...x[`${type}Address`],
                state: e.target.value
            },
            [`${type}AddressError`]: {
                ...x[`${type}AddressError`],
                state: false
            }
        }))
    }, [type, setForm]);
    return (
        <FormBox ref={shippingAddressForm}>
            {(type === 'returned' && form.shippingAddress.address && form.shippingAddress.city && form.shippingAddress.state && form.shippingAddress.zipCode) &&
                <UseSameAddress onClick={switchUseSameAddress} extraMargin={!form.returnedAddress.useSameAddress ? true : false}>
                    <UseSameAddressCheckBox>
                        <UseSameAddressCheck viewBox="0 0 24 24" show={form.returnedAddress.useSameAddress}>
                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                        </UseSameAddressCheck>
                    </UseSameAddressCheckBox>
                    <span>Use shipping address as return address</span>
                </UseSameAddress>
            }
            {(type === 'shipping' || !form.returnedAddress.useSameAddress) &&
                <>
                    <PlacesInputRow>
                        <PlacesInputRowSingle>
                            <LabelBox>
                                <Label>{type === 'shipping' ? "What address are you shipping your product from?" : "What address do we return your product to?"}</Label>
                            </LabelBox>
                            <PlacesBox ref={boxRef}>
                                <PlacesInput name="address" ref={inputRef} onChange={onchangeInput} value={form[`${type}Address`].address ? form[`${type}Address`].address : ''} noMargin={true} placeholder="Street Address" />
                            </PlacesBox>
                            {form[`${type}AddressError`].address && <FormError>Please enter an address.</FormError>}
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                    <PlacesInputRow>
                        <PlacesInputRowSingle>
                            <PlacesInput name="apt" onChange={onchangeInput} value={form[`${type}Address`].apt ? form[`${type}Address`].apt : ''} noMargin={true} placeholder="Apt, suite, etc (optional)" />
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                    <PlacesInputRow>
                        <PlacesInputRowSingle>
                            <PlacesInputRowSingle>
                                <PlacesInput name="companyName" type="text" onChange={onchangeInput} value={form[`${type}Address`].companyName ? form[`${type}Address`].companyName : ''} placeholder="Company name (optional)" noMargin={true} />
                            </PlacesInputRowSingle>
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                    <PlacesInputRow>
                        <PlacesInputRowSingle>
                            <LabelBox>
                                <Label>City</Label>
                            </LabelBox>
                            <PlacesInput name="city" onChange={onchangeInput} value={form[`${type}Address`].city ? form[`${type}Address`].city : ''} placeholder="City" />
                            {form[`${type}AddressError`].city && <FormError>Please enter a city name.</FormError>}
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                    <PlacesInputRow>
                        <PlacesInputRowSingle className="half">
                            <LabelBox>
                                <Label>State</Label>
                            </LabelBox>
                            <SelectBox name="state" onChange={stateSelect} value={form[`${type}Address`].state ? form[`${type}Address`].state : 'default'}>
                                <option value="default" disabled>State</option>
                                {stateValues.map((x, i) => <option key={i} value={x.label}>{x.label}</option>)}
                            </SelectBox>
                            {form[`${type}AddressError`].state && <FormError>Please select a state.</FormError>}
                        </PlacesInputRowSingle>
                        <PlacesInputRowSingle className="half">
                            <LabelBox>
                                <Label>ZIP code (5 digits)</Label>
                            </LabelBox>
                            <PlacesInput type="tel" name="zipCode" onChange={onchangeInput} value={form[`${type}Address`].zipCode ? form[`${type}Address`].zipCode : ''} placeholder="ZIP code" maxLength={5} />
                            {form[`${type}AddressError`].zipCode && <FormError>{form[`${type}AddressError`].zipCode}</FormError>}
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                </>
            }
        </FormBox>
    );
})

export default StepShipping;