import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import mainReducer from './reducers/mainReducer';
import AppRouter from './routers/AppRouter';

const GlobalStyle = createGlobalStyle`
	body {
		padding: 0;
		margin: 0;
		background: #f3f5f9;
		font-size: 15px;
		outline-style:none;
	}
	body, input, select, textarea, button {
		font-family: 'Roboto', arial, sans-serif;
		font-weight: 400;
	}
	body, a {
		color: #202124;
	}
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}
	a {
		text-decoration: none;
	}
	ul {
		list-style-type: none;
	}
	ul,
	li {
		padding: 0;
		margin: 0;
	}
	h1,
	h2,
	h3,
	h4 {
		padding: 0;
		margin: 0;
	}
	#root {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	.hide {
		display: none !important;
	}
	.memberBlockBorderColor {
		border-color: #eeeeee !important;
	}
	body.overflow {
		overflow: hidden;
	}
	.bt-overlay {
		z-index: 99999999 !important;
	}
`;

const theme = {
	green: '#1dd28d',
	blue: '#214ea3',
	brightBlue: '#386ed6',
	brightRed: '#ffd0d1',
	lightFontOrFill: '#ffffff',
	alertMenu: '#e43545',
	heartRed: '#960a20;',
	chatBlue: '#1e448c',
};

const fbConfig = {
	apiKey: "AIzaSyA9q0YH_0V62lNU5v6yHNb-rKX2pTNCx1Y",
	authDomain: "product-pictures-28127.firebaseapp.com",
	databaseURL: "https://product-pictures-28127.firebaseio.com",
	projectId: "product-pictures-28127",
	storageBucket: "product-pictures-28127.appspot.com",
	messagingSenderId: "60303929868",
	appId: "1:60303929868:web:be90996ee5daf2257eb429",
	measurementId: "G-6VKMZLSSS1"
};

const rrfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true
}

firebase.initializeApp(fbConfig)
firebase.firestore();
firebase.functions();

// eslint-disable-next-line no-restricted-globals
// if (location.hostname === "localhost") {
// 	console.log('local host emulator')
// 	firebase.firestore().settings({
// 		host: "localhost:8080",
// 		ssl: false
// 	});
// 	firebase.functions().useFunctionsEmulator('http://localhost:5001')
// }

export const analytics = firebase.analytics();


const rootReducer = combineReducers({
	mainReducer
})

const store = createStore(rootReducer)

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance
}

const Main = () => {
	return (
		<>
			<Provider store={store}>
				<GlobalStyle />
				<ReactReduxFirebaseProvider {...rrfProps}>
					<ThemeProvider theme={theme}>
						<AppRouter />
					</ThemeProvider>
				</ReactReduxFirebaseProvider>
			</Provider>
		</>
	);
}

ReactDOM.render(<Main />, document.getElementById('root'));

serviceWorker.unregister();
