import React, { useRef, useEffect, useState, useCallback } from 'react';
import { ImgPopBox, ImgPopBg, ImgPop, ImgPopX, ImgPopImgBox, ImgPopImgBoxBlurb, ImgPopXBox, ImgPopXCircle, ImgPopClose, ImgPopControls, ImgPopControl, ImgPopControlIcon } from '../style/main';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
gsap.registerPlugin(Draggable, InertiaPlugin);

const imgScales = [1, 1.5, 2, 2.8]

const ImgPopAll = ({ largeImg, closeImgPopBox }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [zoom, setZoom] = useState(0);
    const popBoxRef = useRef(null);
    const imgRef = useRef(null);
    const loadedImage = () => {
        setImageLoaded(true)
    }
    useEffect(() => {
        Draggable.create(imgRef.current, {
            type: "x, y",
            bounds: popBoxRef.current,
            edgeResistance: 0.65,
            inertia: true
        });
    }, []);
    const zoomIn = useCallback(() => {
        let newZoom = zoom
        if (zoom !== imgScales.length - 1) {
            newZoom = newZoom + 1;
        }
        const zoomTimline = gsap.timeline()
        zoomTimline.to(imgRef.current, 0.3, { scale: imgScales[newZoom], x: 0, y: 0 })
        setZoom(newZoom)
    }, [zoom, setZoom]);
    const zoomOut = useCallback(() => {
        let newZoom = zoom
        if (zoom !== 0) {
            newZoom = newZoom - 1;
        }
        const zoomTimline = gsap.timeline()
        zoomTimline.to(imgRef.current, 0.3, { scale: imgScales[newZoom], x: 0, y: 0 })
        setZoom(newZoom)
    }, [zoom, setZoom]);
    return (
        <>
            <ImgPopBox>
                <ImgPop>
                    <ImgPopImgBox ref={popBoxRef}>
                        <img src={`/img/${largeImg}`} alt="test" ref={imgRef} onLoad={loadedImage} />
                        {!imageLoaded && <ImgPopImgBoxBlurb>Loading preview...</ImgPopImgBoxBlurb>}
                        <ImgPopControls>
                            <ImgPopControl onClick={zoomIn} hide={zoom === imgScales.length - 1 ? true : false}>
                                <ImgPopControlIcon viewBox="0 0 24 24">
                                    <path d="M13 10h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
                                </ImgPopControlIcon>
                            </ImgPopControl>
                            <ImgPopControl onClick={zoomOut} hide={zoom === 0 ? true : false}>
                                <ImgPopControlIcon viewBox="0 0 24 24">
                                    <path d="M13 10h-8v-2h8v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
                                </ImgPopControlIcon>
                            </ImgPopControl>
                        </ImgPopControls>
                    </ImgPopImgBox>
                </ImgPop>
                <ImgPopClose onClick={closeImgPopBox} />
                <ImgPopXBox onClick={closeImgPopBox}>
                    <ImgPopX viewBox="0 0 24 24">
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
                    </ImgPopX>
                    <ImgPopXCircle />
                </ImgPopXBox>
            </ImgPopBox>
            {largeImg && <ImgPopBg onClick={closeImgPopBox} />}
        </>
    );
}

export default ImgPopAll;