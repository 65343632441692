import React, { useState, useCallback } from 'react';
import { SubmitReviewBox, SubmitReviewTitle, SubmitReviewX, SubmitReviewContent, SubmitReviewRow, SubmitReviewLabel, SubmitReviewInput, SubmitReviewInputRow, SubmitReviewTextArea, SubmitReviewBottom, SubmitReviewBottomButton, SubmitReviewStars, SubmitReviewError, SubmitReviewErrorBottom, CancelReviewButton, WriteAReviewBox, SubmitReview } from '../style/main';
import ReviewRatingStar from './ReviewRatingStar';
const reviewStarCount = [1, 2, 3, 4, 5]

const ReviewForm = React.memo(() => {
    const initialState = {
        show: false,
        rating: 0,
        firstName: '',
        lastName: '',
        headline: '',
        orderNumber: '',
        review: '',
        errorRating: false,
        errorFirstName: false,
        errorHeadline: false,
        errorOrderNumber: false,
        errorReview: false
    }
    const [submitReviewState, setSubmitReviewState] = useState(initialState);
    const showReviews = () => {
        setSubmitReviewState(x => ({
            ...x,
            show: !x.show
        }))
    }
    const closeShowReview = () => {
        setSubmitReviewState(initialState)
    }
    const onChangeInput = (e) => {
        e.persist();
        setSubmitReviewState(x => ({
            ...x,
            [e.target.name]: e.target.value
        }))
    }
    const submitReviewForm = (e) => {
        e.preventDefault()
        submitReview()
    }
    const submitReview = useCallback(() => {
        let errorRating = false;
        let errorFirstName = false;
        let errorHeadline = false;
        let errorOrderNumber = true;
        let errorReview = false;
        if (!submitReviewState.rating) {
            errorRating = true
        }
        if (submitReviewState.firstName.length === 0) {
            errorFirstName = true
        }
        if (submitReviewState.headline.length === 0) {
            errorHeadline = true
        }
        // if (submitReviewState.orderNumber.length === 0) {
        //     errorOrderNumber = true
        // }
        if (submitReviewState.review.length === 0) {
            errorReview = true
        }
        setSubmitReviewState(x => ({
            ...x,
            errorRating,
            errorFirstName,
            errorHeadline,
            errorOrderNumber,
            errorReview
        }))
    }, [submitReviewState, setSubmitReviewState]);
    const setReviewRating = useCallback(({ rating }) => {
        setSubmitReviewState(x => ({
            ...x,
            rating,
            errorRating: false
        }))
    }, [setSubmitReviewState]);
    return (
        <>
            <WriteAReviewBox>
                <SubmitReview onClick={showReviews}>Write a customer review</SubmitReview>
            </WriteAReviewBox>
            <SubmitReviewBox show={submitReviewState.show} onSubmit={submitReviewForm}>
                <SubmitReviewTitle>
                    <span>Create your customer review</span>
                    <SubmitReviewX viewBox="0 0 24 24" onClick={closeShowReview}>
                        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                    </SubmitReviewX>
                </SubmitReviewTitle>
                <SubmitReviewContent>
                    <SubmitReviewRow>
                        <SubmitReviewLabel>Overall Rating</SubmitReviewLabel>
                        <SubmitReviewStars>
                            {reviewStarCount.map(x => <ReviewRatingStar key={x} rating={x} setReviewRating={setReviewRating} currentRating={submitReviewState.rating} />)}
                        </SubmitReviewStars>
                        {submitReviewState.errorRating && <SubmitReviewError className="ratingError">Please select a rating.</SubmitReviewError>}
                    </SubmitReviewRow>
                    <SubmitReviewRow>
                        <SubmitReviewLabel>Your name</SubmitReviewLabel>
                        <SubmitReviewInputRow>
                            <SubmitReviewInput name="firstName" className="firstName" placeholder="First name" value={submitReviewState.firstName} onChange={onChangeInput} />
                            <SubmitReviewInput name="lastName" className="lastName" placeholder="Last name initial" value={submitReviewState.lastName} onChange={onChangeInput} />
                        </SubmitReviewInputRow>
                        {submitReviewState.errorFirstName && <SubmitReviewError>Please enter your first name.</SubmitReviewError>}
                    </SubmitReviewRow>
                    <SubmitReviewRow>
                        <SubmitReviewLabel>Review headline</SubmitReviewLabel>
                        <SubmitReviewInputRow>
                            <SubmitReviewInput name="headline" placeholder="Write a review headline" value={submitReviewState.headline} onChange={onChangeInput} />
                        </SubmitReviewInputRow>

                        {submitReviewState.errorHeadline && <SubmitReviewError>Please enter a review headline.</SubmitReviewError>}
                    </SubmitReviewRow>
                    <SubmitReviewRow>
                        <SubmitReviewLabel>Your order number</SubmitReviewLabel>
                        <SubmitReviewInputRow>
                            <SubmitReviewInput name="orderNumber" placeholder="Order number found on your receipt" value={submitReviewState.orderNumber} onChange={onChangeInput} />
                        </SubmitReviewInputRow>
                        {submitReviewState.errorOrderNumber && <SubmitReviewError>Please enter a valid order number.</SubmitReviewError>}
                    </SubmitReviewRow>
                    <SubmitReviewRow>
                        <SubmitReviewLabel>Write your review</SubmitReviewLabel>
                        <SubmitReviewInputRow>
                            <SubmitReviewTextArea name="review" placeholder="Tell us about your experience" value={submitReviewState.review} onChange={onChangeInput} />
                        </SubmitReviewInputRow>
                        {submitReviewState.errorReview && <SubmitReviewError>Please write about your experience.</SubmitReviewError>}
                    </SubmitReviewRow>
                </SubmitReviewContent>
                {(submitReviewState.errorRating || submitReviewState.errorFirstName || submitReviewState.errorHeadline || submitReviewState.errorOrderNumber || submitReviewState.errorReview) && <SubmitReviewErrorBottom>Please check your information above.</SubmitReviewErrorBottom>}
                <SubmitReviewBottom>
                    <SubmitReviewBottomButton>Submit Review</SubmitReviewBottomButton>
                    <CancelReviewButton onClick={closeShowReview}>Cancel</CancelReviewButton>
                </SubmitReviewBottom>
            </SubmitReviewBox>
        </>
    );
})

export default ReviewForm;