import React from 'react';
import { LargeProductBoxStepsCircle } from '../style/main';

const LargeSliderCircle = React.memo(({ largeSlideCount, count, sliderDotClick }) => {
    const onClick = () => {
        sliderDotClick({ count })
    }
    return (
        <LargeProductBoxStepsCircle selected={largeSlideCount === count ? true : false} onClick={onClick} />
    );
})

export default LargeSliderCircle;