import React from 'react';
import { PrivacyBox } from '../../style/main';

const PrivacyPolicy = () => {
    return (
        <PrivacyBox>
            <h1>Privacy Policy</h1>
            <div className="termsText">
                <h2>Policy Version Date: June 27, 2020</h2>
                <p>
                    This privacy policy explains how SB DEVELOPMENT LLC, doing business as Product Pictures ("Product Pictures", "we", "us", or "our") collects, uses or shares information about you when you use our website. We're fully committed to protecting your personal information and your right to privacy.
            </p>
                <p>
                    Please read this privacy policy carefully. By using this service you acknowledge that you have read and understand the privacy policy, how we collect, use and share information set forth below. If there is any terms in this privacy policy that you do not agree with, please discontinue the use of our sites and services.
            </p>
                <p>
                    This privacy notice will apply to all information collected through our website (such as https://product.pictures/), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services").
            </p>

                <h2>1. WHAT INFORMATION WE COLLECT</h2>
                <p>
                    We automatically collect certain information when you use, visit or navigate the Services. This information doesn't reveal your specific identity (such as your name or contact information) but it may include device or usage information, such as your IP address, device characteristics, browser, operating system, device name, language preferences, referring URLs, location, other technical information and information about how and when you use our Services. This information is primarily used to maintain the security and operation of our Services, and also for our internal data/analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies.
            </p>

                <h2>2. HOW WE USE YOUR INFORMATION</h2>
                <p>
                    We use your personal information collected via our Services for a variety of business purposes. We process your personal information for these purposes for legitimate business interests. To note we may use any or all of this information in accordance to comply with law, detect and/or prevent fraud and to defend our legal rights.
            </p>
                <p>
                    Details about how we collect and use your personal information are as follows: Account creation and login process, sending you marketing and promotional communications, fulfill and manage your orders or bookings, posting of testimonials, deliver targeted advertising to you, requesting feedback, protecting our Services, to enforce our terms, conditions and policies for business purposes, legal and contractual reasons, to respond to legal requests and prevent harm, manage user accounts, to deliver services to the user, to respond to user inquiries and offer support to users, to use in data analysis or marketing.
            </p>
                <p>We may periodically send you marketing emails. You can unsubscribe from our email marketing list at any time by clicking on the unsubscribe link located in the emails that we send or by contacting us: support@product.pictures. However, we will still need to send you service-related emails that are necessary to administer your account.</p>

                <h2>3. WHEN WE SHARE YOUR INFORMATION</h2>
                <p>
                    We do not sell your information to third party services for their promotional purposes without your consent.
            </p>
                <p>
                    We may disclose your information in certain scenarios. Including when we believe it is necassary to investigate, prevent, or take action to prevent fraud or prevent potential violations of our policies. Situations involving potental threats or safety of any person and illegal activities. Any legal proceedings where we are legally required to do so by applicable law, government requests, judicial proceedings, court orders, in response to a subpoena, public authorities to meet law enforcement or national security requirements.
            </p>
                <p>
                    We may share your data with third party vendors or service providers who perform services on our behalf. Examples include: email delivery, payment processing, data analysis, hosting services, customer service, cookie tracking to analyze data and better understand your online activity, connect you with Services that may interest you or determine popularity of content.
            </p>
                <p>
                    We may share or transfer your information in connection with any business transfers, negotiations of any merger, financing, sale of company assets or aquisition of all or a portion of our company.
            </p>

                <h2>4. COOKIE AND OTHER TRACKING TECHNOLOGIES</h2>
                <p>When you visit our website we automatically use tracking technology such as cookies to access and store your information. We collect this information to improve the navigation experience of our pages, determine popularity of content, determine your location, determine browser language and offer targetted results and/or targetted advertising.</p>

                <p>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies or to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features our Services.</p>

                <h2>5. RETENTION OF DATA</h2>
                <p>
                    Your personal information is stored when we have ongoing business or legal needs to do so.  When there is no legitimate ongoing business needs to store your personal information. We will either anonymize or delete it, if this is not possible (for example because your personal information has been stored in backup archives), then we will securely store and isolate it from further processing until full deletion is possible.
            </p>

                <h2>6. SECURING YOUR INFORMATION</h2>
                <p>We have implemented appropriate technical and security measures designed to protect the security of your personal information from misuse, unauthorized access, disclosure and alteration both online and offline. You should only access the Services within a secure environment. Transmission of personal information to and from our Services is at your own risk. </p>

                <h2>7. MINORS</h2>
                <p>Services are not directed to children under 18 years of age. We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 years of age.</p>

                <h2>8. ACCOUNT TERMINATION</h2>
                <p>If you would at any time like to change the information in your account or terminate your account, you can contact us: support@product.pictures. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained to troubleshoot problems, prevent fraud, assist with any investigations, enforce our Terms of Service and/or comply with any legal requirements.
            </p>

                <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>

                <h2>10. UPDATES TO THIS PRIVACY POLICY</h2>
                <p>We may update this privacy policy periodically. The updated version will be indicated by a "Policy Version" date and the updated version will be effective as soon as it is accessible. If we make changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by sending you a direct notification. We encourage you to review this privacy policy frequently in order to be informed of how we're protecting your information.</p>

                <h2>11. CALIFORNIA PRIVACY RIGHTS</h2>
                <p>California residents may request and obtain from us information about categories of personal information (if any) that we disclosed to third parties for direct marketing purposes in the immediately preceding calendar year. In addition, if you would prefer that we do not share your personal information with third party services for direct marketing purposes you may choose to opt-out. If you're a California resident and would like to make such requests, you can contact us: support@product.pictures.</p>

                <h2>12. CONTACTING US ABOUT THIS PRIVACY POLICY</h2>
                <p>If you have questions or comments about this privacy policy, you may contact us at: support@product.pictures.</p>

            </div>
        </PrivacyBox>
    );
}

export default PrivacyPolicy;