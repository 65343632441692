import React from 'react';

const Dashboard = () => {
    return (
        <>
            Dashboard
        </>
    );
}

export default Dashboard;