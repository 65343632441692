import React, { useState, useEffect, useCallback } from "react";
import firebase from 'firebase/app'
import { analytics } from '../../index'
import { useFirestore } from 'react-redux-firebase'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { Form, Button, PlacesInputRow, PlacesInputRowSingle, LabelBox, Label, FormError, ButtonLoader, CCRow, CCLogos, CCImg, FormCover, PPButton, PPButtonBox, CheckoutOrBox, CheckoutOr, CheckoutOrLine, CheckoutInputBox, CheckoutLoaderBox, CheckoutLoaderBoxText, ButtonLoaderPrepping, PreppingLock } from "../../style/main";
import OrderTotal from "../OrderTotal";
import { stateValues } from '../states';
import { businessPhoneNumber, businessEmail, ourAddress, brainTreeKey, billingOn } from '../info/businessInfo'
const client = require('braintree-web/client');
var paypalCheckout = require('braintree-web/paypal-checkout');

const cardStyle = {
    style: {
        base: {
            color: "#202124",
            fontFamily: 'Roboto, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "13px",
            "::placeholder": {
                color: "#777777"
            }
        },
        invalid: {
            color: "#af0e33",
            iconColor: "#fa755a"
        }
    }
};


const CheckoutForm = ({ form, setForm, photoAmount, steps, stripeIntent, setStripeIntent, setPaypalTeardown, ppCheckingOut, setPPCheckingOut, setStripeCheckingOut, setCheckoutPrepping }) => {
    const firestore = useFirestore()
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const [ppFailedCharged, setPpFailedCharged] = useState(false)
    const [checkoutsLoaded, setCheckoutsLoaded] = useState({
        stripe: false,
        braintree: false
    })
    const [info, setInfo] = useState({
        cardNumberError: false,
        expiryError: false,
        cvcError: false,
        brand: 'unknown'
    });
    const [disabled, setDisabled] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const createOrder = useCallback(({ orderId, paypal }) => {
        analytics.logEvent('purchase', {
            userId: form.account.userId,
            price: (photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost),
            value: photoAmount[form.photoAmount].price,
            currency: 'USD',
            shipping: form.chosenShippingRate.cost ? true : false,
            returned: form.chosenReturnedRate.cost ? true : false,
            quantity: photoAmount[form.photoAmount].amount,
            paypal: paypal ? true : false
        });
        const receiptId = uuidv4();
        // SAVE ORDER
        let labelAddress = false;
        // IF SHIPPING IS NEEDED
        if (form.chosenShippingRate.cost) {
            const chosenState = stateValues.find(x => x.label === form.shippingAddress.state);
            const addressUser = {
                name: `${form.account.firstName} ${form.account.lastName}`,
                company_name: form.shippingAddress.companyName ? form.shippingAddress.companyName : null,
                phone: form.account.phone,
                address_line1: form.shippingAddress.address,
                address_line2: form.shippingAddress.apt ? form.shippingAddress.apt : null,
                city_locality: form.shippingAddress.city,
                state_province: chosenState.value,
                postal_code: form.shippingAddress.zipCode,
                country_code: "US",
                address_residential_indicator: form.shippingAddress.companyName ? 'yes' : 'no'
            }
            labelAddress = {
                shipment: {
                    service_code: form.chosenShippingRate.serviceCode,
                    ship_to: {
                        ...ourAddress
                    },
                    ship_from: {
                        ...addressUser
                    },
                    packages: [
                        {
                            weight: {
                                value: parseInt(form.weight) === 0 ? 1 : form.weight,
                                "unit": "pound"
                            },
                            "label_messages": {
                                "reference1": orderId
                            }
                        }
                    ]
                }
            }
        }
        let receipt = [{
            name: `${photoAmount[form.photoAmount].amount} ${photoAmount[form.photoAmount].amount === 1 ? 'Photo' : 'Photos'}:`,
            price: `$${photoAmount[form.photoAmount].price.toFixed(2)}`
        }];
        // PUSH SHIPPING COST IF NEEDED
        if (form.chosenShippingRate.cost !== 0) {
            receipt.push({
                name: 'Shipping:',
                price: `$${form.chosenShippingRate.cost.toFixed(2)}`
            })
        }
        // PUSH RETURN COST IF NEEDED
        if (form.chosenReturnedRate.cost !== 0) {
            receipt.push({
                name: 'Return Shipping:',
                price: `$${form.chosenReturnedRate.cost.toFixed(2)}`
            })
        }
        // PUSH ORDER TOTAL TO RECEIPT
        receipt.push({
            name: 'Order Total:',
            price: `$${(photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2)}`
        })
        const saveOrder = firebase.functions().httpsCallable('saveOrder');
        saveOrder({
            account: {
                email: form.account.email,
                firstName: form.account.firstName,
                lastName: form.account.lastName,
                userId: form.account.userId,
                phone: form.account.phone
            },
            photoAmount: photoAmount[form.photoAmount],
            subTotal: (photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2),
            shippingInfo: {
                returned: form.returned,
                shipping: form.shipping,
                returnedAddress: form.returnedAddress,
                shippingAddress: form.shippingAddress,
                chosenReturnedRate: form.chosenReturnedRate,
                chosenShippingRate: form.chosenShippingRate,
            },
            receipt,
            orderId: orderId,
            labelAddress,
            labelPdf: false,
            receiptId,
            timeStamp: firestore.Timestamp.fromDate(new Date()),
            dateOrdered: moment().format('LL'),
            businessPhoneNumber,
            businessEmail,
            paypal: paypal ? true : false
        }).then((resp) => {
            // GET RESPONSE FROM THE ORDER AND/OR LABEL CREATION IF NEEDED
            if (resp.data.label || resp.data.labelError) {
                // ADD LABEL TO THE RECREIPT OR ADD LABEL ERROR RESPONSE
                setForm(x => ({
                    ...x,
                    step: steps.findIndex(x => x.receipt),
                    labelPdf: resp.data.label ? resp.data.label.label_download.pdf : false,
                    orderId,
                    receiptId,
                    labelError: resp.data.labelError ? true : false
                }))
                document.querySelector('body').classList.remove('overflow');
                if (!resp.data.labelError) {
                    analytics.logEvent('labelCreated')
                } else {
                    analytics.logEvent('errorLabelCreation')
                }
            }
        }).catch((e) => {
            console.log(e)
        });
        if (!form.shipping) {
            // GO STRAIGHT TO RECEIPT 1 SECONDS AFTER
            setTimeout(() => {
                setForm(x => ({
                    ...x,
                    step: steps.findIndex(x => x.receipt),
                    orderId,
                    receiptId
                }))
                document.querySelector('body').classList.remove('overflow');
            }, 1500);

        }
    }, [firestore, form.account.email, form.account.firstName, form.account.lastName, form.chosenReturnedRate, form.chosenShippingRate, form.photoAmount, form.returned, form.returnedAddress, form.shipping, form.shippingAddress, form.account.userId, photoAmount, setForm, steps, form.account.phone, form.weight]);
    // PAYPPAL
    useEffect(() => {
        // LOAD IN CHECKOUT FOR BUTTONS
        const script = document.createElement('script');
        script.src = "https://www.paypalobjects.com/api/checkout.js";
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [setScriptLoaded]);
    useEffect(() => {
        if (scriptLoaded && steps[form.step].stepCheckoutLoading) {
            client.create({
                authorization: brainTreeKey
            }).then(function (clientInstance) {
                return paypalCheckout.create({
                    client: clientInstance
                });
            }).then((paypalCheckoutInstance) => {
                setPaypalTeardown(paypalCheckoutInstance)
                return window.paypal.Button.render({
                    style: {
                        color: 'gold',
                        size: 'responsive',
                        shape: 'rect',
                        label: 'checkout',
                        tagline: 'false'
                    },
                    env: billingOn ? 'production' : 'sandbox',
                    payment: function () {
                        return paypalCheckoutInstance.createPayment({
                            flow: 'vault',
                            displayName: 'www.product.pictures',
                            amount: (photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2),
                            currency: 'USD'
                        });
                    },

                    onAuthorize: (data, actions) => {
                        return paypalCheckoutInstance.tokenizePayment(data)
                            .then((payload) => {
                                setPPCheckingOut(true)
                                setPpFailedCharged(false)
                                setInfo(x => ({
                                    ...x,
                                    cardNumberError: false,
                                    expiryError: false,
                                    cvcError: false
                                }))
                                const braintreeCheckout = firebase.functions().httpsCallable('braintreeCheckout');
                                braintreeCheckout({
                                    amount: (photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2),
                                    id: form.account.userId,
                                    nonce: payload.nonce
                                }).then((resp) => {
                                    if (resp.data) {
                                        const orderId = resp.data;
                                        createOrder({ orderId, paypal: true })
                                    } else {
                                        setPpFailedCharged(`<div class="html">Whoops! There was an issue checking out with PayPal. Please try again or choose a different payment option. If you need help please call customer service: <a href='tel:${businessPhoneNumber}' class="htmlA"><strong>${businessPhoneNumber}</strong></a></div>`)
                                        analytics.logEvent('errorPaypalChargeFailed')
                                        setPPCheckingOut(false)
                                    }
                                }).catch(e => {
                                    analytics.logEvent('errorPaypal')
                                    console.log(e)
                                })
                            });
                    },
                    onCancel: (data) => {
                    },
                    onError: (err) => {
                        console.error('checkout.js error', err);
                    }
                }, '#paypalContainer');
            }).then(() => {
                setCheckoutsLoaded(x => ({
                    ...x,
                    paypal: true
                }))
                return
            }).catch((e) => {
                console.log(e)
            });
        }
    }, [scriptLoaded, setPPCheckingOut, form.chosenReturnedRate.cost, form.chosenShippingRate.cost, form.photoAmount, photoAmount, form.step, steps, form.account.userId, createOrder, setInfo, setPaypalTeardown]);
    const submitForm = (e) => {
        e.preventDefault()
        setPpFailedCharged(false)
        // STRIPE OR ELEMENTS NOT READY RETURN
        if (!stripe || !elements || !stripeIntent) {
            return;
        }
        if (info.cardNumberError || info.expiryError || info.cvcError) {
            return
        }
        setDisabled(true)
        setStripeCheckingOut(true)
        stripe.confirmCardPayment(stripeIntent, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: `${form.account.firstName} ${form.account.lastName}`,
                    email: form.account.email
                }
            }
        }).then((resp) => {
            if (resp.error) {
                setInfo(x => ({
                    ...x,
                    cardNumberError: resp.error.code.includes('expiry') || resp.error.code.includes('cvc') ? x.cardNumberError : resp.error.type === 'validation_error' ? resp.error.message : resp.error.message ? `<div class="html">${resp.error.message} If you need help please call customer service: <a href='tel:${businessPhoneNumber}' class="htmlA"><strong>${businessPhoneNumber}</strong></a></div>` : `<div class="html">Whoops! There was an issue with your order. If you need help please call customer service: <a href='tel:${businessPhoneNumber}' class="htmlA"><strong>${businessPhoneNumber}</strong></a></div>`
                }))
                setStripeCheckingOut(false)
                setDisabled(false)
                analytics.logEvent('errorCC', {
                    code: resp.error.code,
                    type: resp.error.type
                });
                return
            } else if (resp.paymentIntent.status === 'succeeded') {
                const orderId = resp.paymentIntent.id;
                createOrder({ orderId })
                return
            }
        }).catch((e) => {
            analytics.logEvent('errorCC')
            setDisabled(false)
            console.log(e)
        })
    }
    const changeCardNumber = (e) => {
        setPpFailedCharged(false)
        let brand = e.brand;
        if (brand !== 'visa' && brand !== 'mastercard' && brand !== 'amex' && brand !== 'discover') {
            brand = 'unknown';
        }
        if (e.error) {
            setInfo(x => ({
                ...x,
                cardNumberError: e.error.message,
                brand
            }))
        } else {
            setInfo(x => ({
                ...x,
                cardNumberError: false,
                brand
            }))
        }
    }
    const changeExpiry = (e) => {
        setPpFailedCharged(false)
        if (e.error) {
            setInfo(x => ({
                ...x,
                expiryError: e.error.message
            }))
        } else {
            setInfo(x => ({
                ...x,
                expiryError: false
            }))
        }
    }
    const changeCvc = (e) => {
        setPpFailedCharged(false)
        if (e.error) {
            setInfo(x => ({
                ...x,
                cvcError: e.error.message
            }))
        } else {
            setInfo(x => ({
                ...x,
                cvcError: false
            }))
        }
    }
    useEffect(() => {
        if (steps[form.step].stepCheckoutLoading) {
            setCheckoutPrepping(true)
            const stripePaymentIntent = firebase.functions().httpsCallable('stripePaymentIntent');
            stripePaymentIntent({
                amount: Math.round(parseFloat((photoAmount[form.photoAmount].price + form.chosenShippingRate.cost + form.chosenReturnedRate.cost).toFixed(2)) * 100),
                id: form.account.userId
            }).then((res) => {
                if (!res) {
                    analytics.logEvent('errorStripeIntentFalse')
                }
                setStripeIntent(res.data)
                setCheckoutsLoaded(x => ({
                    ...x,
                    stripe: true
                }))
                return
            }).catch((e) => {
                analytics.logEvent('errorStripeIntent')
                console.log(e)
            });
        } else {
            setCheckoutPrepping(false)
        }
    }, [form.step, steps, form.account.userId, form.chosenReturnedRate.cost, form.chosenShippingRate.cost, form.photoAmount, photoAmount, setCheckoutsLoaded, setStripeIntent, setCheckoutPrepping]);
    useEffect(() => {
        if (checkoutsLoaded.stripe && checkoutsLoaded.paypal) {
            setForm(x => ({
                ...x,
                step: steps.findIndex(x => x.stepBilling),
            }))
        }
    }, [checkoutsLoaded, setForm, steps]);
    return (
        <>
            {steps[form.step].stepCheckoutLoading &&
                <CheckoutLoaderBox>
                    <ButtonLoaderPrepping>
                        <ButtonLoader dark={true} noMargin={true} receiptUrlPage={true} />
                        <PreppingLock viewBox="0 0 24 24">
                            <path d="M18,10V6c0-3.313-2.686-6-6-6S6,2.687,6,6v4H3v14h18V10H18z M8,6c0-2.206,1.794-4,4-4c2.206,0,4,1.794,4,4v4H8V6z M19,22H5V12h14V22z" />
                            <rect x="10.877" y="14.886" width="2.246" height="5.526" />
                            <circle cx="12" cy="15.382" r="1.993" />
                        </PreppingLock>
                    </ButtonLoaderPrepping>
                    <CheckoutLoaderBoxText>Preparing Checkout...</CheckoutLoaderBoxText>
                </CheckoutLoaderBox>
            }
            <Form onSubmit={submitForm} show={steps[form.step].stepBilling ? true : false}>
                {disabled || ppCheckingOut ? <FormCover /> : ''}
                {ppCheckingOut &&
                    <Button disabled={true} defaultPointer={true} gold={true}>
                        <span>Completing order...</span>
                        <ButtonLoader />
                    </Button>
                }
                {form.showPaypal &&
                    <PPButtonBox show={ppCheckingOut ? false : true} fade={disabled ? true : false}>
                        <PPButton id="paypalContainer"></PPButton>
                    </PPButtonBox>
                }
                {ppFailedCharged && <FormError dangerouslySetInnerHTML={{ __html: ppFailedCharged }} />}
                <CheckoutOrBox fade={ppCheckingOut ? true : false}>
                    <CheckoutOr>Or pay with credit card</CheckoutOr>
                    <CheckoutOrLine />
                </CheckoutOrBox>
                <CheckoutInputBox fade={ppCheckingOut ? true : false}>
                    <PlacesInputRow>
                        <PlacesInputRowSingle>
                            <LabelBox>
                                <Label>Card number</Label>
                            </LabelBox>
                            <CCRow>
                                <CardNumberElement
                                    options={cardStyle}
                                    onChange={changeCardNumber}
                                    className="cardNumber"
                                />
                                <CCLogos>
                                    <CCImg src={'/img/cc/vs.png'} className="vs" show={info.brand === 'unknown' ? true : info.brand === 'visa' ? true : false} alt="We accept Visa" />
                                    <CCImg src={'/img/cc/mc.png'} className="mc" show={info.brand === 'unknown' ? true : info.brand === 'mastercard' ? true : false} alt="We accept Mastercard" />
                                    <CCImg src={'/img/cc/amx.png'} className="amx" show={info.brand === 'unknown' ? true : info.brand === 'amex' ? true : false} alt="We accept American Express" />
                                    <CCImg src={'/img/cc/disc.png'} className="disc" show={info.brand === 'unknown' ? true : info.brand === 'discover' ? true : false} alt="We accept Discover" />
                                </CCLogos>
                            </CCRow>
                            {info.cardNumberError && <FormError dangerouslySetInnerHTML={{ __html: info.cardNumberError }} />}
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                    <PlacesInputRow>
                        <PlacesInputRowSingle className="half">
                            <LabelBox>
                                <Label>Expiration date</Label>
                            </LabelBox>
                            <CardExpiryElement
                                options={cardStyle}
                                onChange={changeExpiry}
                            />
                            {info.expiryError && <FormError>{info.expiryError}</FormError>}
                        </PlacesInputRowSingle>
                        <PlacesInputRowSingle className="half">
                            <LabelBox>
                                <Label>CVC</Label>
                            </LabelBox>
                            <CardCvcElement
                                options={cardStyle}
                                onChange={changeCvc}
                            />
                            {info.cvcError && <FormError>{info.cvcError}</FormError>}
                        </PlacesInputRowSingle>
                    </PlacesInputRow>
                </CheckoutInputBox>
                <OrderTotal photoAmount={photoAmount} form={form} steps={steps} setForm={setForm} fade={ppCheckingOut ? true : false} />
                {disabled ?
                    <Button disabled={true} defaultPointer={true} disabledButton={true}>
                        <span>Completing order...</span>
                        <ButtonLoader />
                    </Button>
                    :
                    <Button fade={ppCheckingOut ? true : false}>
                        <span>{steps[form.step].buttonText}</span>
                    </Button>
                }
            </Form>
        </>
    );
}

export default CheckoutForm;