import React, { useCallback } from 'react';
import { FormBox, SelectBox, PlacesInputRow, LabelBox, Label, PlacesInputRowSingle, FormError, WeightBox, WeightBoxText } from '../../style/main';
import { maxPounds } from '../MainForm'
import { businessPhoneNumber } from '../info/businessInfo'


const StepWeight = ({ form, setForm }) => {
    let i;
    let pounds = []
    for (i = 0; i <= maxPounds; i++) {
        pounds.push({
            text: i === 0 ? 'Under 1 pound' : i === 1 ? `1 pound` : i === maxPounds ? `${maxPounds - 1}+ pounds` : `${i} pounds`
        })
    }
    const weightSelect = useCallback((e) => {
        e.persist()
        if (parseInt(e.target.value) >= maxPounds) {
            setForm(x => ({
                ...x,
                weight: e.target.value,
                weightError: `<div class="html">Please call customer service: <a href='tel:${businessPhoneNumber}' class="htmlA"><strong>${businessPhoneNumber}</strong></a> with help creating a shipment for heavier products.</div>`,
            }))
        } else {
            setForm(x => ({
                ...x,
                weight: e.target.value,
                weightError: false
            }))
        }
    }, [setForm]);
    return (
        <>
            <FormBox>
                <PlacesInputRow>
                    <PlacesInputRowSingle>
                        <LabelBox>
                            <Label>How much does your product weigh?</Label>
                        </LabelBox>
                        <WeightBox>
                            <SelectBox className="weight" name="weight" onChange={weightSelect} value={form.weight ? form.weight : 'default'} noMargin={true} bgImg={'/img/arrowDown.svg'}>
                                <option value="default" disabled>Product weight</option>
                                {pounds.map((x, i) => <option key={i} value={i}>{x.text}</option>)}
                            </SelectBox>
                            <WeightBoxText>(lbs)</WeightBoxText>
                        </WeightBox>
                        {form.weightError && <FormError dangerouslySetInnerHTML={{ __html: form.weightError }} ></FormError>}
                    </PlacesInputRowSingle>
                </PlacesInputRow>
            </FormBox>
        </>
    );
}

export default StepWeight;