import React from 'react';
import { PrivacyBox } from '../../style/main';

const Terms = () => {
    return (
        <PrivacyBox>
            <h1>Terms &#x26; Conditions</h1>
            <div className="termsText">

                <h2>1. The Agreement</h2>
                <p>
                    These Terms &#x26; Conditions are effective on July 2, 2020. In the following terms Terms &#x26; conditions described the agreement and entire understanding between "Product Pictures" SB Development LLC (further defined as a "Photographer") and the Client.  These Terms &#x26; Conditions cannot be varied or changed in any way by Client(s) unless it was written and signed by all parties involved. Product Pictures reserves the right to update and change the Terms &#x26; Conditions by posting updates and changes to the Product Pictures website. You are advised to check the Terms &#x26; Conditions periodically for any updates or changes that may impact you. If you do not accept such amendments, you must agree to not use the service. You may review the current version of the Terms &#x26; Conditions at any time at <a href="https://product.pictures/terms">https://product.pictures.com/terms</a>.
            </p>
                <p>
                    You must read, agree with and accept all of the terms and conditions contained or expressly referenced in this <a href="https://product.pictures.com/terms">Terms &#x26; Conditions</a>, including <a href="https://product.pictures.com/privacy">Privacy Policy</a> before you may sign up, book or use any Product Pictures service.
            </p>

                <h2>2. Payment</h2>
                <p>
                    All Package costs are as stated at the time of booking and will remain once booking is confirmed. The full payment amount is due at the time of booking.
            </p>

                <h2>3. Cancellation and Termination.</h2>
                <p>
                    1. "Booking Time(s)" is deemed the selected booking time/date during your booking process. All Booking Time(s) are displayed in Eastern Daylight Time (EDT).<br />
            2. Client may request cancellation of photography services and may receive a full refund no later than 48 hours prior to your photoshoot Booking Time(s). <br />
            3. Client request of cancellation for photography services that occur less than 48 hours and prior to 12:00am of the same day Booking Time(s) may receive a 50 percent refund of total booking payment. <br />
            4. Same day cancellations or "no-shows" (Client not appearing for the photoshoot) will be charged a 100 percent fee. (Client will receive no refund for same day cancellations or "no-shows" no matter the reasoning)<br />
            5. Client will not receive any refunds once the photographs have been taken or services initiated. <br />
            6. You may cancel your booking or product purchase and terminate the Terms &#x26; Conditions by contacting: support@product.pictures. Provide your "Order Number" which can be located on your payment receipt provided at the completion of your payment or located at the bottom of your payment confirmation email. Your cancellation will not be deemed terminated, approved, nor any refunds issued without expressed written consent from Product Pictures approving the cancellation.<br />
            7. All approved cancellations will be refunded to the original authorized payment method.<br />
                </p>

                <h2>4. Force majeure</h2>
                <p>
                    1. The Photographer may choose to reschedule or cancel the photoshoot based on circumstances which include but are not limited to: bad weather conditions, acts of God, nature, war, epidemics, terrorism, civil disturbance. <br />
            2. Depending on availability of the Photographer, the Client may not be able to reschedule the photoshoot.<br />
            3. If Client is unable to reschedule the photoshoot, Client will receive a full refund issued to the original payment method.
            </p>

                <h2>5. Limitation of liability</h2>
                <p>
                    In the very unlikely event of cancellation by the Photographer or in case of total photographic failure due to emergency or any other cause beyond Photographer's control, liability will be limited to a full refund of the booking payment. This limitation on liability shall also apply in the event that photographic materials are damaged in processing, lost through camera malfunction, or otherwise lost or damaged without fault on the part of the Photographer. Accordingly, the parties agree that, in no event will Photographer's liability exceed the amounts Client paid to the Photographer under this agreement.
            </p>

                <h2>6. Copyright</h2>
                <p>
                    The Photographer owns the copyright to all images created during the photoshoot. The Photographer retains the rights in all cases to use the Images to include but not limited to Photographer's self-promotions, advertising, portfolio, editorial use.
            </p>

                <h2>7. Photography style and coverage</h2>
                <p>
                    Photographer will honor requested photographs but does not guarantee any specific photographs.&nbsp;Client acknowledges that she/he has viewed examples of previous work and is satisfied with the overall style of photography that is presented. Client understands that differences in locations, weather, background, subjects and lighting allow for variations in the final outcome of the photoshoot. Due to a variety of lighting conditions, some colors may alter throughout a set of photographs. Photographer reserves the right to artistically interpret how to photograph a scene and edit images.
            </p>

                <h2>8. Digital negative files</h2>
                <p>
                    All digital negatives (untouched, uncompressed RAW image files) remain the property of the Photographer. Unless otherwise agreed in writing, the RAW images are not being released to Client.
            </p>

                <h2>9. Assumption of Risk</h2>
                <p>
                    Client assumes full risk of loss or damage to or arising from materials furnished by Client and warrants that said materials are adequately insured against such loss, damage, or liability.
                    Any directions issued to clients, their guests or employees during a photographic shoot are deemed to be at said persons own risk. The photographer cannot be held responsible for any personal accidents during a photographic shoot.&nbsp;
            </p>

            </div>
        </PrivacyBox>
    );
}

export default Terms;