import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { useFirestore, useFirebase } from 'react-redux-firebase'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import generator from 'generate-password'
import { analytics } from '../index'
import { Form, FormTitle, Button, FormTitleText, FormTitleBack, FormTitleBackIcon, FormError, ButtonLoader, FormOuter, FormCover } from '../style/main';
import { stateValues } from './states'
import Step0 from './steps/step0';
import StepShipping from './steps/StepShipping';
import StepEmail from './steps/StepEmail';
import StepShippingRates from './steps/StepShippingRates';
import StepReturnedRates from './steps/StepReturnedRates';
import OrderTotal from './OrderTotal';
import CheckoutForm from './steps/CheckoutForm';
import Receipt from './steps/Receipt';
import { stripeId, uspsId, upsId, fedExId, ourAddress, businessPhoneNumber } from './info/businessInfo'
import StepWeight from './steps/StepWeight';
gsap.registerPlugin(ScrollToPlugin)

const promise = loadStripe(stripeId);

export const maxPounds = 21

const buttonText = ["Get photos!", "Find shipping rate", "Find return shipping rate", "Continue", "Place your order"]
const steps = [{
    title: "Get your Amazon product photos!",
    buttonText: buttonText[0]
}, {
    title: "Let's get started",
    buttonText: buttonText[3],
    stepEmail: true
}, {
    title: "Add a shipping information",
    buttonText: buttonText[3],
    stepShipping: true
}, {
    title: "Product weight",
    buttonText: buttonText[1],
    buttonText2: buttonText[2],
    stepWeight: true
}, {
    title: "Choose a shipping option",
    buttonText: buttonText[3],
    stepShippingOption: true
}, {
    title: "Add return shipping adddress",
    buttonText: buttonText[2],
    buttonText2: buttonText[3],
    stepReturned: true
}, {
    title: "Confirm return shipping",
    buttonText: buttonText[3],
    stepReturnedOption: true
}, {
    title: "Complete your order",
    buttonText: buttonText[4],
    stepBilling: true
}, {
    stepCheckoutLoading: true
}, {
    title: "Your order receipt",
    receipt: true
}]


const MainForm = React.memo(({ allBoxRightRef, photoAmount, ppCheckingOut, setPPCheckingOut, stripeCheckingOut, setStripeCheckingOut, setCheckoutPrepping }) => {
    const firebase = useFirebase();
    const firestore = useFirestore()
    const unsubscribe = useRef(false);
    const checkSignIn = useRef(() => {
        unsubscribe.current = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const userId = user.uid;
                const email = user.email;
                setForm(x => ({
                    ...x,
                    account: {
                        ...x.account,
                        email,
                        userId
                    }
                }))
            }
        })
    })
    useEffect(() => {
        checkSignIn.current()
        return () => {
            if (unsubscribe.current) {
                unsubscribe.current()
            }
        };
    }, []);
    const [form, setForm] = useState({
        step: 0,
        bgColor: 'white',
        photoAmount: 0,
        shipping: false,
        returned: false,
        account: {
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            userId: false
        },
        accountError: {
            firstName: false,
            lastName: false,
            email: false,
            phone: false
        },
        shippingAddress: {
            address: false,
            city: false,
            state: false,
            zipCode: false
        },
        shippingAddressError: {
            adddress: false,
            city: false,
            state: false,
            zipCode: false
        },
        chosenShippingRate: {
            cost: 0,
            rateId: false
        },
        returnedAddress: {
            address: false,
            city: false,
            zipCode: false,
            state: false,
            useSameAddress: false
        },
        returnedAddressError: {
            adddress: false,
            city: false,
            state: false,
            zipCode: false
        },
        chosenReturnedRate: {
            cost: 0,
            rateId: false
        },
        billing: {
            nameOnCard: false,
            cardNumber: false,
            month: false,
            year: false,
            cvv: false
        },
        returnCheckClicked: false,
        orderSummary: false,
        calculating: false,
        signupLoading: false,
        formError: false,
        orderId: false,
        labelPdf: false,
        receiptId: false,
        weight: false,
        weightError: false,
        showPaypal: false,
        labelError: false
    });
    const [compare, setCompare] = useState(false)
    const [stripeIntent, setStripeIntent] = useState(false);
    const [paypalTeardown, setPaypalTeardown] = useState(false);
    const submitForm = (e) => {
        e.preventDefault()
        nextStep()
    }
    const getShippingRate = useCallback(({ type }) => {
        const chosenState = stateValues.find(x => x.label === form[`${type}Address`].state);
        const addressUser = {
            name: `${form.account.firstName} ${form.account.lastName}`,
            phone: form.account.phone,
            address_line1: form[`${type}Address`].address,
            city_locality: form[`${type}Address`].city,
            state_province: chosenState.value,
            postal_code: form[`${type}Address`].zipCode,
            country_code: "US",
            address_residential_indicator: form[`${type}Address`].companyName ? 'yes' : 'no'
        }
        const obj = {
            rate_options: {
                carrier_ids: [uspsId, upsId, fedExId],
                service_codes: type === 'shipping' ? ["usps_parcel_select", "usps_priority_mail_express", "ups_ground", "ups_2nd_day_air", "ups_next_day_air", "fedex_ground", "fedex_2day", "fedex_priority_overnight"] : ["ups_ground", "usps_parcel_select", "fedex_ground"],
                package_types: ["package"]
            },
            shipment: {
                validate_address: "no_validation",
                ship_to: {
                    ...type === 'shipping' ? ourAddress : addressUser
                },
                ship_from: {
                    ...type === 'shipping' ? addressUser : ourAddress
                },
                packages: [
                    {
                        weight: {
                            value: parseInt(form.weight) === 0 ? 1 : form.weight,
                            unit: "pound"
                        }
                    }
                ]
            }
        }
        setForm(x => ({
            ...x,
            calculating: true
        }))
        const shippingRate = firebase.functions().httpsCallable('shippingRate');
        const shippingError = `<div class="html">Please call customer service: <a href='tel:${businessPhoneNumber}' class="htmlA"><strong>${businessPhoneNumber}</strong></a> with help creating a label.</div>`;
        shippingRate({
            data: obj
        }).then((response) => {
            const body = response.data
            let rates = false;
            if (body.rate_response) {
                rates = body.rate_response.rates
            }
            // NO RATES FOUND
            if (!rates.length) {
                setForm(x => ({
                    ...x,
                    calculating: false,
                    formError: response.data
                }))
                analytics.logEvent('errorNoRates')
            } else if (type === 'shipping') {
                // SHIPPING RATES
                let rateArr = []
                // eslint-disable-next-line array-callback-return
                rates.map(x => {
                    rateArr.push({
                        carrierId: x.carrier_id,
                        carrierName: x.carrier_id === uspsId ? "USPS" : x.carrier_id === upsId ? "UPS" : "FedEx",
                        deliveryDays: x.delivery_days,
                        cost: x.shipping_amount.amount,
                        serviceType: x.service_type,
                        serviceCode: x.service_code,
                        deliveryDayCount: x.carrier_delivery_days,
                        rateId: x.rate_id
                    })
                })
                let uspsRates = []
                let upsRates = [];
                let fedExRates = []
                // eslint-disable-next-line array-callback-return
                rateArr.map(x => {
                    if (x.carrierId === uspsId) {
                        uspsRates.push(x)
                    } else if (x.carrierId === upsId) {
                        upsRates.push(x)
                    } else if (x.carrierId === fedExId) {
                        fedExRates.push(x)
                    }
                })
                uspsRates.sort(function (a, b) {
                    return parseInt(a.cost) - parseInt(b.cost)
                });
                upsRates.sort(function (a, b) {
                    return parseInt(a.cost) - parseInt(b.cost)
                });
                fedExRates.sort(function (a, b) {
                    return parseInt(a.cost) - parseInt(b.cost)
                });
                let foundRates = [...uspsRates, ...upsRates, ...fedExRates]
                setForm(x => ({
                    ...x,
                    shippingRates: foundRates,
                    step: steps.findIndex(x => x.stepShippingOption),
                    calculating: false
                }))
            } else {
                // FIND A CARRIER THAT WORKS
                let foundRate = rates.find(x => x.carrier_id === uspsId)
                if (!foundRate) {
                    foundRate = rates.find(x => x.carrier_id === fedExId)
                }
                if (!foundRate) {
                    foundRate = rates.find(x => x.carrier_id === upsId)
                }
                if (!foundRate) {
                    setForm(x => ({
                        ...x,
                        calculating: false,
                        formError: shippingError
                    }))
                } else {
                    // RETURNED RATE
                    const returnedRate = {
                        carrierId: foundRate.carrier_id,
                        carrierName: foundRate.carrier_id === uspsId ? "USPS" : foundRate.carrier_id === upsId ? "UPS" : "FedEx",
                        cost: foundRate.shipping_amount.amount,
                        rateId: foundRate.rate_id
                    }
                    setForm(x => ({
                        ...x,
                        returnedRate,
                        chosenReturnedRate: {
                            carrierId: returnedRate.carrierId,
                            cost: returnedRate.cost,
                            rateId: returnedRate.rateId
                        },
                        step: steps.findIndex(x => x.stepReturnedOption),
                        calculating: false
                    }))
                }
            }
        }).catch(e => {
            console.log(e)
            analytics.logEvent('errorShipping')
            setForm(x => ({
                ...x,
                calculating: false,
                formError: shippingError
            }))
        });
    }, [firebase, form, setForm]);
    const nextStep = useCallback(() => {
        if (form.step === 0) {
            setForm(x => ({
                ...x,
                step: steps.findIndex(x => x.stepEmail)
            }))
            analytics.logEvent('stepFirst')
        } else if (steps[form.step].stepEmail) {
            let emailError = false;
            let firstNameError = false;
            let lastNameError = false;
            let phoneError = false;
            // EMAIL
            if (!form.account.email || form.account.email.length <= 3) {
                emailError = 'Please enter a valid email address.'
            } else if (!form.account.email.includes('@')) {
                emailError = "You're missing the @ symbol in your email."
            } else if (!form.account.email.includes('.')) {
                emailError = "You're missing '.' in your email."
            }
            // FIRST NAME
            if (!form.account.firstName) {
                firstNameError = 'Please enter your first name.'
            }
            // LAST NAME
            if (!form.account.lastName) {
                lastNameError = 'Please enter your last name.'
            }
            // PHONE
            if (!form.account.phone || form.account.phone.length < 10) {
                phoneError = 'Please enter a 10 digit phone number.'
            }
            // SET EMAIL ERROR
            if (firstNameError || lastNameError || emailError || phoneError) {
                setForm(x => ({
                    ...x,
                    accountError: {
                        email: emailError,
                        firstName: firstNameError,
                        lastName: lastNameError,
                        phone: phoneError
                    }
                }))
                return
            }
            // ONTO NEXT STEP AFTER SIGNUP/SIGNUP ERROR
            const stepAfterSignup = () => {
                setForm(x => ({
                    ...x,
                    step: form.shipping ? steps.findIndex(x => x.stepShipping) : form.returned ? steps.findIndex(x => x.stepReturned) : steps.findIndex(x => x.stepCheckoutLoading),
                    accountError: {
                        email: false,
                        firstName: false,
                        lastName: false
                    },
                    formError: false,
                    signupLoading: false,
                    showPaypal: form.shipping ? false : form.returned ? false : true
                }))
                analytics.logEvent('stepEmail')
            }
            // CREATE NEW USER
            setForm(x => ({
                ...x,
                signupLoading: true
            }))
            const password = generator.generate({
                length: Math.floor(Math.random() * (11 - 7) + 7),
                numbers: true
            });
            let id;
            firebase.auth().createUserWithEmailAndPassword(form.account.email, password).then((resp) => {
                id = resp.user.uid;
                return { id }
            }).then(({ id }) => {
                // CREATE STRIPE CUSTOMER FOR NEW USERS
                const customerCreate = firebase.functions().httpsCallable('customerCreate');
                return customerCreate({
                    id,
                    email: form.account.email,
                    firstName: form.account.firstName,
                    lastName: form.account.lastName,
                })
            }).then(() => {
                stepAfterSignup()
                return firestore.collection("users").doc(id).set({
                    userId: id,
                    email: form.account.email,
                    password,
                    firstName: form.account.firstName,
                    lastName: form.account.lastName,
                    pendingOrder: true,
                    photoAmountPreChosen: photoAmount[form.photoAmount].amount,
                })
            }).catch((e) => {
                if (e.code === 'auth/email-already-in-use') {
                    analytics.logEvent('errorSignupEmailInUse')
                } else {
                    analytics.logEvent('errorSignup')
                }
                stepAfterSignup()
            })
        } else if (steps[form.step].stepShipping || steps[form.step].stepReturned) {
            const type = steps[form.step].stepShipping ? 'shipping' : 'returned';
            // SHIPPING & RETURNED STEP
            let errorAddress = false;
            let errorCity = false;
            let errorState = false;
            let errorZipCode = false;
            if (!form[`${type}Address`].address) {
                errorAddress = true;
            }
            if (!form[`${type}Address`].city) {
                errorCity = true;
            }
            if (!form[`${type}Address`].state) {
                errorState = true;
            }

            if (!form[`${type}Address`].zipCode) {
                errorZipCode = 'Please enter a ZIP code.';
            } else if (form[`${type}Address`].zipCode.length > 5) {
                errorZipCode = 'ZIP code can only be 5 digits maximum.';
            } else if (form[`${type}Address`].zipCode.length < 5) {
                errorZipCode = 'Please enter a 5 digit ZIP code.';
            } else if (!form[`${type}Address`].zipCode.match(/^[0-9]+$/)) {
                // CHECK FOR LETTER
                errorZipCode = 'Please enter only digits.';
            }
            if (errorAddress || errorCity || errorState || errorZipCode) {
                setForm(x => ({
                    ...x,
                    [`${type}AddressError`]: {
                        ...x[`${type}AddressError`],
                        address: errorAddress,
                        city: errorCity,
                        state: errorState,
                        zipCode: errorZipCode
                    }
                }))
            } else if (steps[form.step].stepShipping) {
                setForm(x => ({
                    ...x,
                    step: steps.findIndex(x => x.stepWeight)
                }))
            } else if (steps[form.step].stepReturned) {
                // FOR RETURN - IF WEIGHT WAS CHOSEN ON SHIPPING STEP, GO DIRECTLY TO CALCULAT SHIPPING
                if (form.shipping && form.weight) {
                    getShippingRate({ type })
                } else {
                    setForm(x => ({
                        ...x,
                        step: steps.findIndex(x => x.stepWeight)
                    }))
                }
            }
        } else if (steps[form.step].stepWeight) {
            if (!form.weight) {
                setForm(x => ({
                    ...x,
                    weightError: 'Please select your product weight.'
                }))
            } else if (form.weight >= maxPounds) {
                analytics.logEvent('errorWeightOverMax')
                return
            } else if (!form.shipping) {
                // RETURNED SHIPPING
                getShippingRate({ type: 'returned' })
                analytics.logEvent('stepWeightReturned')
            } else {
                // REGULAR SHIPPING
                getShippingRate({ type: 'shipping' })
                analytics.logEvent('stepWeightShipping')
            }
        } else if (steps[form.step].stepShippingOption) {
            if (!form.chosenShippingRate.rateId) {
                setForm(x => ({
                    ...x,
                    formError: 'Please select a shipping option.'
                }))
                return
            }
            analytics.logEvent('stepShippingOption')
            // NEXT STEP
            setForm(x => ({
                ...x,
                step: form.returned ? steps.findIndex(x => x.stepReturned) : steps.findIndex(x => x.stepCheckoutLoading),
                showPaypal: form.returned ? false : true
            }))
        } else if (steps[form.step].stepReturnedOption) {
            analytics.logEvent('stepReturnOption')
            setForm(x => ({
                ...x,
                step: steps.findIndex(x => x.stepCheckoutLoading),
                showPaypal: true
            }))
        }
    }, [form, setForm, firebase, firestore, photoAmount, getShippingRate]);
    const stepGoBack = useCallback(() => {
        if (form.calculating || form.signupLoading || ppCheckingOut || stripeCheckingOut) {
            return
        }
        if (steps[form.step].stepEmail) {
            setForm(x => ({
                ...x,
                step: 0,
                formError: false
            }))
        } else if (steps[form.step].stepShipping) {
            setForm(x => ({
                ...x,
                step: steps.findIndex(x => x.stepEmail),
                formError: false
            }))
        } else if (steps[form.step].stepWeight) {
            // GO TO SHIPPING OR RETURNED
            setForm(x => ({
                ...x,
                step: !form.shipping ? steps.findIndex(x => x.stepReturned) : steps.findIndex(x => x.stepShipping)
            }))
        } else if (steps[form.step].stepShippingOption) {
            setForm(x => ({
                ...x,
                step: steps.findIndex(x => x.stepWeight),
                chosenShippingRate: {
                    cost: 0,
                    rateId: false
                },
                formError: false
            }))
        } else if (steps[form.step].stepReturned) {
            setForm(x => ({
                ...x,
                step: form.shipping ? steps.findIndex(x => x.stepShippingOption) : steps.findIndex(x => x.stepEmail),
                formError: false
            }))
        } else if (steps[form.step].stepReturnedOption) {
            // GO TO WEIGHT OPTION IF SHIPPING AND WEIGHT DO NOT EXIST
            setForm(x => ({
                ...x,
                step: (form.shipping && form.weight) ? steps.findIndex(x => x.stepReturned) : steps.findIndex(x => x.stepWeight),
                chosenReturnedRate: {
                    cost: 0,
                    rateId: false
                },
                formError: false
            }))
        } else if (steps[form.step].stepBilling) {
            paypalTeardown.teardown();
            setStripeIntent(false)
            setForm(x => ({
                ...x,
                step: form.returned ? steps.findIndex(x => x.stepReturnedOption) : form.shipping ? steps.findIndex(x => x.stepShippingOption) : steps.findIndex(x => x.stepEmail),
                formError: false,
                showPaypal: false
            }))
        }
    }, [form, setForm, paypalTeardown, ppCheckingOut, stripeCheckingOut]);
    useEffect(() => {
        if (steps[form.step].stepReturned && !form.returnCheckClicked) {
            if (form.chosenShippingRate.rateId) {
                setForm(x => ({
                    ...x,
                    returnedAddress: {
                        ...x.shippingAddress,
                        useSameAddress: true
                    }
                }))
            }
        }
    }, [form.step, form.chosenShippingRate.rateId, form.returnCheckClicked, setForm]);
    useLayoutEffect(() => {
        // SCROLL TO BUTTON ON THE SHIPPING CHOICE STEP
        if (form.chosenShippingRate.cost) {
            gsap.to(allBoxRightRef.current, { duration: 1, scrollTo: { y: allBoxRightRef.current.scrollHeight - allBoxRightRef.current.offsetHeight } });
        }
    }, [form.chosenShippingRate.cost, allBoxRightRef]);
    useLayoutEffect(() => {
        if (allBoxRightRef.current) {
            allBoxRightRef.current.scrollTop = 0;
        }
    }, [form.step, allBoxRightRef]);
    return (
        <FormOuter>
            <FormTitle>
                {(form.step > 0 && !steps[form.step].receipt && !steps[form.step].stepCheckoutLoading) &&
                    <FormTitleBack onClick={stepGoBack} calculating={form.calculating ? true : form.signupLoading ? true : ppCheckingOut ? true : stripeCheckingOut ? true : false} >
                        <FormTitleBackIcon viewBox="0 0 15 24">
                            <polygon points="12.17,0 15,2.829 5.661,12.004 15,21.171 12.17,24 0,12.004 " />
                        </FormTitleBackIcon>
                    </FormTitleBack>
                }
                <FormTitleText>{steps[form.step].title}</FormTitleText>
            </FormTitle>
            <Receipt form={form} photoAmount={photoAmount} steps={steps} setForm={setForm} setPPCheckingOut={setPPCheckingOut} setStripeCheckingOut={setStripeCheckingOut} />
            <Elements stripe={promise}>
                <CheckoutForm photoAmount={photoAmount} form={form} steps={steps} setForm={setForm} stripeIntent={stripeIntent} setStripeIntent={setStripeIntent} setPaypalTeardown={setPaypalTeardown} ppCheckingOut={ppCheckingOut} setPPCheckingOut={setPPCheckingOut} setStripeCheckingOut={setStripeCheckingOut} setCheckoutPrepping={setCheckoutPrepping} />
            </Elements>
            <Form onSubmit={submitForm} show={(steps[form.step].receipt || steps[form.step].stepCheckoutLoading) ? false : !steps[form.step].stepBilling ? true : false}>
                {form.calculating || form.signupLoading ? <FormCover /> : ''}
                {form.step === 0 && <Step0 form={form} setForm={setForm} photoAmount={photoAmount} compare={compare} setCompare={setCompare} />}
                {steps[form.step].stepEmail && <StepEmail form={form} setForm={setForm} />}
                {steps[form.step].stepShipping && <StepShipping form={form} setForm={setForm} type={'shipping'} />}
                {steps[form.step].stepWeight && <StepWeight form={form} setForm={setForm} />}
                {steps[form.step].stepShippingOption && <StepShippingRates form={form} setForm={setForm} />}
                {steps[form.step].stepReturned && <StepShipping form={form} setForm={setForm} type={'returned'} />}
                {steps[form.step].stepReturnedOption && <StepReturnedRates form={form} setForm={setForm} />}
                {form.formError && <FormError dangerouslySetInnerHTML={{ __html: form.formError }} ></FormError>}
                <OrderTotal photoAmount={photoAmount} form={form} steps={steps} setForm={setForm} />
                {form.signupLoading ?
                    <Button disabled={true} defaultPointer={true} disabledButton={true}>
                        <span>Creating account</span>
                        <ButtonLoader />
                    </Button>
                    : form.calculating ?
                        <Button disabled={true} defaultPointer={true} disabledButton={true}>
                            <span>Finding you the best rates!</span>
                            <ButtonLoader />
                        </Button>
                        :
                        <Button disabledButton={
                            (steps[form.step].stepShipping && !form.shippingAddress.address && !form.shippingAddress.city && !form.shippingAddress.zipCode && !form.shippingAddress.state) ? true
                                :
                                (steps[form.step].stepShippingOption && !form.chosenShippingRate.rateId) ? true
                                    :
                                    (steps[form.step].stepWeight && !form.weight) || (steps[form.step].stepWeight && form.weight >= maxPounds) ? true
                                        :
                                        ((steps[form.step].stepEmail && !form.account.email) || (steps[form.step].stepEmail && !form.account.firstName) || (steps[form.step].stepEmail && !form.account.lastName)) ? true
                                            :
                                            false
                        }
                        >
                            <span>{(steps[form.step].stepReturned && !form.shipping) ? steps[form.step].buttonText2 : (steps[form.step].stepWeight && !form.shipping) ? steps[form.step].buttonText2 : steps[form.step].buttonText}</span>
                        </Button>
                }
            </Form>
        </FormOuter>
    );
})

export default MainForm;