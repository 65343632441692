import React, { useState, useCallback } from 'react';
import { YesNoBox, YesNoTab, Radio, RadioCircle, QuestionStepIcon, ToolTipText, ToolTipBox, QuestionStepIconBox, ToolTipTextFloat } from '../style/main';

const FormYesNo = React.memo(({ type, form, setForm }) => {
    const [toolTipShip, setToolTipShip] = useState(false)
    const [toolTipReturn, setToolTipReturn] = useState(false)
    const yes = () => {
        if (type === 'shipping') {
            setForm(x => ({
                ...x,
                shipping: true
            }))
        } else {
            setForm(x => ({
                ...x,
                returned: true
            }))
        }
    }
    const no = () => {
        if (type === 'shipping') {
            setForm(x => ({
                ...x,
                shipping: false
            }))
        } else {
            setForm(x => ({
                ...x,
                returned: false
            }))
        }
    }
    const toolTipShipShow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return
        }
        setToolTipShip(true)
    }
    const toolTipShipHide = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return
        }
        setToolTipShip(false)
    }
    const toolTipReturnShow = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return
        }
        setToolTipReturn(true)
    }
    const toolTipReturnHide = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return
        }
        setToolTipReturn(false)
    }
    const onClickToolTipShip = useCallback(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setToolTipShip(x => !x)
        }
    }, [setToolTipShip]);
    const onClickToolTipReturn = useCallback(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setToolTipReturn(x => !x)
        }
    }, [setToolTipReturn]);
    return (
        <YesNoBox>
            <YesNoTab onClick={no} selected={type === 'shipping' && !form.shipping ? true : type === 'returned' && !form.returned ? true : false}>
                <Radio>
                    <RadioCircle selected={type === 'shipping' && !form.shipping ? true : type === 'returned' && !form.returned ? true : false} />
                </Radio>
                <span>No</span>
            </YesNoTab>
            <YesNoTab onClick={yes} selected={type === 'shipping' && form.shipping ? true : type === 'returned' && form.returned ? true : false}>
                <Radio>
                    <RadioCircle selected={type === 'shipping' && form.shipping ? true : type === 'returned' && form.returned ? true : false} />
                </Radio>
                <span>Yes</span>
            </YesNoTab>
            <ToolTipBox>
                <QuestionStepIconBox onClick={type === 'shipping' ? onClickToolTipShip : onClickToolTipReturn} onMouseOver={type === 'shipping' ? toolTipShipShow : toolTipReturnShow} onMouseOut={type === 'shipping' ? toolTipShipHide : toolTipReturnHide}>
                    <QuestionStepIcon viewBox="0 0 24 24">
                        <path d="M11.9-0.1c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C23.9,5.3,18.6-0.1,11.9-0.1z M12.8,18.6
		c-0.3,0.2-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.2-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1s0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4
		c0.3,0.3,0.4,0.6,0.4,1C13.2,18,13.1,18.4,12.8,18.6z M16.3,10c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.3-0.8,0.8-1.6,1.5
		c-0.2,0.2-0.4,0.4-0.5,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.6-0.5,1-1.1,1
		c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.6,1.1-1
		c0.4-0.3,0.7-0.6,0.9-0.8c0.2-0.2,0.3-0.4,0.4-0.6C14,9.1,14,8.9,14,8.6c0-0.5-0.2-0.9-0.6-1.3C13.1,7,12.6,6.8,12,6.8
		c-0.7,0-1.2,0.2-1.5,0.5c-0.3,0.3-0.6,0.9-0.8,1.5C9.5,9.6,9.1,9.9,8.5,9.9c-0.3,0-0.6-0.1-0.9-0.4C7.4,9.3,7.3,9.1,7.3,8.8
		c0-0.6,0.2-1.2,0.6-1.8c0.4-0.6,0.9-1.1,1.7-1.5c0.7-0.4,1.6-0.6,2.5-0.6c0.9,0,1.7,0.2,2.4,0.5c0.7,0.3,1.2,0.8,1.6,1.4
		c0.4,0.6,0.6,1.2,0.6,1.9C16.6,9.1,16.5,9.6,16.3,10z"/>
                    </QuestionStepIcon>
                </QuestionStepIconBox>
                <ToolTipText onClick={type === 'shipping' ? onClickToolTipShip : onClickToolTipReturn} show={type === 'shipping' ? toolTipShip : toolTipReturn}>
                    <ToolTipTextFloat>{type === 'shipping' ? 'Receive rates and create a label automatically.' : 'Choose to have your product return shipped.'}</ToolTipTextFloat>
                </ToolTipText>
            </ToolTipBox>
        </YesNoBox>
    );
})

export default FormYesNo;