import React, { useRef, useEffect } from 'react';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { IntroBox, IntroTop, IntroTitle, IntroTitle2, IntroWrap, IntroP, DownArrowHeader, CreatedBySmall } from '../style/main';
gsap.registerPlugin(ScrollToPlugin)

const Intro = React.memo(() => {
    const arrowHeader = useRef(null);
    useEffect(() => {
        const arrowTimeline = gsap.timeline({ repeat: -1 }).yoyo(true)
        arrowTimeline.to(arrowHeader.current, 0.3, { y: -10 })
    }, []);
    const scrollTo = () => {
        const headerBox = document.getElementById('headerBox')
        gsap.to(window, { duration: 0.5, scrollTo: { y: "#largeProductAll", offsetY: window.innerWidth < 1024 ? headerBox.offsetHeight : 0, autoKill: false } });
    }
    return (
        <IntroBox>
            <IntroTop>
                <IntroWrap>
                    <IntroTitle><span className="orange">Amazon</span> Product Photography</IntroTitle>
                    <CreatedBySmall>Created By Product.Pictures</CreatedBySmall>
                    <IntroTitle2>Boost your profits selling on Amazon with our professional product photography today!</IntroTitle2>
                    <IntroP>Great news! You can now take the guess work out of Amazon product photography. With our high quality Amazon product photography you will be able to stand out above your competition and increase  your sales on Amazon in no time.</IntroP>
                    <DownArrowHeader viewBox="0 0 256 512" ref={arrowHeader} onClick={scrollTo}>
                        <path d="M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z"></path>
                    </DownArrowHeader>
                </IntroWrap>
            </IntroTop>
        </IntroBox>
    );
})

export default Intro;