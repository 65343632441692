import React, { useEffect } from 'react';
import { ReceiptBox, ReceiptIdText, ReceiptPdf, ReceiptSupportBox, ReceiptSupportTitle, ReceiptSupportType, ReceiptSupportTypes, ReceiptSupportTypeLink, SupportTypeBox, PrintReceipt, PrintReceiptText, LabelErrorReceipt } from '../../style/main';
import { Link } from "react-router-dom";

import OrderTotal from '../OrderTotal';
import { businessPhoneNumber, businessEmail } from '../info/businessInfo'

const Receipt = ({ form, photoAmount, steps, setForm, setPPCheckingOut, setStripeCheckingOut }) => {
    useEffect(() => {
        if (steps[form.step].receipt) {
            setPPCheckingOut(false)
            setStripeCheckingOut(false)
        }
    }, [form.step, steps, setPPCheckingOut, setStripeCheckingOut]);
    return (
        <ReceiptBox show={steps[form.step].receipt ? true : false}>
            <ReceiptIdText>
                Order ID: {form.orderId && form.orderId}
            </ReceiptIdText>
            {
                form.labelPdf ?
                    <ReceiptPdf href={form.labelPdf} target="_blank">Print your shipping label</ReceiptPdf> :
                    form.labelError ?
                        <LabelErrorReceipt>
                            <div>Whoops! We had trouble creating your shipping label please contact support for assistance:</div>
                            <ReceiptSupportTypes>
                                <SupportTypeBox>
                                    <ReceiptSupportType>Phone:</ReceiptSupportType> <ReceiptSupportTypeLink href={`tel:${businessPhoneNumber}`}>{businessPhoneNumber}</ReceiptSupportTypeLink>
                                </SupportTypeBox>
                                <SupportTypeBox>
                                    <ReceiptSupportType>Email:</ReceiptSupportType> <ReceiptSupportTypeLink href={`mailto:${businessEmail}`}>{businessEmail}</ReceiptSupportTypeLink>
                                </SupportTypeBox>
                            </ReceiptSupportTypes>
                        </LabelErrorReceipt> :
                        <ReceiptPdf href={`/packing/${form.orderId}`} target="_blank">Print your packing slip</ReceiptPdf>
            }
            <OrderTotal photoAmount={photoAmount} form={form} steps={steps} setForm={setForm} />
            <PrintReceipt>
                <Link to={`/rec/${form.receiptId}`} target="_blank">
                    <PrintReceiptText>View your full receipt</PrintReceiptText>
                </Link>
            </PrintReceipt>
            <ReceiptSupportBox>
                <ReceiptSupportTitle>Question about your order?</ReceiptSupportTitle>
                <ReceiptSupportTypes>
                    <SupportTypeBox>
                        <ReceiptSupportType>Phone:</ReceiptSupportType> <ReceiptSupportTypeLink href={`tel:${businessPhoneNumber}`}>{businessPhoneNumber}</ReceiptSupportTypeLink>
                    </SupportTypeBox>
                    <SupportTypeBox>
                        <ReceiptSupportType>Email:</ReceiptSupportType> <ReceiptSupportTypeLink href={`mailto:${businessEmail}`}>{businessEmail}</ReceiptSupportTypeLink>
                    </SupportTypeBox>
                </ReceiptSupportTypes>
            </ReceiptSupportBox>
        </ReceiptBox>
    );
}

export default Receipt;