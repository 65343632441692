import React from 'react';
import { IntroWrap, IntroP, InnerH2Title, ParagraphH2, WhatYouGetBox, WhatYouGetChecks, WhatYouGetCheck, WhatYouGetCheckIcon, WhatYouGetCheckText, CtaMobile } from '../style/main';

const WhatYouGet = React.memo(({ setSignupOpen }) => {
    const openForm = () => {
        document.querySelector('body').classList.add('overflow');
        setSignupOpen(true)
    }
    return (
        <WhatYouGetBox>
            <IntroWrap>
                <InnerH2Title><span className="orange">What</span> You Get</InnerH2Title>
                <ParagraphH2><strong><u>High quality</u></strong> Amazon product photos</ParagraphH2>
                <IntroP>You will receive high quality product photos that are perfectly created for selling on Amazon. Your photos will come pre-made to fit all of Amazon's rules/standards for product images. We offer you a professional <strong><u>plug and play solution</u></strong> with no thinking involved.</IntroP>
                <WhatYouGetChecks>
                    <WhatYouGetCheck>
                        <WhatYouGetCheckIcon viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                        </WhatYouGetCheckIcon>
                        <WhatYouGetCheckText>
                            High quality Amazon product photos
                    </WhatYouGetCheckText>
                    </WhatYouGetCheck>
                    <WhatYouGetCheck>
                        <WhatYouGetCheckIcon viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                        </WhatYouGetCheckIcon>
                        <WhatYouGetCheckText>
                            Made to fit Amazon product photo rules/standards
                    </WhatYouGetCheckText>
                    </WhatYouGetCheck>
                    <WhatYouGetCheck>
                        <WhatYouGetCheckIcon viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                        </WhatYouGetCheckIcon>
                        <WhatYouGetCheckText>
                            Your choice of photo amount
                    </WhatYouGetCheckText>
                    </WhatYouGetCheck>
                    <WhatYouGetCheck>
                        <WhatYouGetCheckIcon viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                        </WhatYouGetCheckIcon>
                        <WhatYouGetCheckText>
                            Bonus: Full resolution photos for marketing use
                    </WhatYouGetCheckText>
                    </WhatYouGetCheck>
                    <WhatYouGetCheck>
                        <WhatYouGetCheckIcon viewBox="0 0 24 24">
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                        </WhatYouGetCheckIcon>
                        <WhatYouGetCheckText>
                            Affordable pricing!
                    </WhatYouGetCheckText>
                    </WhatYouGetCheck>
                </WhatYouGetChecks>
                <CtaMobile onClick={openForm}>Start Now</CtaMobile>
            </IntroWrap>
        </WhatYouGetBox>
    );
})

export default WhatYouGet;