import React, { useState, useCallback } from 'react';
import { IntroWrap, InnerH2Title, ParagraphH2, ReviewsBox, Reviews, MoreReviewsBox, MoreReviewsButton } from '../style/main';
import ReviewSingle from './ReviewSingle';
import ReviewForm from './ReviewForm';
import reviewText from './ReviewText';
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const reviewsPerPage = 10;

const ReviewInfo = React.memo(() => {
    const [reviewPaging, setReviewPaging] = useState({
        page: 0,
        reviews: reviewText.slice(0, reviewsPerPage)
    });
    const nextPage = useCallback(() => {
        setReviewPaging(x => ({
            ...x,
            page: x.page + 1,
            reviews: reviewText.slice(0, reviewsPerPage * (x.page + 2))
        }))
    }, [setReviewPaging]);
    return (
        <ReviewsBox>
            <IntroWrap>
                <InnerH2Title mobilePadding={true}>Customer <span className="orange">Reviews</span></InnerH2Title>
                <ParagraphH2>Read some of our <strong className="noWrap"><u>customer reviews.</u></strong></ParagraphH2>
                <ReviewForm />
                <Reviews>
                    {reviewPaging.reviews.map((x, i) => <ReviewSingle name={x.name} title={x.title} text={x.text} rating={x.rating} key={i} />)}
                </Reviews>
                {(reviewPaging.page + 1) * reviewsPerPage < reviewText.length &&
                    <MoreReviewsBox>
                        <MoreReviewsButton onClick={nextPage}>Load More Revies</MoreReviewsButton>
                    </MoreReviewsBox>
                }
            </IntroWrap>
        </ReviewsBox>
    );
})

export default ReviewInfo;