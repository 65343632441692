import React from 'react';
import { Tab, Radio, RadioCircle, TabValue } from '../style/main';

const FormTabs = React.memo(({ formPhotoAmount, amount, count, setForm, last }) => {
    const onClick = () => {
        setForm(x => ({
            ...x,
            photoAmount: count
        }))
    }
    return (
        <>
            <Tab selected={formPhotoAmount === count ? true : false} last={last} onClick={onClick}>
                <Radio>
                    <RadioCircle selected={formPhotoAmount === count ? true : false} />
                </Radio>
                <span>{amount}</span>
                {last && <TabValue>Value</TabValue>}
            </Tab>
        </>
    );
})

export default FormTabs;