import React from 'react';
import { FormBox, ShippingReturnedRatesTab, Radio, RadioCircle, ShippingReturnedRatesTabText, ShippingReturnedRatesTabTextLeft, ShippingReturnedRatesTabTextRight, ShippingReturnedRatesPrice, ShippingReturnedRatesPriceText } from '../../style/main';

const StepReturnedRates = React.memo(({ form, setForm }) => {
    const choiceYes = () => {
        setForm(x => ({
            ...x,
            chosenReturnedRate: {
                carrierId: x.returnedRate.carrierId,
                cost: x.returnedRate.cost,
                rateId: x.returnedRate.rateId
            }
        }))
    }
    const choiceNo = () => {
        setForm(x => ({
            ...x,
            chosenReturnedRate: {
                cost: 0,
                rateId: false
            }
        }))
    }
    return (
        <FormBox>
            <ShippingReturnedRatesTab selected={form.chosenReturnedRate.cost} boldFont={true} onClick={choiceYes}>
                <Radio>
                    <RadioCircle selected={form.chosenReturnedRate.cost} />
                </Radio>
                <ShippingReturnedRatesTabText>
                    <ShippingReturnedRatesTabTextLeft>
                        Yes, please return my product.
                            </ShippingReturnedRatesTabTextLeft>
                    <ShippingReturnedRatesTabTextRight>
                        <ShippingReturnedRatesPriceText>Price</ShippingReturnedRatesPriceText>
                        <ShippingReturnedRatesPrice>
                            ${form.returnedRate.cost.toFixed(2)}
                        </ShippingReturnedRatesPrice>
                    </ShippingReturnedRatesTabTextRight>
                </ShippingReturnedRatesTabText>
            </ShippingReturnedRatesTab>
            <ShippingReturnedRatesTab selected={form.chosenReturnedRate.cost === 0 && true} onClick={choiceNo}>
                <Radio>
                    <RadioCircle selected={form.chosenReturnedRate.cost === 0 && true} />
                </Radio>
                <ShippingReturnedRatesTabText>
                    <ShippingReturnedRatesTabTextLeft>
                        No thank you, please donate my product.
                            </ShippingReturnedRatesTabTextLeft>
                    <ShippingReturnedRatesTabTextRight>
                        $0.00
                            </ShippingReturnedRatesTabTextRight>
                </ShippingReturnedRatesTabText>
            </ShippingReturnedRatesTab>
        </FormBox>
    );
})

export default StepReturnedRates;