import React from 'react';
import { SubmitReviewStar } from '../style/main';

const ReviewRatingStar = React.memo(({ setReviewRating, rating, currentRating }) => {
    const onClick = () => {
        setReviewRating({ rating })
    }
    return (
        <SubmitReviewStar viewBox="0 0 24 24" onClick={onClick}>
            <path fill={(currentRating && rating <= currentRating) ? 'url(#grad1)' : '#eeeeee'} stroke="#c07d12" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
        </SubmitReviewStar>
    );
})

export default ReviewRatingStar;