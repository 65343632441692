import React from 'react';
import { IntroWrap, IntroP, InnerH2Title, ParagraphH2, BoostProfitsBox, CtaMobile } from '../style/main';

const BoostProfits = React.memo(({ setSignupOpen }) => {
    const openForm = () => {
        document.querySelector('body').classList.add('overflow');
        setSignupOpen(true)
    }
    return (
        <BoostProfitsBox>
            <IntroWrap>
                <InnerH2Title><span className="orange">Boost</span> Your Amazon Profits</InnerH2Title>
                <ParagraphH2>The simple fact is <strong><u>product photos do matter</u></strong> while selling on Amazon.</ParagraphH2>
                <IntroP>Leaving a great first impression is paramount over everything while selling on Amazon. The simple fact is <strong><u>product photos do matter</u></strong>. You need quality product photos to impress your potential customers or they will just simply walk away. </IntroP>
                <IntroP>When it comes to selling on the internet... and especially Amazon. You cannot think of your product as if it sits on the shelf in a regular store. In person a customer has a chance to hold, feel and touch your product. But what about on Amazon? With the shopping experience being completely virtual. You can never underestimate just how powerful it is for the visual of your product to stand out and help you drive sales.</IntroP>
                <IntroP><strong><u>You need Amazon product photos</u></strong> and we are here to help. Our simple ordering process makes it easy for anyone selling on Amazon to have high quality Amazon product photos at an affordable price. Start standing out above your competition! Order now.</IntroP>
                <CtaMobile onClick={openForm}>Start Now</CtaMobile>
            </IntroWrap>
        </BoostProfitsBox>
    );
})

export default BoostProfits;