import React, { useState, useCallback, useEffect, useRef } from 'react';
import gsap from "gsap";
import { LargeProductBox, LargeProductAll, LargeProductBoxBadge, LargeProductBoxBadgeText, LargeProductBoxBadgePrice, LargeProductBoxBadgeTextSmall, LargeProductBoxSteps, LargeProductBoxArrow, LargeProductBoxArrowBox, LargeProductWrap, LargeProductTitle, LargeSliderBox, LargeProductMobileArrowBox, LargeProductMobileArrowWrap } from '../style/main';
import LargeSliderImg from './LargeSliderImg';
import LargeSliderCircle from './LargeSliderCircle';

const folder = 'sliderMid/'
const largeFoler = `${folder}/large/`
const productImages = [{
    src: `${folder}0.jpg`,
    largeSrc: `${largeFoler}0.jpg`
}, {
    src: `${folder}1.jpg`,
    largeSrc: `${largeFoler}1.jpg`
}, {
    src: `${folder}2.jpg`,
    largeSrc: `${largeFoler}2.jpg`
}, {
    src: `${folder}3.jpg`,
    largeSrc: `${largeFoler}3.jpg`
}, {
    src: `${folder}4.jpg`,
    largeSrc: `${largeFoler}4.jpg`
}]

const slideTime = 0.5

const LargeSlider = React.memo(({ photoAmount, setPopImg, largeImg }) => {
    const firstLoad = useRef(true)
    const sliderTimeout = useRef(null)
    const sliderRef = useRef(null)
    const [sliding, setSliding] = useState(false)
    const [sliderInView, setSliderInView] = useState(false)
    const [largeSlide, setLargeSlide] = useState({
        count: 0,
    });
    const previousImage = useCallback(() => {
        if (sliding) {
            return
        }
        const resetSliding = () => {
            setSliding(false)
        }
        clearSliderTimeout()
        setSliding(true)
        const slideTimeline = gsap.timeline({ onComplete: resetSliding });
        const largeSliderImg = document.querySelectorAll('.largeSliderImg');
        let sliderCount = largeSlide.count;
        gsap.set(largeSliderImg[sliderCount === 0 ? productImages.length - 1 : sliderCount - 1], { xPercent: -100, rotate: 0, opacity: 0, });
        slideTimeline.to(largeSliderImg[sliderCount === 0 ? productImages.length - 1 : sliderCount - 1], { xPercent: 0, opacity: 1, duration: slideTime, ease: "power2.in" });
        slideTimeline.to(largeSliderImg[sliderCount], { xPercent: 100, rotate: 40, duration: slideTime, ease: "power2.in" }, 0);
        slideTimeline.set(largeSliderImg[sliderCount], { opacity: 0 });
        sliderCount--;
        if (sliderCount < 0) {
            sliderCount = productImages.length - 1;
        }
        setLargeSlide(x => ({
            ...x,
            count: sliderCount
        }))
    }, [largeSlide.count, setLargeSlide, sliding, setSliding]);
    const clearSliderTimeout = () => {
        clearTimeout(sliderTimeout.current)
    }
    const nextImage = useCallback(() => {
        if (sliding) {
            return
        }
        const resetSliding = () => {
            setSliding(false)
        }
        clearSliderTimeout()
        setSliding(true)
        const slideTimeline = gsap.timeline({ onComplete: resetSliding });
        const largeSliderImg = document.querySelectorAll('.largeSliderImg');
        let sliderCount = largeSlide.count;
        gsap.set(largeSliderImg[sliderCount === productImages.length - 1 ? 0 : sliderCount + 1], { xPercent: 100, rotate: 0, opacity: 0 });
        slideTimeline.to(largeSliderImg[sliderCount === productImages.length - 1 ? 0 : sliderCount + 1], { xPercent: 0, opacity: 1, duration: slideTime, ease: "power2.in" });
        slideTimeline.to(largeSliderImg[sliderCount === productImages.length - 1 ? productImages.length - 1 : sliderCount], { xPercent: -100, rotate: -40, duration: slideTime, ease: "power2.in" }, 0);
        slideTimeline.set(largeSliderImg[sliderCount === productImages.length - 1 ? productImages.length - 1 : sliderCount], { opacity: 0 });
        sliderCount++
        if (sliderCount === productImages.length) {
            sliderCount = 0;
        }
        setLargeSlide(x => ({
            ...x,
            count: sliderCount
        }))
    }, [largeSlide.count, setLargeSlide, sliding, setSliding]);
    const sliderDotClick = useCallback(({ count }) => {
        if (sliding) {
            return
        }
        const resetSliding = () => {
            setSliding(false)
        }
        clearSliderTimeout()
        setSliding(true)
        const slideTimeline = gsap.timeline({ onComplete: resetSliding });
        const largeSliderImg = document.querySelectorAll('.largeSliderImg');
        if (count > largeSlide.count) {
            gsap.set(largeSliderImg[count], { xPercent: 100, rotate: 0, opacity: 0 });
            slideTimeline.to(largeSliderImg[count], { xPercent: 0, opacity: 1, duration: slideTime, ease: "power2.in" });
            slideTimeline.to(largeSliderImg[largeSlide.count], { xPercent: -100, rotate: -40, duration: slideTime, ease: "power2.in" }, 0);
            slideTimeline.set(largeSliderImg[largeSlide.count], { opacity: 0 });
        } else if (count < largeSlide.count) {
            gsap.set(largeSliderImg[count], { xPercent: -100, rotate: 0, opacity: 0 });
            slideTimeline.to(largeSliderImg[count], { xPercent: 0, opacity: 1, duration: slideTime, ease: "power2.in" });
            slideTimeline.to(largeSliderImg[largeSlide.count], { xPercent: 100, rotate: 40, opacity: 0, duration: slideTime, ease: "power2.in" }, 0);
            slideTimeline.set(largeSliderImg[largeSlide.count], { opacity: 0 });
        } else {
            return
        }
        setLargeSlide(x => ({
            ...x,
            count: count
        }))
    }, [largeSlide.count, setLargeSlide, sliding, setSliding]);
    useEffect(() => {
        const largeArrowLeft = document.getElementById('largeArrowLeft')
        const largeArrowRight = document.getElementById('largeArrowRight')
        const allBoxLeft = document.getElementById('allBoxLeft')
        const addNoSelect = () => {
            allBoxLeft.classList.add('noSelect');
        }
        const removeNoSelect = () => {
            allBoxLeft.classList.remove('noSelect');
        }
        // ADD NO SELECT TO ALL BOX LEFT, TO STOP TEXT FROM HIGHLIGHTING WHEN CLICKING ARROW
        largeArrowLeft.addEventListener('mousedown', addNoSelect);
        largeArrowRight.addEventListener('mousedown', addNoSelect);
        // REMOVE NO SELECT TO ALL BOX LEFT, TO STOP TEXT FROM HIGHLIGHTING WHEN CLICKING ARROW
        largeArrowLeft.addEventListener('mouseup', removeNoSelect);
        largeArrowRight.addEventListener('mouseup', removeNoSelect);
        // SET TWEEN MAX SLIDER
        const largeSliderImg = document.querySelectorAll('.largeSliderImg');
        largeSliderImg.forEach((x, i) => {
            if (i !== 0) {
                gsap.set(x, { xPercent: 100, opacity: 0 })
            } else {
                gsap.set(x, { opacity: 1 })
            }
        });
        return () => {
            largeArrowLeft.removeEventListener('mousedown', addNoSelect);
            largeArrowRight.removeEventListener('mousedown', addNoSelect);
            largeArrowLeft.removeEventListener('mouseup', removeNoSelect);
            largeArrowRight.removeEventListener('mouseup', removeNoSelect);
        }
    }, []);
    // useEffect(() => {
    //     const onScroll = () => {
    //         const bounding = sliderRef.current.getBoundingClientRect();
    //         const sliderHeight = sliderRef.current.offsetHeight;
    //         if ((bounding.top + sliderHeight >= 0) && (bounding.bottom - sliderHeight - window.innerHeight <= 0) && !firstLoad.current) {
    //             setSliderInView(true)
    //         } else {
    //             setSliderInView(false)
    //         }
    //     }
    //     window.addEventListener('scroll', onScroll);
    //     return () => {
    //         window.removeEventListener('scroll', onScroll)
    //     }
    // }, []);
    // useEffect(() => {
    //     if (largeImg || !sliderInView) {
    //         clearSliderTimeout()
    //     }
    //     if (!sliding && sliderInView && !largeImg) {
    //         sliderTimeout.current = setTimeout(() => {
    //             nextImage()
    //         }, 5000);
    //     }
    // }, [sliding, nextImage, sliderInView, largeImg]);
    // useEffect(() => {
    //     if (firstLoad.current) {
    //         firstLoad.current = false;
    //         const bounding = sliderRef.current.getBoundingClientRect();
    //         const position = window.pageYOffset;
    //         if (bounding.top >= 0 && bounding.bottom <= (window.innerHeight) && position > 10) {
    //             setTimeout(() => {
    //                 nextImage()
    //                 setSliderInView(true)
    //             }, 3000);
    //         }
    //     }
    // }, [nextImage]);
    return (
        <LargeProductAll id="largeProductAll">
            <LargeProductWrap>
                <LargeProductBoxArrowBox></LargeProductBoxArrowBox>
                <LargeProductTitle>High quality product photos, <span>instantly ready to start selling on Amazon!</span></LargeProductTitle>
                <LargeProductBoxArrowBox></LargeProductBoxArrowBox>
            </LargeProductWrap>
            <LargeProductWrap ref={sliderRef}>
                <LargeProductBoxArrowBox onClick={previousImage} id="largeArrowLeft">
                    <LargeProductBoxArrow viewBox="0 0 24 24">
                        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                    </LargeProductBoxArrow>
                </LargeProductBoxArrowBox>
                <LargeProductBox>
                    <LargeSliderBox>
                        {productImages.map((x, i) => <LargeSliderImg src={x.src} largeSrc={x.largeSrc} firstImg={i === 0 ? true : false} setPopImg={setPopImg} key={i} />)}
                    </LargeSliderBox>
                    <LargeProductBoxBadge>
                        <LargeProductBoxBadgeText>Starting at</LargeProductBoxBadgeText>
                        <LargeProductBoxBadgePrice>${photoAmount[photoAmount.length - 1].price / photoAmount[photoAmount.length - 1].amount}!</LargeProductBoxBadgePrice>
                        <LargeProductBoxBadgeTextSmall>per photo</LargeProductBoxBadgeTextSmall>
                    </LargeProductBoxBadge>
                    <LargeProductBoxSteps>
                        {productImages.map((x, i) => <LargeSliderCircle count={i} key={i} largeSlideCount={largeSlide.count} sliderDotClick={sliderDotClick} />)}
                    </LargeProductBoxSteps>
                </LargeProductBox>
                <LargeProductBoxArrowBox onClick={nextImage} id="largeArrowRight">
                    <LargeProductBoxArrow viewBox="0 0 24 24">
                        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                    </LargeProductBoxArrow>
                </LargeProductBoxArrowBox>
            </LargeProductWrap>
            <LargeProductMobileArrowBox>
                <LargeProductMobileArrowWrap>
                    <LargeProductBoxArrowBox onClick={previousImage} mobileShow={true} id="largeArrowLeft">
                        <LargeProductBoxArrow viewBox="0 0 24 24">
                            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                        </LargeProductBoxArrow>
                    </LargeProductBoxArrowBox>
                    <LargeProductBoxArrowBox onClick={nextImage} mobileShow={true} id="largeArrowRight">
                        <LargeProductBoxArrow viewBox="0 0 24 24">
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                        </LargeProductBoxArrow>
                    </LargeProductBoxArrowBox>
                </LargeProductMobileArrowWrap>
            </LargeProductMobileArrowBox>
        </LargeProductAll>
    );
})

export default LargeSlider;