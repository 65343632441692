import React from 'react';
import { FormBox, ExtraInfo, ExtraInfoRow, ShippingOptionsPriceHeader, ShippingOptionsBox, ShippingOptionsList, ShippingOptionsPrice } from '../../style/main';
import ShippingChoice from '../ShippingChoice';

const StepShippingRates = React.memo(({ form, setForm }) => {
    return (
        <FormBox>
            <ExtraInfoRow>
                <ExtraInfo>
                    <ShippingOptionsBox>
                        <ShippingOptionsPriceHeader>
                            <span>Shipping options:</span>
                            <ShippingOptionsPrice>Price</ShippingOptionsPrice>
                        </ShippingOptionsPriceHeader>
                        <ShippingOptionsList>
                            {form.shippingRates && form.shippingRates.map((x, i) => <ShippingChoice key={i} {...x} form={form} setForm={setForm} />)}
                        </ShippingOptionsList>
                        <ShippingOptionsPriceHeader footer={true}>
                            <span>Shipping Price:</span>
                            <ShippingOptionsPrice>${form.chosenShippingRate.cost.toFixed(2)}</ShippingOptionsPrice>
                        </ShippingOptionsPriceHeader>
                    </ShippingOptionsBox>
                </ExtraInfo>
            </ExtraInfoRow>
        </FormBox>
    );
})

export default StepShippingRates;