import React, { useRef, useState, useCallback, useEffect } from 'react';
import { gsap } from "gsap";
import { AllBox, AllBoxRight, AllBoxLeft, LeftBox, FormCornerX, FormCornerXIcon } from '../style/main';
import MainForm from './MainForm';
import Header from './Header';
import Slider from './Slider';
import Faq from './Faq';
import LargeSlider from './LargeSlider';
import HowItWorks from './HowItWorks';
import WhatYouGet from './WhatYouGet';
import BoostProfits from './BoostProfits';
import Intro from './Intro';
import SupportBottom from './SupportBottom';
import ImgPopAll from './ImgPopAll';
import ReviewInfo from './ReviewInfo';

const photoAmount = [{
    amount: 1,
    price: 40
}, {
    amount: 2,
    price: 70
}, {
    amount: 5,
    price: 100
}]

const Home = React.memo(() => {
    const sliderTimeline = useRef(null);
    const firstLoad = useRef(true);
    const allBoxRightRef = useRef(false);
    const [scrollTop, setScrollTop] = useState(true);
    const [largeImg, setLargeImg] = useState(false);
    const [signupOpen, setSignupOpen] = useState(false);
    const [ppCheckingOut, setPPCheckingOut] = useState(false)
    const [stripeCheckingOut, setStripeCheckingOut] = useState(false)
    const [checkoutPrepping, setCheckoutPrepping] = useState(false)
    const setPopImg = useCallback(({ largeSrc }) => {
        setLargeImg(largeSrc)
        document.querySelector('body').classList.add('overflow');
    }, [setLargeImg]);
    const closeImgPopBox = () => {
        setLargeImg(false)
        if (sliderTimeline.current) {
            sliderTimeline.current.play()
        }
        document.querySelector('body').classList.remove('overflow');
    }
    const onScroll = () => {
        const position = window.pageYOffset;
        if (position < 10) {
            setScrollTop(true)
        } else {
            setScrollTop(false)
        }
    }
    useEffect(() => {
        if (firstLoad.current) {
            return
        }
        const introSlider = document.getElementById('introSlider')
        if (scrollTop) {
            gsap.to(introSlider, { opacity: 1, duration: 0.3, yPercent: 0, ease: "none" });
            setTimeout(() => {
                sliderTimeline.current.play()
            }, 400);
        } else {
            sliderTimeline.current.pause()
            gsap.to(introSlider, { opacity: 0, duration: 0.3, yPercent: 100, ease: "none" });
        }
    }, [scrollTop]);
    useEffect(() => {
        const position = window.pageYOffset;
        const introSlider = document.getElementById('introSlider')
        if (firstLoad.current && sliderTimeline.current) {
            if (position < 10) {
                sliderTimeline.current.play()
            } else {
                gsap.set(introSlider, { opacity: 0, yPercent: 100 });
            }
            firstLoad.current = false
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, []);
    useEffect(() => {
        if (largeImg && sliderTimeline.current) {
            sliderTimeline.current.pause()
        }
    }, [largeImg]);
    const setSignupOpenClick = useCallback(() => {
        if (ppCheckingOut || stripeCheckingOut) {
            return
        }
        setSignupOpen(false)
        document.querySelector('body').classList.remove('overflow');
    }, [ppCheckingOut, setSignupOpen, stripeCheckingOut]);
    useEffect(() => {
        // MAKE SURE TO SET OVERFLOW IF SIGNUPBOX IS STILL OPEN AFTER ORIENTATION CHANGE
        const setSignupBox = () => {
            if (window.innerWidth < 1024 && signupOpen) {
                document.querySelector('body').classList.add('overflow');
            }
        }
        window.addEventListener('resize', setSignupBox)
        return () => {
            window.removeEventListener('resize', setSignupBox)
        }
    }, [signupOpen]);
    return (
        <>
            <AllBox>
                <AllBoxRight signupOpen={signupOpen} ref={allBoxRightRef}>
                    {!checkoutPrepping &&
                        <FormCornerX onClick={setSignupOpenClick} fade={ppCheckingOut ? true : stripeCheckingOut ? true : false}>
                            <FormCornerXIcon viewBox="0 0 24 24">
                                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                            </FormCornerXIcon>
                        </FormCornerX>
                    }
                    <MainForm allBoxRightRef={allBoxRightRef} photoAmount={photoAmount} ppCheckingOut={ppCheckingOut} setPPCheckingOut={setPPCheckingOut} stripeCheckingOut={stripeCheckingOut} setStripeCheckingOut={setStripeCheckingOut} setCheckoutPrepping={setCheckoutPrepping} />
                </AllBoxRight>
                <AllBoxLeft id="allBoxLeft">
                    <Header setSignupOpen={setSignupOpen} />
                    <LeftBox>
                        <Intro />
                        <LargeSlider photoAmount={photoAmount} setPopImg={setPopImg} largeImg={largeImg} />
                        <BoostProfits setSignupOpen={setSignupOpen} />
                        <HowItWorks setSignupOpen={setSignupOpen} />
                        <WhatYouGet setSignupOpen={setSignupOpen} />
                        <Faq />
                        <ReviewInfo />
                        <SupportBottom setSignupOpen={setSignupOpen} />
                    </LeftBox>
                </AllBoxLeft>
            </AllBox>
            <Slider sliderTimeline={sliderTimeline} largeImg={largeImg} setPopImg={setPopImg} />
            {largeImg && <ImgPopAll largeImg={largeImg} closeImgPopBox={closeImgPopBox} />}
        </>
    );
})

export default Home;