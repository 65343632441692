import React from 'react';
import { IntroWrap, InnerH2Title, FaqBox, FaqQuestions, FaqQuestion, FaqQuestionIcon, FaqQuestionText, FaqQuestionLeft, FaqQuestionArrow, FaqQuestionAnswer } from '../style/main';
import { businessEmail } from './info/businessInfo'

const faqText = [{
    title: `What do I get with my order?`,
    answers: `You will receive high quality Amazon product photos via a secure downloadable link online. Your Amazon product photos come plug-and-play and will fit all Amazon product image rules/standards.`
}, {
    title: `How many Amazon product photos can I order?`,
    answers: `Your photo amount selection comes in three simple choices: 1, 2, and 5. The more photos you choose the cheaper your price. If you require a larger amount of product photos, we would glady offer you special bulk discount pricing. Please contact us: <a href="mailto:${businessEmail}?subject=Bulk Order Pricing Information">${businessEmail}</a> with your bulk order information.`
}, {
    title: `How long does it take to receive my Amazon product photos?`,
    answers: `Usually within 5-7 business days after your product arrives we will message you a secure link containing all of your Amazon product photos ready for download. Your photos will come instantly ready for use selling on Amazon!`
}, {
    title: `Can you create a shipping label for me?`,
    answers: `Yes! During your order creation you can choose to use our automated shipping label creation system. We will guide you through the easy steps to create your very own shipping label instantly.`
}, {
    title: `Can I use my own shipping label?`,
    answers: `Yes you can, many companies and customers choose to use their own shipping method to create their own shipping label. Once you place your order we will instantly send you all the information that you require to prepare your own shipping label.`
}, {
    title: `Can you ship my product back to me?`,
    answers: `Yes! Simply choose "Yes" when asked "Do you need your product returned?" during your order creation. Then select a return shipping option. When your photos are completed we will ship your product back to you. To note: We donate all items when possible that are not selected for return shipping.`
}, {
    title: `Do you offer any shipping insurance?`,
    answers: `We do not offer any direct shipping insurance at this time. For any shipping insurance please contact the shipping carrier of your choice and ask about their shipping insurance options.`
}, {
    title: `Do you offer discounts for bulk/large orders?`,
    answers: `Yes! We offer discounts for bulk/large orders. Please contact us: <a href="mailto:${businessEmail}?subject=Bulk Order Pricing Information">${businessEmail}</a> with your bulk order information.`
}, {
    title: `How can I order?`,
    answers: `Ordering is easy! Follow our simple guided steps to prepare your order. Place your order, then ship us your product using the shipping information we provide you upon your orders completion. Once we receive your product we will create your professional Amazon product photos. After your photos are created we will message you a secure link containing all of your Amazon product photos ready for download (usually within 5-7 business days). It's just that simple! Have any questions? Contact us: <a href="mailto:${businessEmail}?subject=I need assistance with ordering">${businessEmail}</a> we would love to help!`
}]

const Faq = React.memo(() => {
    const onClick = (e) => {
        const parent = e.target.closest(".faqQuestion")
        const faqAnswer = parent.nextSibling
        const arrow = parent.querySelector('.faqQuestionArrow')
        const allTitles = document.querySelectorAll('.faqQuestion');
        const allAnswers = document.querySelectorAll('.faqAnswer');
        const allArrows = document.querySelectorAll('.faqQuestionArrow');
        allTitles.forEach(x => {
            if (x !== parent) {
                x.classList.remove('show');
            }
        });
        allAnswers.forEach(x => {
            if (x !== faqAnswer) {
                x.classList.remove('show');
            }
        });
        allArrows.forEach(x => {
            if (x !== arrow) {
                x.classList.remove('flip');
            }
        });
        if (!faqAnswer.classList.contains('show')) {
            parent.classList.add('show');
            faqAnswer.classList.add('show');
            arrow.classList.add('flip');
        } else {
            parent.classList.remove('show');
            faqAnswer.classList.remove('show');
            arrow.classList.remove('flip');
        }

    }
    return (
        <FaqBox>
            <IntroWrap>
                <InnerH2Title mobilePadding={true}>Frequently Asked <span className="orange">Questions</span></InnerH2Title>
                <FaqQuestions>
                    {faqText.map((x, i) =>
                        <React.Fragment key={i}>
                            <FaqQuestion onClick={onClick} className="faqQuestion">
                                <FaqQuestionLeft>
                                    <FaqQuestionIcon viewBox="0 0 24 24">
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 18.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25s-.559 1.25-1.25 1.25zm1.961-5.928c-.904.975-.947 1.514-.935 2.178h-2.005c-.007-1.475.02-2.125 1.431-3.468.573-.544 1.025-.975.962-1.821-.058-.805-.73-1.226-1.365-1.226-.709 0-1.538.527-1.538 2.013h-2.01c0-2.4 1.409-3.95 3.59-3.95 1.036 0 1.942.339 2.55.955.57.578.865 1.372.854 2.298-.016 1.383-.857 2.291-1.534 3.021z" />
                                    </FaqQuestionIcon>
                                    <FaqQuestionText>{x.title}</FaqQuestionText>
                                </FaqQuestionLeft>
                                <FaqQuestionArrow viewBox="0 0 24 24" className="faqQuestionArrow">
                                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                </FaqQuestionArrow>
                            </FaqQuestion>
                            <FaqQuestionAnswer className="faqAnswer" dangerouslySetInnerHTML={{ __html: x.answers }}></FaqQuestionAnswer>
                        </React.Fragment>
                    )}
                </FaqQuestions>
            </IntroWrap>
        </FaqBox>
    );
})

export default Faq;